import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useGlobal } from 'reactn';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    pageContainer:{
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    root: {
      maxWidth: "100%",
    },
    
  }));

  export default function Skolebesoeg() {
    const history = useHistory();
    const classes = useStyles();
    const [visits,setVisits] = React.useState("");
    const [global,setGlobal]= useGlobal();
    const handleStartCourse = (theVisit) => {
      setGlobal({besoegsid:theVisit.id,
      besoegsSkoler:theVisit.skoler,
      besoegsKlasser:theVisit.klasser,
      besoegsForloeb:theVisit.forloeb,
      besoegsDato:theVisit.hvornaar});
      history.push('/forloebOpstart');
    }
    useEffect(()=>{
        if(visits===""){
    let initialIoDate = new Date();
    let month = (initialIoDate.getMonth()+1);
      if(month <10){
          month = "0"+month;
      }
      let initialDate = initialIoDate.getFullYear()+"-"+month+"-"+initialIoDate.getDate();
      console.log(initialDate);
      fetch("https://vidensrummet.dk/vidensrumapi/skolebesoeg/read_date.php?date="+initialDate,{
        method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data =>{ setVisits(data.records);console.log(data.records)}).then(console.log(visits));
    }
});
    
return(<div>
    <AppBar position="static">
      <Toolbar>
      <IconButton component={RouterLink} to="/" edge="start" className={classes.backButton} color="inherit" aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Vidensrummet - Skolebesøg
        </Typography>
        
      </Toolbar>
      
    </AppBar>
    <div>
    <List className={classes.listRoot}>
        {visits.length>0?visits.map((visit,index)=>{
        return(
           
          <ListItem onClick={()=>handleStartCourse(visit)} key={visit.id} divider>
            <ListItemText
              primary={visit.skoler.map(function (school) { return school.name; }).join(" & ")+" | "+visit.klasser.join(" & ")}
              secondary={visit.forloeb}
            />
          </ListItem>)
        }):<ListItem divider>
        <ListItemText
          primary="ingen besøg registreret idag"
        />
      </ListItem>}
        </List>
    </div>
  </div>);
  }