/*import louie from './Louie.png';
import guggenhomie from './Guggenhomie.png';
import mina from './Minaret.png';
import sydney from './Sydney.png';*/
import Akropolis from './SVG/Akropolis.svg';
import Belem from './SVG/belem tårnet.svg';
import Ben from './SVG/big ben.svg';
import Brandenburger from './SVG/brandenburg.svg';
import Capitol from './SVG/capitol.svg';
import Itza from './SVG/Chichen Itza.svg';
import Cristo from './SVG/Cristo Redentor.svg';
import Eiffel from './SVG/eiffel.svg';
import Fernseh from './SVG/fernseh.svg';
import Goldie from './SVG/golden gate.svg';
import Guggen from './SVG/gugen.svg';
import India from './SVG/india gate.svg';
import Klippe from './SVG/Klippehelligdommen.svg';
import Sagrada from './SVG/La Sagrada Familia.svg';
import Libie from './SVG/liberty.svg';
import London from './SVG/london bridge.svg';
import Louie from './SVG/louvre.svg';
import Pantheon from './SVG/pantheon.svg';
import Savannah from './SVG/savannah15.svg';
import Basil from './SVG/St basil.svg';
import Stoner from './SVG/stonehenge.svg';
import Stortorget from './SVG/stortorget.svg';
import Sydney from './SVG/sydney.svg';
import Taj from './SVG/Taj Mahal.svg';
import Tori from './SVG/torii gate.svg';
import Triumf from './SVG/triumpf.svg';
import UK from './SVG/uknown.svg';

import AkropolisImg from './1x/Akropolis.png';
import BelemImg from './1x/belem tårnet.png';
import BenImg from './1x/big ben.png';
import BrandenburgerImg from './1x/brandenburg.png';
import CapitolImg from './1x/capitol.png';
import ItzaImg from './1x/Chichen Itza.png';
import CristoImg from './1x/Cristo Redentor.png';
import EiffelImg from './1x/eiffel.png';
import FernsehImg from './1x/fernseh.png';
import GoldieImg from './1x/golden gate.png';
import GuggenImg from './1x/gugen.png';
import IndiaImg from './1x/india gate.png';
import KlippeImg from './1x/Klippehelligdommen.png';
import SagradaImg from './1x/La Sagrada Familia.png';
import LibieImg from './1x/liberty.png';
import LondonImg from './1x/london bridge.png';
import LouieImg from './1x/louvre.png';
import PantheonImg from './1x/pantheon.png';
import SavannahImg from './1x/savannah15.png';
import BasilImg from './1x/St basil.png';
import StonerImg from './1x/stonehenge.png';
import StortorgetImg from './1x/stortorget.png';
import SydneyImg from './1x/sydney.png';
import TajImg from './1x/Taj Mahal.png';
import ToriImg from './1x/torii gate.png';
import TriumfImg from './1x/triumpf.png';
import UKImg from './1x/uknown.png';
const avatars = [
    {
      img: Guggen,
      title: 'Guggenhomie',
    },
    {
      img: Libie,
      title: 'Libie',
    },
    {
      img: London,
      title: 'London',
    },
    {
      img: Pantheon,
      title: 'Pantheon',
    },
    {
      img: Savannah,
      title: 'Savannah',
    },
    {
      img: Basil,
      title: 'Basil',
    },
    {
      img: Stoner,
      title: 'Stoner',
    },
    {
      img: Stortorget,
      title: 'Twotones',
    },
    {
      img: Taj,
      title: 'Taj',
    },
    {
      img: Tori,
      title: 'Tori',
    },
    {
      img: Triumf,
      title: 'Triumf',
    },
    {
      img: Louie,
      title: 'Louie',
    },
    {
      img: Belem,
      title: 'Mina',
    },
    {
      img: Sydney,
      title: 'Sydney',
    },
    {
      img: Akropolis,
      title: 'Lizzy',
    },
    {
      img: Ben,
      title: 'Ben',
    },
    {
      img: Brandenburger,
      title: 'Brannie',
    },
    {
      img: Capitol,
      title: 'Cappie',
    },
    {
      img: Itza,
      title: 'Itza',
    },
    {
      img: Cristo,
      title: 'Cristo',
    },
    {
      img: Eiffel,
      title: 'Leiffel',
    },
    {
      img: Fernseh,
      title: 'Fernse',
    },
    {
      img: Goldie,
      title: 'Goldie',
    },
    {
      img: India,
      title: 'India',
    },
    {
      img: Klippe,
      title: 'Rocky',
    },
    {
      img: Sagrada,
      title: 'Sagrada',
    },
    {
      img: UK,
      title: 'UK',
    },
  ];
  
  export default avatars;