import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function Badge(props){
    const badgeWidth = props.badgeWidth;
    const [borderColor,setBorderColor] = React.useState(props.borderColor);
    const clearData = false;
    if(clearData===true){
      setBorderColor("transparent");
    }
    const useStyles = makeStyles(theme => ({
        badge:{
            marginTop:"8px",
            height:badgeWidth?"262px":"220px",
            width: badgeWidth?badgeWidth+"px":"200px",
            outline:"5px solid "+borderColor,
            position: "relative",
            top:"0px",
            left:"0px",
          },
          
          badgecontent:{
            height:"219px",
            backgroundColor:(props.badgecontent!=="")?'white':'transparent',
          },
          ribbon:{
            width:badgeWidth?parseInt(badgeWidth)+14+"px":"214px",
            height:"43px",
            position: "relative",
            bottom:"0px",
            left:"-7px",
            border: "21.5px solid #395a6d",
            borderRightWidth: "7px",
            borderRightColor: "transparent",
            borderLeftWidth: "7px",
            borderLeftColor: "transparent",
            boxSizing: "border-box",
            textAlign: "center",
            '& :before':{
                content: "''",
                zIndex:"-1",
                position: "absolute",
                top:"-19px",
                left:"-14px",
                border: "28.5px solid "+borderColor,
                borderLeftWidth: "7px",
                borderLeftColor: "transparent",
                borderRightWidth:"107px",
            },
            '& ::after':{
                zIndex:"-1",
                content: "''",
                position: "absolute",
                border: "28.5px solid "+borderColor,
                borderRightWidth:"7px",
                borderRightColor: "transparent",
                borderLeftWidth:"107px",
                right:"-14px",
                top:"-19px",
            },
            '& span':{
                position: "absolute",
                top: "50%",
                left:"50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                fontSize: "1.2em",
                fontFamily: "'Programme'",
                fontWeight: "900",
                color: "white",
            }
          },
          
          
      }));
    const classes = useStyles();

      let ribbon = <div className={classes.ribbon}><span>{props.ribbonText}</span></div>;
      if(props.ribbonText==="false"){
         ribbon = "";
      }
    return(
        <div className={classes.badge} id="skala">
      <div className={classes.badgecontent}>{props.content}</div>
      {ribbon}
    </div>
    );
}