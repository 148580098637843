import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Typography from '@material-ui/core/Typography';
import { useGlobal } from 'reactn';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  listRoot:{
    overflow: 'auto',
    maxHeight: 500,
  },
  listContainer:{
      backgroundColor: theme.palette.background.paper,
  },
  dialogContainer: {
    backgroundColor: theme.palette.background.paper,
    position:"fixed",
    height:"100%",
    width:"100%"

  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  diagrammer:{position:"relative"},
  kaotisk:{position:"absolute",top:"0px",left:"120px"},
  kaotiskSelect:{position:"absolute",top:"0px",left:"120px"},
  gaardhave:{position:"absolute",top:"195px",left:"440px"},
  ordnet:{position:"absolute",top:"305px",left:"70px"},
  ppes:{position:"absolute",top:"610px",left:"170px"},
}));
  
  export default function Organisationsdiagrammer(props){
    const [ global, setGlobal ] = useGlobal();
    const classes = useStyles();
    React.useEffect(()=>{
      fetch('https://vidensrummet.dk/vidensrumapi/boligenbesoeg/update.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:global.gruppeId,
      gruppemedlemmer:global.gruppemedlemmer,
      registreringer:global.registreringer,
      viewImages:global.viewImages,
      funktioner:global.funktioner, 
      organisation:global.organisation, 
      faellesskitse:global.faellesskitse, 
      maalfast:global.maalfast, 
      snit:global.snit, 
      digitalplan:global.digitalplan, 
      render:global.render, 
      tredimensionelmodel:global.tredimensionelmodel, 
    })
    }).then(response => response.json());
  },[global]);
    return(
    <div className={classes.dialogContainer}>
        <div style={{padding:"20px"}}>
        <Typography variant="h4" color="primary">Vælg et Organisationsdiagram</Typography>
        <Typography  color="primary">Hvordan skal rummene i jeres bolig organiseres?</Typography>
        </div>
        <div className={classes.diagrammer}>
            <div onClick={()=>{setGlobal({organisation:"kaotisk"});}} className={classes.kaotisk}><Typography variant="h5" color="primary">Kaotisk</Typography>
            <div style={{backgroundColor:global.organisation==="kaotisk"?"#FFBE6B":"#69BC91",border:global.organisation==="kaotisk"?"3px solid white":"",height:"240px",width:"240px",display:"flex",alignItems:"center"}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.34 259.37" height={255.3217*0.8+"px"} style={{tetAlign:"center",display:"block",margin:"auto"}}><g><g><rect x="173.78" y="97.86" width="76.43" height="116.65" transform="translate(55.81 368.18) rotate(-90)" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="35.37" y="33.3" width="120.27" height="61.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="86.72" y="202.64" width="63.15" height="54.7" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.0491600000000005px"}}/><rect x="2.02" y="95.24" width="57.12" height="54.7" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="153.67" y="2.02" width="102.17" height="115.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="59.14" y="95.24" width="94.53" height="115.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/></g></g></svg>
            <CheckCircleRoundedIcon  style={{display:global.organisation==="kaotisk"?"block":"none",position:"absolute",bottom:"0",right:"0"}}/>
            </div>
            </div>
            <div onClick={()=>{setGlobal({organisation:"gårdhave"});}} className={classes.gaardhave}><Typography variant="h5" color="primary">Gårdhave</Typography>
            <div style={{backgroundColor:global.organisation==="gårdhave"?"#FFBE6B":"#69BC91",border:global.organisation==="gårdhave"?"3px solid white":"",height:"240px",width:"240px",display:"flex",alignItems:"center"}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 263.58 253.94" height={249.8913*0.8+"px"}style={{display:"block",margin:"auto"}}><g><g ><rect x="2.02" y="19.32" width="76.43" height="116.65" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="78.45" y="2.02" width="120.27" height="61.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="116.66" y="179.92" width="63.15" height="54.7" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="198.72" y="9.27" width="57.12" height="54.7" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="14.49" y="135.97" width="102.17" height="115.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="167.03" y="63.97" width="94.53" height="115.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/></g></g></svg>
            <CheckCircleRoundedIcon  style={{display:global.organisation==="gårdhave"?"block":"none",position:"absolute",bottom:"0",right:"0"}}/>
            </div>
            </div>
            <div onClick={()=>{setGlobal({organisation:"ordnet"});}} className={classes.ordnet}><Typography variant="h5" color="primary">Ordnet</Typography>
            <div style={{backgroundColor:global.organisation==="ordnet"?"#FFBE6B":"#69BC91",border:global.organisation==="ordnet"?"3px solid white":"",height:"240px",width:"240px",display:"flex",alignItems:"center"}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.74 236.64" height={232.5954*0.8+"px"} style={{display:"block",margin:"auto"}}><g ><g><rect x="2.02" y="2.02" width="76.43" height="116.65" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="78.45" y="56.73" width="120.27" height="61.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="78.45" y="2.02" width="63.15" height="54.7" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="141.6" y="2.02" width="57.12" height="54.7" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="96.55" y="118.67" width="102.17" height="115.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="2.02" y="118.67" width="94.53" height="115.95" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/></g></g></svg>
            <CheckCircleRoundedIcon  style={{display:global.organisation==="ordnet"?"block":"none",position:"absolute",bottom:"0",right:"0"}}/>
            </div>
            </div>
            <div onClick={()=>{setGlobal({organisation:"ppes"});}} className={classes.ppes}><Typography variant="h5" color="primary">Perler på en Snor</Typography>
            <div style={{backgroundColor:global.organisation==="ppes"?"#FFBE6B":"#69BC91",border:global.organisation==="ppes"?"3px solid white":"",height:"240px",width:"480px",display:"flex",alignItems:"center"}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 586.62 167.29" height={163.243*0.8+"px"} style={{display:"block",margin:"auto"}} ><g ><g><rect x="33" y="100.82" width="523.04" height="64.45" transform="translate(589.03 266.09) rotate(180)" style={{fill:"none",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="425.61" y="22.7" width="76.43" height="116.65" transform="translate(927.64 162.04) rotate(180)" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="-27.14" y="40.77" width="120.27" height="61.95" transform="translate(104.74 38.74) rotate(90)" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="337.01" y="66.6" width="63.15" height="54.7" transform="translate(737.17 187.91) rotate(180)" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="527.48" y="60" width="57.12" height="54.7" transform="translate(1112.08 174.7) rotate(180)" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="209.39" y="23.05" width="102.17" height="115.95" transform="translate(520.95 162.04) rotate(180)" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/><rect x="89.42" y="2.02" width="94.53" height="115.95" transform="translate(273.36 120) rotate(180)" style={{fill:"#fff",stroke:"#1d1d1b",strokeMiterlimit:"10",strokeWidth:"4.04916px"}}/></g></g></svg>
            <CheckCircleRoundedIcon  style={{display:global.organisation==="ppes"?"block":"none",position:"absolute",bottom:"0",right:"0"}}/>
            </div>
            </div>

        </div>
        
    </div>);}