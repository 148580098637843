import React from 'react';
import { Avatar, SvgIcon } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import avatars from '../Assets/Images/avatars.js';
import { useGlobal } from 'reactn';

export default function AvatarBar(){
const [global,setGlobal] = useGlobal();

return(
    
<AvatarGroup max={6}>
        {global.gruppemedlemmer.map((medlem,index)=>
        <Avatar style={{backgroundColor:"grey", margin:"2px",padding:"1px"}} key={index} variant="square" alt={medlem.name} src={avatars[medlem.avatar].img}/>)}
</AvatarGroup>
)
}