import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'reactn';
import { useGlobal } from 'reactn';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Dialog, InputAdornment, TextField } from '@material-ui/core';
import { Crop169, Crop32, Crop54, Crop75 } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  listRoot:{
    overflow: 'auto',
    maxHeight: 500,
  },
  listContainer:{
      backgroundColor: theme.palette.background.paper,
  },
  dialogContainer: {
    backgroundColor: theme.palette.background.paper,
    position:"fixed",
    height:"760px",
    width:"660px",
    padding:"20px",
    top:"200px",
    left:"50px"
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));
  
  export default function KvmFordeling(props){
    const classes = useStyles();
    const [global,setGlobal] = useGlobal();
    const [open,setOpen] = useState(false);
    const [roomName,setRoomName]=useState("");
    const [roomSize,setRoomSize]=useState("");
    const [roomErrors,setRoomErrors]=useState(0);
    const [helperTexts,setHelperTexts] = useState({
        sizeHelperText:"eks. 35,2",
        nameHelperText:"eks. Børneværelse, Køkken"
    });
    React.useEffect(()=>{
      fetch('https://vidensrummet.dk/vidensrumapi/boligenbesoeg/update.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:global.gruppeId,
      gruppemedlemmer:global.gruppemedlemmer,
      registreringer:global.registreringer,
      viewImages:global.viewImages,
      funktioner:global.funktioner, 
      organisation:global.organisation, 
      faellesskitse:global.faellesskitse, 
      maalfast:global.maalfast, 
      snit:global.snit, 
      digitalplan:global.digitalplan, 
      render:global.render, 
      tredimensionelmodel:global.tredimensionelmodel, 
    })
    }).then(response => response.json());
  },[global]);
    const handleClose= ()=> {
        setRoomErrors(0);
        setRoomName("");
        setRoomSize("");
        setOpen(false);
    }
    const handleOpen=()=>{
        setOpen(true);
    }
    const roomIndexFinder=(roomId)=>{
        let theRooms = global.funktioner;
        const roomIdFind = (element) => element.id === roomId;
        return theRooms.findIndex(roomIdFind);

    }
    const addRoom=()=>{
        if(roomName==="" || roomName===null){
            setHelperTexts({...helperTexts, nameHelperText: "Du skal give rummet et navn"});
            setRoomErrors(1);
        }else if(roomSize==="" || roomSize===null || Number.isNaN(parseFloat(roomSize.replace(",",".")))){
            setHelperTexts({
                sizeHelperText:"Angiv tal, skriv kun tal ingen tekst",
                nameHelperText:"eks. Børneværelse, Køkken"
            });
setRoomErrors(2);
        }else{
            setHelperTexts({
                sizeHelperText:"eks. 35,2",
                nameHelperText:"eks. Børneværelse, Køkken"
            });
            setRoomErrors(0);
        let theRooms = global.funktioner;
        theRooms.push({id:theRooms.length>0?theRooms[theRooms.length-1].id+1:1,name:roomName,size:String(parseFloat(roomSize.replace(",",".")).toFixed(2)).replace(".",",")});
        setGlobal({funktioner:theRooms}); 
        setRoomName("");
        setRoomSize("");
        setOpen(false);
    }
    }
    const deleteRoom=(roomId)=>{let theRooms=global.funktioner.filter((_, i) => i !== roomIndexFinder(roomId));setGlobal({funktioner:theRooms});}
    const handleNameChange=(event)=>{
        setRoomName(event.target.value);
    }
    const handleSizeChange=(event)=>{
        setRoomSize(event.target.value);
    }
    //const handleSizeUpdate = event=>setRooms([... rooms, rooms[roomIndexFinder(roomId)]={id:roomId,name:rooms[roomIndexFinder(roomId)].name,size:event.target.value,}]);
    const roomItems =global.funktioner.map(room=> {
      let roomIcon = <Crop75 />
      if(parseFloat(room.size)<=12){
        roomIcon = <Crop75 />
      }else if(parseFloat(room.size)<=19){
        roomIcon = <Crop169 />
      }else if(parseFloat(room.size)<=24){
        roomIcon = <Crop54 />
      }else{
        roomIcon = <Crop32 />
      }
        return(
          <ListItem key={room.id} divider>
            <ListItemAvatar>
              <Avatar>
                {roomIcon}
              </Avatar>
            </ListItemAvatar>
            
            <ListItemText
              primary={room.name}
            />
            <div style={{display:"flex"}}><TextField error={roomErrors===2?true:false} variant="filled" value={room.size} onChange={event=>{let theRooms=global.funktioner;theRooms[roomIndexFinder(room.id)].size=event.target.value;setGlobal({funktioner:theRooms});}} label="Størrelse" InputProps={{
            endAdornment: <InputAdornment position="end">m&sup2;</InputAdornment>,
          }}/></div>
            <ListItemSecondaryAction>
              <IconButton onClick={()=>deleteRoom(room.id)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>);
        });
        let totalSquareM = 0;
        let currentFloat = 0;
        for (const val of global.funktioner) {
            currentFloat = parseFloat(val.size.replace(",","."));
            if(!Number.isNaN(currentFloat)){
            totalSquareM +=currentFloat;
        }
        }
    return(
    <div className={classes.dialogContainer}>
        <Typography variant="h3" color="primary">Hvilke rum?</Typography>
        <div className={classes.listContainer}>
        <List className={classes.listRoot}>
        {roomItems}
        </List>
        </div>
        <div>
            <Button color="primary" variant="outlined" onClick={handleOpen}>Tilføj Rum</Button>
        </div>
        <div>I alt {String(totalSquareM.toFixed(2)).replace(".",",")}m&sup2;</div>
        <Dialog open={open} onClose={handleClose}><div style={{width:"300px",height:"360px",backgroundColor:"white",padding:"30px"}}>
            <Typography variant="h5">Tilføj Rum</Typography>
            <Typography >Hvilket rum vil i tilføje?</Typography>
                <TextField error={roomErrors===1?true:false} helperText={helperTexts.nameHelperText} variant="filled" value={roomName} onChange={handleNameChange} label="Rum" />
                <TextField error={roomErrors===2?true:false} helperText={helperTexts.sizeHelperText} variant="filled" value={roomSize} onChange={handleSizeChange} label="Størrelse" InputProps={{
            endAdornment: <InputAdornment position="end">m&sup2;</InputAdornment>,
          }}/>
                <div style={{display:"flex",height:"60px",margin:"10px",alignItems:"flex-end",width:"100%"}}>
                    <Button onClick={addRoom} variant="contained" color="secondary">Tilføj</Button><Button style={{marginLeft:"10px"}} onClick={handleClose} variant="outlined" color="secondary">Annuller</Button>
                </div>
                
            </div>
        </Dialog>
    </div>);
}