import React from 'react';
import { useGlobal } from 'reactn';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Grid, Button } from '@material-ui/core';
import AvatarBar from '../Components/AvatarBar.js';
import bgikon from '../Assets/Images/byggegrundenikon.png';
import rfikon from '../Assets/Images/rumfordelingIkon.png';
import rtikon from '../Assets/Images/rentegningIkon.png';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Registrering from '../Components/Registrering.js';
import Funktionerne from '../Components/Funktionerne.js';
import Dialog from '@material-ui/core/Dialog';
import TakePhoto from '../Components/TakePhoto.js';
import KvmFordeling from '../Components/KvmFordeling.js';
import Organisationsdiagrammer from '../Components/Organisationsdiagrammer.js';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  AppBarRoot:{
    flexGrow: 1, 
},
  expansionButtonSheen:{
    border:'2px solid white',
    color:'white',
    background:'linear-gradient(to right, #DD5E89 0%, #F7BB97 51%, #DD5E89 100%)',
    display:'block',
      width:'100%',
      marginTop:'10px',
    position: 'relative',
    overflow: 'hidden',
    "&:after": {
      animation: 'sheen 2.5s ease 0s normal infinite',
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom:0,
      left: 0,
      background: 'linear-gradient(to bottom, transparent, rgba(200,255,250,0.5) 50%, transparent)',
      transform: 'rotateZ(60deg) translate(-304px, 608px)',
  },},
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    topBar:{
      backgroundColor: "rgba(0,0,0,.2)",
      height:"70px",
      color:"#fff"
    },
    content:{
      color:'#fff',
    },
    nextBut:{
      border:"white 2px solid",
      height:"70px",
  
    },
    studentinfo:{
        border:"white 2px solid",
        margin:'20px',
        padding:'10px',
    },
    studentName:{
        color:"#FFF",
    },
    nameInput:{paddingBottom:'10px',},
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    expansionButtons:{
      display:'block',
      width:'100%',
      marginTop:'10px',
    },
    card: {
        maxWidth: 725,
      },
      media: {
        height: 200,
      },
      dialogPaper:{
        backgroundColor:'transparent'
      }
  }));
 

  const AccordionSummary = withStyles({
    root: {
      marginTop:'10px',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      
      display:'flex',
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  const AccordionDetails = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
      backgroundColor:'transparent',
    },
  }))(MuiAccordionDetails);
  const Accordion = withStyles({
    root: {
      
      display:'block',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
    },
    expanded: {},
  })(MuiAccordion);

  export default function Boligen() {
    const classes = useStyles();
    const [global,setGlobal] = useGlobal();
    const [progress,setProgress] = useGlobal("boligprogress");
    const [openReg, setOpenReg] = React.useState(false);
    const [openFun, setOpenFun] = React.useState(false);
    const [openOrg, setOpenOrg] = React.useState(false);
    const [openPhotoSkitse,setOpenSkitse]= React.useState(false);
    const [openKvm,setOpenKvm]= React.useState(false);
    const [openPhotoPlan,setOpenPhotoPlan]=React.useState(false);
    const [openSnitPlan,setOpenSnitPlan]=React.useState(false);
    const [openDigiPlan,setOpenDigiPlan]= React.useState(false);
    const [openTreD,setOpenTreD]=React.useState(false);
    const [openRender,setOpenRender]=React.useState(false);
    const [accordionExpanded, setAccordionExpanded] = React.useState(false);
    const handleClickOpen = (theDialog) => {
      if(theDialog==="reg"){
      setOpenReg(true);
      }else if(theDialog==="fun"){
        setOpenFun(true);
      }else if(theDialog==="organ"){
        setOpenOrg(true);
      }else if(theDialog==="skitse"){
        setOpenSkitse(true);
      }else if(theDialog==="kvm"){
        setOpenKvm(true);
      }else if(theDialog==="plan"){
        setOpenPhotoPlan(true);
      }else if(theDialog==="snit"){
        setOpenSnitPlan(true);
      }else if(theDialog==="digiplan"){
        setOpenDigiPlan(true);
      }else if(theDialog==="treD"){
        setOpenTreD(true);
      }else if(theDialog==="render"){
        setOpenRender(true);
      }
    };
    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setAccordionExpanded(isExpanded ? panel : false);
    };
    const handleClose = () => {
      setOpenReg(false);
      setOpenFun(false);
      setOpenOrg(false);
      setOpenSkitse(false);
      setOpenKvm(false);
      setOpenPhotoPlan(false);
      setOpenSnitPlan(false);
      setOpenDigiPlan(false);
      setOpenTreD(false);
      setOpenRender(false);
    };
      React.useEffect(()=>{
        if(global.gruppeId!==''){
          fetch('https://vidensrummet.dk/vidensrumapi/boligenbesoeg/read_one.php?gruppeid='+global.gruppeId, {
            method: 'POST',
          headers: {
            'Accept': 'application/json',
          }
        }).then(response =>response.json()).then(data=>setGlobal({
          gruppemedlemmer:data.gruppemedlemmer,
          registreringer:data.registreringer,
          viewImages:data.viewImages,
          funktioner:data.funktioner,
          organisation:data.organisation,
          maalfast:data.maalfast,
          snit:data.snit,
          digitalplan:data.digitalplan,
          render:data.render,
          tredimensionelmodel:data.tredimensionelmodel
        }));
      }
  },[]);
  switch(global.boligprogress){   

    case 9:
      if(global.render!==""){
        setGlobal({boligprogress:10});
      }
    
    break;
    case 8:
      if(global.tredimensionelmodel!==""){
        setGlobal({boligprogress:9});
      }

    break;
    case 7:
      if(global.digitalplan!==""){
        setGlobal({boligprogress:8});
      }

    break;
    case 6:
      if(global.snit!==""){
        setGlobal({boligprogress:7})
      }

    break;
    case 5:
      if(global.maalfast!==""){
        setGlobal({boligprogress:6})
      }

    break;
    case 4:
      if(global.funktioner[0].size!==""){
        setGlobal({boligprogress:5})
      }

    break;
    case 3:
      if(global.faellesskitse!==""){
        setGlobal({boligprogress:4})
      }

    break;
    case 2:
      if(global.organisation!==""){
        setGlobal({boligprogress:3})
      }

    break;
    case 1:
      if(global.funktioner.length!==0){
        setGlobal({boligprogress:2})
      }

    break;
    case 0:
      if(global.registreringer.length!==0){
        setGlobal({boligprogress:1})
      }

    break;
  }
      
        return(
    
          <div>
        <AppBar position="static">
            <Toolbar>
            <Typography variant="h6" className={classes.title}>
                Boligen - Det enkle liv og landskabet
            </Typography>
            <AvatarBar />           
            </Toolbar>
        </AppBar>
        <div>
        <Grid container alignContent="center" alignItems="stretch" justify="space-around" className={classes.content}>
        <Grid item xs={10}>
        <Accordion expanded={accordionExpanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
            <AccordionSummary
              expandIcon="|"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <img src={bgikon} alt="Byggegrunden" style={{paddingRight:'24px'}}/>
              <div style={{display:'block',paddingTop:'10px'}}>
              <Typography variant="h5">Byggegrunden</Typography>
              <Typography>
              Vi tager et kig på byggegrunden og går ud og gør vores registreringer.
              </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails >
            <Button variant="contained" className={progress===0?classes.expansionButtonSheen:classes.expansionButtons} onClick={()=>{progress===0?setProgress(1):setProgress(progress);handleClickOpen("reg");}}>Registrering</Button>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={accordionExpanded === 'panel2'} onChange={handleAccordionChange('panel2')} disabled={progress>=1?false:true}>
            <AccordionSummary
              expandIcon="|"
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <img src={rfikon} alt="Rumfordelingen" style={{paddingRight:'24px'}}/>
              <div style={{display:'block',paddingTop:'10px'}}>
              <Typography variant="h5">Rumfordelingen</Typography>
              <Typography>
              Vi går igang med at designe vores bolig. Vi finder ud af hvilke funktioner den skal have og hvordan den skal organiseres. Vi skitserer og finder frem til familiens værdier.
              </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{display:'block',width:'608px'}}>
            <Button variant="contained" className={progress===1?classes.expansionButtonSheen:classes.expansionButtons}  onClick={()=>{progress===0?setProgress(2):setProgress(progress);handleClickOpen("fun");}}>Funktioner</Button>
    
            <Button variant="contained" className={progress===2?classes.expansionButtonSheen:classes.expansionButtons}  disabled={progress>=2?false:true} onClick={()=>{progress===2?setProgress(3):setProgress(progress);handleClickOpen("organ");}}>Organisationsdiagram</Button>
    
            <Button variant="contained" className={progress===3?classes.expansionButtonSheen:classes.expansionButtons}  disabled={progress>=3?false:true} onClick={()=>{progress===3?setProgress(4):setProgress(progress);handleClickOpen("skitse");}}>Fællesskitse</Button>
            </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={accordionExpanded === 'panel3'} onChange={handleAccordionChange('panel3')} disabled={progress>=4?false:true}>
            <AccordionSummary
              expandIcon="|"
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <img src={rtikon} alt="rentegning" style={{paddingRight:'24px'}}/>
              <div style={{display:'block',paddingTop:'10px'}}>
              <Typography variant="h5">Rentegning</Typography>
              <Typography>
              Det spidser til, nu skal vi til at være målfaste og regne lidt på vores bolig. Vi griber fat i flere af Arkitektens redskaber og bliver konkrete i vores boligdesing.
              </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
            <div style={{display:'block',width:'608px'}}>
            <Button variant="contained" className={progress===4?classes.expansionButtonSheen:classes.expansionButtons} disabled={progress>=4?false:true} onClick={()=>{progress===4?setProgress(5):setProgress(progress);handleClickOpen("kvm");}}>Kvadratmeter fordeling</Button>
            <Button variant="contained" className={progress===5?classes.expansionButtonSheen:classes.expansionButtons} disabled={progress>=5?false:true} onClick={()=>{progress===5?setProgress(6):setProgress(progress);handleClickOpen("plan");}}>Målfast tegning</Button>
            <Button variant="contained" className={progress===6?classes.expansionButtonSheen:classes.expansionButtons} disabled={progress>=6?false:true} onClick={()=>{progress===6?setProgress(7):setProgress(progress);handleClickOpen("snit");}}>Snittegning</Button>
            </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={accordionExpanded === 'panel4'} onChange={handleAccordionChange('panel4')} disabled={progress>=7?false:true}>
            <AccordionSummary
              expandIcon="|"
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <img src={rtikon} alt="3d tegning" style={{paddingRight:'24px'}}/>
              <div style={{display:'block',paddingTop:'10px'}}>
              <Typography variant="h5">3D tegning</Typography>
              <Typography>
             Vi har fået lavet både plan og snit, nu er det tid til at digitalisere vores tegninger.
              </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
            <div style={{display:'block',width:'608px'}}>
            <Button variant="contained" className={progress===7?classes.expansionButtonSheen:classes.expansionButtons} disabled={progress>=7?false:true} onClick={()=>{progress===7?setProgress(8):setProgress(progress);handleClickOpen("digiplan");}}>Digital Plan Tegning</Button>
            <Button variant="contained" className={progress===8?classes.expansionButtonSheen:classes.expansionButtons} disabled={progress>=8?false:true} onClick={()=>{progress===8?setProgress(9):setProgress(progress);handleClickOpen("treD");}}>3d View</Button>
            <Button variant="contained" className={progress===9?classes.expansionButtonSheen:classes.expansionButtons} disabled={progress>=9?false:true} onClick={()=>{progress===9?setProgress(10):setProgress(progress);handleClickOpen("render");}}>Visualisering</Button>
            </div>
            </AccordionDetails>
          </Accordion>
          </Grid>
        </Grid>
        </div>
        <Dialog  fullScreen open={openReg} onClose={handleClose}>
          <Registrering />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog PaperProps={{classes:{root:classes.dialogPaper}}} fullScreen open={openFun} onClose={handleClose}>
          <Funktionerne />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog  fullScreen open={openPhotoSkitse} onClose={handleClose}>
          <TakePhoto tegning="faellesskitse" />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog  fullScreen open={openOrg} onClose={handleClose}>
          <Organisationsdiagrammer />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog PaperProps={{classes:{root:classes.dialogPaper}}} fullScreen open={openKvm} onClose={handleClose}>
          <KvmFordeling />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog  fullScreen open={openPhotoPlan} onClose={handleClose}>
          <TakePhoto tegning="maalfast" />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog   fullScreen open={openSnitPlan} onClose={handleClose}>
          <TakePhoto tegning="snit" />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog  fullScreen open={openDigiPlan} onClose={handleClose}>
          <TakePhoto tegning="digiplan" />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog  fullScreen open={openTreD} onClose={handleClose}>
          <TakePhoto tegning="treD" />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          <Dialog  fullScreen open={openRender} onClose={handleClose}>
          <TakePhoto tegning="render" />
          <Button style={{position:"absolute",bottom:0,left:0}} variant="contained" fullWidth={true} onClick={handleClose}>Afslut</Button>
          </Dialog>
          </div>

        );
  }