import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect} from 'reactn';
import { List, ListItem, ListItemText, ListSubheader,ListItemSecondaryAction, Dialog, Button,TextField, MenuItem, Select, FormControl, InputLabel, DialogContent, DialogTitle, DialogActions, FormHelperText, CircularProgress, Snackbar, DialogContentText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import DayJsUtils from '@date-io/dayjs';
import daDK from 'dayjs/locale/da.js';
import MuiAlert from '@material-ui/lab/Alert';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { TextFields } from '@material-ui/icons';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    pageContainer:{
      width:"100%"
    },
    title: {
      flexGrow: 1,
    },
    root: {
      maxWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    schoolInput:{
      borderColor:"#fff",
      borderWidth:"2px",
      color:"#fff",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    
  }));

  export default function AdminSkoleBesoeg() {
    const classes = useStyles();
    const [visits,setVisits] = React.useState("");
    const [visitsToday,setVisitsToday]= React.useState([]);
    const [visitsPast,setVisitsPast]= React.useState([]);
    const [visitsTomorrow,setVisitsTomorrow]= React.useState([]);
    const [visitsFuture,setVisitsFuture]= React.useState([]);
    const [open,setOpen] = React.useState(false);
    const [hvornaar,setHvornaar] = React.useState(null);
    const [id,setId] = React.useState(null);
    const [skoler,setSkoler] = React.useState([]);
    const [klasser,setklasser] = React.useState([]);
    const [antalElever,setAntalElever] = React.useState("");
    const [forloeb,setforloeb] = React.useState('');
    const [dateError,setDateError] = React.useState(false);
    const [schoolError,setSchoolError]= React.useState(false);
    const [gradeError,setGradeError] = React.useState(false);
    const [studentsError,setStudentsError] = React.useState(false);
    const [courseError,setCourseError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [openSuccess,setOpenSuccess] = React.useState(false);
    const [openEditSuccess,setOpenEditSuccess] = React.useState(false);
    const [openError,setOpenError] = React.useState(false);
    const [openEditDialog,setOpenEditDialog]=React.useState(false);
    const [openDeleteDia,setOpenDeleteDia] = React.useState(false);
    const handleSuccessClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSuccess(false);
    };
    const handleEditSuccessClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenEditSuccess(false);
    };
    const handleErrorClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenError(false);
    };
    const handleDateChange = (date) => {
      setHvornaar(date);
    };
    const handleClose= ()=> {
      setOpen(false);
      setOpenEditDialog(false);
      setklasser([]);
      setSkoler([]);
      setHvornaar(null);
      setId(null);
      setAntalElever('');
      setforloeb('');
      setDateError(false);
      setSchoolError(false);
      setGradeError(false);
      setStudentsError(false);
      setCourseError(false);
      setOpenDeleteDia(false);
  }
  
  const handleOpen=()=>{
      setOpen(true);
  }
  useEffect(()=>{
    if(openSuccess || openEditSuccess){
      getVisits();
    }
  },[openSuccess,openEditSuccess])
    useEffect(()=>{
        if(visits===""){
          getVisits();
    }
});
const getVisits = ()=>{fetch("https://vidensrummet.dk/vidensrumapi/skolebesoeg/read.php",{
  method: 'GET',
headers: {
  'Accept': 'application/json',
}
})
.then(response => response.json())
.then(data => {
setVisits(data.records);
if(data.records){
setVisitsPast(data.records.filter(
  visit=>{
    let today= new Date();
    today.setHours(0,0,0,0);
    let compareDate = new Date(visit.hvornaar);
    compareDate.setHours(0,0,0,0);
  return compareDate.getTime() < today.getTime();
  }));
setVisitsToday(data.records.filter(
  visit=>{
    let today= new Date();
    today.setHours(0,0,0,0);
    let compareDate = new Date(visit.hvornaar);
    compareDate.setHours(0,0,0,0);
  return compareDate.getTime() === today.getTime();
  }));

setVisitsTomorrow(data.records.filter(
  visit=>{
    let today= new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(0,0,0,0);
    let compareDate = new Date(visit.hvornaar);
    compareDate.setHours(0,0,0,0);
  return compareDate.getTime() === tomorrow.getTime();
  }));
setVisitsFuture(data.records.filter(
  visit=>{
    let today= new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(0,0,0,0);
    let compareDate = new Date(visit.hvornaar);
    compareDate.setHours(0,0,0,0);
  return compareDate.getTime() > tomorrow.getTime();
  }));
}
});}
const handleSchoolChange=(event,newValue)=>{
  setSkoler(newValue);
}
const handleGradeChange=(event,newValue)=>{
  setklasser(newValue);
}
function handlefetchErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}
const getCookie =(cname)=>{
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' '){
          c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}
const handleAddVisit= () => {
  
  setDateError(hvornaar===null?true:false);
  setSchoolError(skoler.length===0?true:false);
  setGradeError(klasser.length===0?true:false);
  setStudentsError(antalElever===""?true:false);
  setCourseError(forloeb===''?true:false);
  if(hvornaar!==null && skoler.length!==0 && klasser.length!==0 && antalElever!=="" && forloeb!==''){
    setLoading(true);
    fetch('https://vidensrummet.dk/vidensrumapi/skolebesoeg/create.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      hvornaar: hvornaar,
      skoler: skoler,
      klasser:klasser,
      elevantal: antalElever,
      forloeb:forloeb,
      jwt:getCookie("jwt"),
    })
    
  }).then(handlefetchErrors).then(response => response.json()).then(data=>{
    window.setTimeout(
      () => {
      setLoading(false);
      setOpenSuccess(true);
      handleClose();
    }, 1000);
   
  }).catch( error=>{
    window.setTimeout(
      () => {
      setLoading(false);
      setOpenError(true);
    }, 1000);

  });
  }
}
const handleEditVisit= () => {
  setDateError(hvornaar===null?true:false);
  setSchoolError(skoler.length===0?true:false);
  setGradeError(klasser.length===0?true:false);
  setStudentsError(antalElever===""?true:false);
  setCourseError(forloeb===''?true:false);
  if(hvornaar!==null && skoler.length!==0 && klasser.length!==0 && antalElever!=="" && forloeb!==''){
    setLoading(true);
    fetch('https://vidensrummet.dk/vidensrumapi/skolebesoeg/update.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:id,
      hvornaar: hvornaar,
      skoler: skoler,
      klasser:klasser,
      elevantal: antalElever,
      forloeb:forloeb,
      jwt:getCookie("jwt"),
    })
    
  }).then(handlefetchErrors).then(response => response.json()).then(data=>{
    window.setTimeout(
      () => {
      setLoading(false);
      setOpenEditSuccess(true);
      handleClose();
    }, 1000);
   
  }).catch( error=>{
    window.setTimeout(
      () => {
      setLoading(false);
      setOpenError(true);
    }, 1000);

  });
  }
}
const handleDelete = ()=>{
  fetch('https://vidensrummet.dk/vidensrumapi/skolebesoeg/delete.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:id,
      jwt:getCookie("jwt"),
    })
    
  }).then(handlefetchErrors).then(response => response.json()).then(data=>{
    window.setTimeout(
      () => {
      setLoading(false);
      setOpenEditSuccess(true);
      handleClose();
    }, 1000);
   
  }).catch( error=>{
    window.setTimeout(
      () => {
      setLoading(false);
      setOpenError(true);
    }, 1000);

  });
  }
const handleOpenDelete=(theVisit)=>{
  setId(theVisit.id);
  setOpenDeleteDia(true);
}
const handleOpenEditVisit=(theVisit)=>{
  setklasser(theVisit.klasser);
      setSkoler(theVisit.skoler);
      setHvornaar(theVisit.hvornaar);
      setId(theVisit.id);
      setAntalElever(theVisit.elevantal);
      setforloeb(theVisit.forloeb);
      setOpenEditDialog(true);
}
    
return(<div>
    <AppBar position="static">
      <Toolbar>
      <IconButton component={RouterLink} to="/" edge="start" className={classes.backButton} color="inherit" aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Vidensrummet - Skolebesøg
        </Typography>
        
      </Toolbar>
      
    </AppBar>
    <div>
    <Button onClick={handleOpen} variant="outlined">Tilføj Besøg</Button>
        <List >
        <ListSubheader component="div" id="nested-list-subheader">
          Besøg i dag
        </ListSubheader>
        {visitsToday.length>0?visitsToday.map((visit,index)=>{
        return(
           
          <ListItem onClick={()=>handleOpenEditVisit(visit)} key={visit.id} divider>
            <ListItemText
              primary={visit.hvornaar+" "+visit.skoler.map(function (school) { return school.name; }).join(" & ")+" | "+visit.klasser.join(" & ")}
              secondary={visit.forloeb}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={()=>handleOpenDelete(visit)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>)
        }):<ListItem divider>
        <ListItemText
          primary="ingen besøg registreret i dag"
        />
      </ListItem>}
        </List>
        <List className={classes.listRoot}>
        <ListSubheader component="div" id="nested-list-subheader">
          Besøg i morgen
        </ListSubheader>
        {visitsTomorrow.length>0?visitsTomorrow.map((visit,index)=>{
        return(
           
          <ListItem onClick={()=>handleOpenEditVisit(visit)} key={visit.id} divider>
            <ListItemText
              primary={visit.hvornaar+" "+visit.skoler.map(function (school) { return school.name; }).join(" & ")+" | "+visit.klasser.join(" & ")}
              secondary={visit.forloeb}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={()=>handleOpenDelete(visit)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>)
        }):<ListItem divider>
        <ListItemText
          primary="ingen besøg registreret i morgen"
        />
      </ListItem>}
        </List>
        <List className={classes.listRoot}>
        <ListSubheader component="div" id="nested-list-subheader">
          Kommende Besøg
        </ListSubheader>
        {visitsFuture.length>0?visitsFuture.map((visit,index)=>{
        return(
           
          <ListItem onClick={()=>handleOpenEditVisit(visit)} key={visit.id} divider>
            <ListItemText
              primary={visit.hvornaar+" | "+visit.skoler.map(function (school) { return school.name; }).join(" & ")+" | "+visit.klasser.join(" & ")}
              secondary={visit.forloeb}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={()=>handleOpenDelete(visit)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>)
        }):<ListItem divider>
        <ListItemText color="warning"
          primary="ingen kommende besøg registreret"
        />
      </ListItem>}
        </List>
        <List className={classes.listRoot}>
    <ListSubheader component="div" id="nested-list-subheader">
          Tidligere Besøg
        </ListSubheader>
        {visitsPast.length>0?visitsPast.map((visit,index)=>{
        return(
           
          <ListItem key={visit.id} divider>
            <ListItemText
              primary={visit.hvornaar+" "+visit.skoler.map(function (school) { return school.name; }).join(" & ")+" | "+visit.klasser.join(" & ")}
              secondary={visit.forloeb}
            />
          </ListItem>)
        }):<ListItem divider>
        <ListItemText
          primary="ingen besøg tidligere besøg tilgængelige"
        />
      </ListItem>}
        </List>
        <Dialog open={openEditDialog} onClose={handleClose}><DialogTitle id="alert-dialog-title">{"Tilføj Ny Skoleforløb"}</DialogTitle>
        <DialogContent><div>

        <Grid container >
          <Grid item xs={8}>
        <MuiPickersUtilsProvider utils={DayJsUtils} locale={daDK}>
      <FormControl error={dateError} variant="outlined" className={classes.formControl}>
      <KeyboardDatePicker
      error={dateError}
          disableToolbar
          disablePast
          variant="static"
          format='DD/MM/YYYY'
          margin="normal"
          id="date-picker-inline"
          label="Dato"
          inputVariant="outlined"
          value={hvornaar}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        {dateError && <FormHelperText id="date-error-text">Hvornår kommer de?</FormHelperText>}
        </FormControl>
    </MuiPickersUtilsProvider></Grid>
    <Grid item xs={8}>
        <FormControl error={schoolError} variant="outlined" className={classes.formControl}>
          <Autocomplete multiple className={classes.schoolInput}
      id="skole-autocompleter"
      onChange={handleSchoolChange}
      getOptionSelected={(option, value) => option.id === value.id }
      value={skoler}
      options={skolerdk}
      style={{ width: 320 }}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField {...params} error={schoolError} className={classes.schoolInput}  label="Skole" variant="outlined"  fullWidth />
      )}
      />{schoolError && <FormHelperText id="component-error-text">Hvilken skole er eleverne fra?</FormHelperText>}</FormControl></Grid>
    <Grid item xs={6}>
    <FormControl error={gradeError} variant="outlined" className={classes.formControl}>
    <Autocomplete multiple className={classes.schoolInput}
    id="grade-selection"
    style={{ width: 220 }}
    onChange={handleGradeChange}
    value={klasser}
    options={["1. klasse",
    "2. klasse",
    "3. klasse",
    "4. klasse",
    "5. klasse",
    "6. klasse",
    "7. klasse",
    "8. klasse",
    "9. klasse",
    "10. klasse",
    "1.g",
    "2.g",
    "3.g",
    "andet"]}
    renderInput={params => (
      <TextField {...params} error={gradeError} className={classes.schoolInput}  label="Klasser" variant="outlined"  fullWidth />
    )}
  />{gradeError && <FormHelperText id="component-error-text">Hvilke klassetrin?</FormHelperText>}</FormControl></Grid>
  <Grid item xs={6}>
  <FormControl error={studentsError} variant="outlined" className={classes.formControl}>
         <TextField
          id="outlined-number"
          label="Antal elever"
          error={studentsError}
          type="number"
          value={antalElever}
          onChange={(event)=>setAntalElever(event.target.value)}
          variant="outlined"
        />{studentsError && <FormHelperText id="component-error-text">Hvor mange elever er der i klassen?</FormHelperText>}</FormControl></Grid>
        <Grid item xs={7}>
  <FormControl error={courseError} variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Forløb</InputLabel>
        <Select labelId="demo-simple-select-outlined-label" value={forloeb} style={{ width: 220 }} onChange={(event)=>setforloeb(event.target.value)}
          id="demo-simple-select-outlined" label="Forløb">
    <MenuItem value="I Skalaens Verden">I Skalaens Verden</MenuItem>
    <MenuItem value="Ultrabit: Rum og Lys">Ultrabit: Rum og Lys</MenuItem>
    <MenuItem value="Boligen - det enkle liv og landskabet">Boligen - det enkle liv og landskabet</MenuItem>
          </Select>
          {courseError && <FormHelperText id="component-error-text">Hvad skal de lave?</FormHelperText>}
      </FormControl></Grid>
      </Grid>
      </div>
      </DialogContent>
      <DialogActions>
                <div style={{display:"flex",alignItems:"flex-end"}}>
                {loading? <CircularProgress size={24} className={classes.buttonProgress} />:<><Button onClick={handleEditVisit} variant="contained" color="secondary">Gem Ændringer</Button><Button style={{marginLeft:"10px"}} onClick={handleClose} variant="outlined" color="secondary">Annuller</Button></>}
                </div>
                </DialogActions>
            
        </Dialog>
        <Dialog open={open} onClose={handleClose}><DialogTitle id="alert-dialog-title">{"Tilføj Ny Skoleforløb"}</DialogTitle>
        <DialogContent><div>

        <Grid container >
          <Grid item xs={8}>
        <MuiPickersUtilsProvider utils={DayJsUtils} locale={daDK}>
      <FormControl error={dateError} variant="outlined" className={classes.formControl}>
      <KeyboardDatePicker
      error={dateError}
          disableToolbar
          disablePast
          variant="static"
          format='DD/MM/YYYY'
          margin="normal"
          id="date-picker-inline"
          label="Dato"
          inputVariant="outlined"
          value={hvornaar}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        {dateError && <FormHelperText id="date-error-text">Hvornår kommer de?</FormHelperText>}
        </FormControl>
    </MuiPickersUtilsProvider></Grid>
    <Grid item xs={8}>
        <FormControl error={schoolError} variant="outlined" className={classes.formControl}>
          <Autocomplete multiple className={classes.schoolInput}
      id="skole-autocompleter"
      onChange={handleSchoolChange}
      value={skoler}
      options={skolerdk}
      style={{ width: 320 }}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField {...params} error={schoolError} className={classes.schoolInput}  label="Skole" variant="outlined"  fullWidth />
      )}
      />{schoolError && <FormHelperText id="component-error-text">Hvilken skole er eleverne fra?</FormHelperText>}</FormControl></Grid>
    <Grid item xs={6}>
    <FormControl error={gradeError} variant="outlined" className={classes.formControl}>
    <Autocomplete multiple className={classes.schoolInput}
    id="grade-selection"
    style={{ width: 220 }}
    onChange={handleGradeChange}
    value={klasser}
    options={["1. klasse",
    "2. klasse",
    "3. klasse",
    "4. klasse",
    "5. klasse",
    "6. klasse",
    "7. klasse",
    "8. klasse",
    "9. klasse",
    "10. klasse",
    "1.g",
    "2.g",
    "3.g",
    "andet"]}
    renderInput={params => (
      <TextField {...params} error={gradeError} className={classes.schoolInput}  label="Klasser" variant="outlined"  fullWidth />
    )}
  />{gradeError && <FormHelperText id="component-error-text">Hvilke klassetrin?</FormHelperText>}</FormControl></Grid>
  <Grid item xs={6}>
  <FormControl error={studentsError} variant="outlined" className={classes.formControl}>
         <TextField
          id="outlined-number"
          label="Antal elever"
          error={studentsError}
          type="number"
          value={antalElever}
          onChange={(event)=>setAntalElever(event.target.value)}
          variant="outlined"
        />{studentsError && <FormHelperText id="component-error-text">Hvor mange elever er der i klassen?</FormHelperText>}</FormControl></Grid>
        <Grid item xs={7}>
  <FormControl error={courseError} variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Forløb</InputLabel>
        <Select labelId="demo-simple-select-outlined-label" value={forloeb} style={{ width: 220 }} onChange={(event)=>setforloeb(event.target.value)}
          id="demo-simple-select-outlined" label="Forløb">
    <MenuItem value="I Skalaens Verden">I Skalaens Verden</MenuItem>
    <MenuItem value="Ultrabit: Rum og Lys">Ultrabit: Rum og Lys</MenuItem>
    <MenuItem value="Boligen - det enkle liv og landskabet">Boligen - det enkle liv og landskabet</MenuItem>
          </Select>
          {courseError && <FormHelperText id="component-error-text">Hvad skal de lave?</FormHelperText>}
      </FormControl></Grid>
      </Grid>
      </div>
      </DialogContent>
      <DialogActions>
                <div style={{display:"flex",alignItems:"flex-end"}}>
                {loading? <CircularProgress size={24} className={classes.buttonProgress} />:<><Button onClick={handleAddVisit} variant="contained" color="secondary">Tilføj</Button><Button style={{marginLeft:"10px"}} onClick={handleClose} variant="outlined" color="secondary">Annuller</Button></>}
                </div>
                </DialogActions>
            
        </Dialog>
        <Dialog
        open={openDeleteDia}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Slet Skolebesøg?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Er du sikker på at du vil slette dette skolebesøg?
            {hvornaar+ " | " +skoler.map(function (school) { return school.name; }).join(" & ")+" | "+klasser.join(" & ")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            Ja, Slet
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Nej, Annuller
          </Button>
        </DialogActions>
      </Dialog>

        <Snackbar anchorOrigin={{vertical:"top", horizontal:"center"}} open={openSuccess} autoHideDuration={3000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          Skolebesøg oprettet
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{vertical:"top", horizontal:"center"}} open={openEditSuccess} autoHideDuration={2000} onClose={handleEditSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          Skolebesøg rettet
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{vertical:"top", horizontal:"center"}} open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
      <Alert onClose={handleErrorClose} severity="error">Noget gik galt, prøv igen! </Alert>
      </Snackbar>
    </div>
  </div>);
  }
  const skolerdk = [
    {id:"101001",name:"Den Classenske Legatskole"},
    {id:"101003",name:"Nyboder Skole"},
    {id:"101005",name:"Sølvgades Skole"},
    {id:"101007",name:"Nørrebro Park Skole"},
    {id:"101008",name:"Blågård Skole"},
    {id:"101011",name:"Bellahøj Skole"},
    {id:"101012",name:"Brønshøj Skole"},
    {id:"101015",name:"Grøndalsvængets Skole"},
    {id:"101017",name:"Husum Skole"},
    {id:"101019",name:"Katrinedals Skole"},
    {id:"101020",name:"Kirkebjerg Skole"},
    {id:"101021",name:"Korsager Skole"},
    {id:"101022",name:"Rødkilde Skole"},
    {id:"101023",name:"Tingbjerg Skole"},
    {id:"101029",name:"Tagensbo Skole"},
    {id:"101030",name:"Holbergskolen"},
    {id:"101034",name:"Rådmandsgades Skole"},
    {id:"101035",name:"Guldberg Skole"},
    {id:"101039",name:"Randersgades Skole"},
    {id:"101041",name:"Strandvejsskolen"},
    {id:"101042",name:"Vibenshus Skole"},
    {id:"101043",name:"Øster Farimagsgades Skole"},
    {id:"101045",name:"Nørre Fælled Skole"},
    {id:"101047",name:"Christianshavns Skole"},
    {id:"101049",name:"Dyvekeskolen"},
    {id:"101050",name:"Skolen på Amagerbro"},
    {id:"101051",name:"Gerbrandskolen"},
    {id:"101052",name:"Højdevangens Skole"},
    {id:"101053",name:"Skolen ved Sundet"},
    {id:"101055",name:"Sundbyøster Skole"},
    {id:"101058",name:"Lergravsparkens Skole"},
    {id:"101059",name:"Skolen på Islands Brygge"},
    {id:"101060",name:"Peder Lykke Skolen"},
    {id:"101062",name:"Bavnehøj Skole"},
    {id:"101063",name:"Ellebjerg Skole"},
    {id:"101064",name:"Tove Ditlevsens Skole"},
    {id:"101069",name:"Oehlenschlægersgades Skole"},
    {id:"101070",name:"Hanssted Skole"},
    {id:"101071",name:"Kirsebærhavens Skole"},
    {id:"101072",name:"Lykkebo Skole"},
    {id:"101074",name:"Valby Skole"},
    {id:"101075",name:"Vigerslev Allés Skole"},
    {id:"101076",name:"Ålholm Skole"},
    {id:"101077",name:"Svanevej Privatskole"},
    {id:"101078",name:"Carolineskolen"},
    {id:"101079",name:"Christianshavns Døttreskole"},
    {id:"101080",name:"Det Kgl Vaisenhus Skole"},
    {id:"101081",name:"Institut Sankt Joseph"},
    {id:"101082",name:"Sankt Ansgars Skole"},
    {id:"101083",name:"Krebs' Skole"},
    {id:"101084",name:"Sankt Annæ Skole"},
    {id:"101086",name:"Bordings Friskole"},
    {id:"101087",name:"Den Lille Skole i København"},
    {id:"101088",name:"N. Zahles Seminarieskole"},
    {id:"101089",name:"Sankt Petri Skole"},
    {id:"101090",name:"Østerbro Lilleskole"},
    {id:"101091",name:"Hovedstadens Privatskole, Filipskolen"},
    {id:"101092",name:"Amager Lille Skole"},
    {id:"101111",name:"Byens Skole"},
    {id:"101127",name:"Øbro Fri Skole"},
    {id:"101129",name:"Landsbyskolen"},
    {id:"101132",name:"Nørrebro Lilleskole"},
    {id:"101133",name:"Freinet-Skolen"},
    {id:"101135",name:"Vanløse Privatskole"},
    {id:"101138",name:"Lundehusskolen"},
    {id:"101140",name:"Trekronergade Freinetskole"},
    {id:"101145",name:"Ryparken Lille Skole"},
    {id:"101149",name:"DIA Privatskole"},
    {id:"101151",name:"Kildevældsskolen"},
    {id:"101152",name:"Bjørns Internationale Skole"},
    {id:"101153",name:"Ingrid Jespersens Gymnasieskole"},
    {id:"101154",name:"Niels Steensens Gymnasium"},
    {id:"101155",name:"N. Zahles Gymnasieskole"},
    {id:"101157",name:"Sortedamskolen"},
    {id:"101158",name:"Sankt Annæ Gymnasiums Grundskole"},
    {id:"101162",name:"Kildeskolen"},
    {id:"101163",name:"Amager's International School"},
    {id:"101169",name:"Jinnah International School"},
    {id:"101170",name:"Det Frie Gymnasiums Grundskole"},
    {id:"101172",name:"Ahi International School"},
    {id:"101174",name:"Amager Fælled Skole"},
    {id:"101175",name:"Langelinieskolen"},
    {id:"101178",name:"Lygten Skole"},
    {id:"101180",name:"Al Hikma Skolen"},
    {id:"101181",name:"Hay Skolen"},
    {id:"101184",name:"Sjællands Privatskole"},
    {id:"101185",name:"Mariendal Friskole"},
    {id:"101188",name:"Copenhagen City School"},
    {id:"101189",name:"Al-Huda Skolen"},
    {id:"101191",name:"Børneuniversitetet på Vesterbro"},
    {id:"101516",name:"Al Quds Skole"},
    {id:"101526",name:"Al-Hilal Skolen"},
    {id:"101533",name:"Rudolf Steiner Skolen i København"},
    {id:"101537",name:"Heibergskolen"},
    {id:"101540",name:"Utterslev Skole"},
    {id:"101541",name:"Livets Skole"},
    {id:"101564",name:"Øresunds Internationale Skole"},
    {id:"101572",name:"Vesterbro Ny Skole"},
    {id:"101585",name:"Ørestad Friskole"},
    {id:"101586",name:"Gasværksvejens Skole"},
    {id:"101593",name:"Salix Skole"},
    {id:"101621",name:"International School of Hellerup"},
    {id:"147002",name:"Lindevangskolen"},
    {id:"147006",name:"Søndermarkskolen"},
    {id:"147007",name:"Skolen på Duevej"},
    {id:"147008",name:"Skolen ved Bulowsvej"},
    {id:"147010",name:"Skolen på La Cours Vej"},
    {id:"147012",name:"Skolen på Nyelandsvej"},
    {id:"147017",name:"Frederik Barfods Skole"},
    {id:"147018",name:"Kaptajn Johnsens Skole"},
    {id:"147019",name:"Prins Henriks Skole, Lycee Francais De Copenhague"},
    {id:"147031",name:"Frederiksberg Friskole"},
    {id:"147033",name:"Jakobskolen"},
    {id:"147034",name:"Johannesskolen"},
    {id:"147044",name:"Ny Hollænderskolen"},
    {id:"147051",name:"Tre Falke Skolen"},
    {id:"147052",name:"Skolen ved Søerne"},
    {id:"151003",name:"Hedegårdsskolen"},
    {id:"151014",name:"Hareskovens Lilleskole"},
    {id:"151018",name:"Ballerup Ny Skole"},
    {id:"151021",name:"Thomasskolen"},
    {id:"151022",name:"Michael skolen Rudolf Steiner i Hjortespring"},
    {id:"153013",name:"Uddannelsescenter Nygård - 10.Klasseskolen"},
    {id:"153018",name:"Brøndbyøster Skole"},
    {id:"153019",name:"Brøndbyvester Skole"},
    {id:"153020",name:"Brøndby Strand Skole"},
    {id:"155004",name:"Nordstrandskolen"},
    {id:"157001",name:"Bakkegårdsskolen"},
    {id:"157002",name:"Dyssegårdsskolen"},
    {id:"157003",name:"Gentofte Skole"},
    {id:"157005",name:"Maglegårdsskolen"},
    {id:"157007",name:"Munkegårdsskolen"},
    {id:"157008",name:"Ordrup Skole"},
    {id:"157009",name:"Skovgårdsskolen"},
    {id:"157010",name:"Skovshoved Skole"},
    {id:"157011",name:"Tjørnegårdsskolen"},
    {id:"157012",name:"Tranegårdskolen"},
    {id:"157013",name:"Bernadotteskolen"},
    {id:"157015",name:"Busses Skole"},
    {id:"157018",name:"Rygaards Skole"},
    {id:"157019",name:"Sct. Joseph Søstrenes Skole SI"},
    {id:"157020",name:"Rudolf Steiner skolen i Gentofte, Vidar Skolen"},
    {id:"157040",name:"Kildegård Privatskole"},
    {id:"157041",name:"Copenhagen International School"},
    {id:"157047",name:"Hellerup Skole"},
    {id:"159001",name:"Bagsværd Skole"},
    {id:"159002",name:"Buddinge Skole"},
    {id:"159004",name:"Enghavegård Skole"},
    {id:"159005",name:"Gladsaxe Skole"},
    {id:"159006",name:"Grønnemose Skole"},
    {id:"159008",name:"Mørkhøj Skole"},
    {id:"159009",name:"Stengård Skole"},
    {id:"159010",name:"Søborg Skole"},
    {id:"159012",name:"Vadgård Skole"},
    {id:"159013",name:"Skovbrynet skole"},
    {id:"159015",name:"Den Lille Skole"},
    {id:"159016",name:"Kvikmarkens Privatskole"},
    {id:"159017",name:"Gladsaxe Privatskole"},
    {id:"159018",name:"Bagsværd Friskole (Hanna-Skolen)"},
    {id:"159034",name:"Bagsværd Gymnasiums Grundskole"},
    {id:"159038",name:"GXU - Gladsaxe 10. Klasse og Ungdomsskole, 10. klasse afdelingen"},
    {id:"159040",name:"Atheneskolen"},
    {id:"161002",name:"Glostrup skole, Nordvang"},
    {id:"161003",name:"Glostrup skole, Søndervang"},
    {id:"161004",name:"Glostrup Skole, Vestervang"},
    {id:"161010",name:"Glostrup Skole, Skovvang"},
    {id:"163001",name:"Lindehøjskolen"},
    {id:"163004",name:"Herlev byskole"},
    {id:"163006",name:"Kildegårdskolen"},
    {id:"163009",name:"Herlev Privatskole"},
    {id:"165001",name:"Egelundskolen"},
    {id:"165011",name:"Albertslund Lille Skole"},
    {id:"165019",name:"Herstedøster Skole"},
    {id:"165020",name:"Herstedvester Skole"},
    {id:"167001",name:"Dansborgskolen"},
    {id:"167002",name:"Gungehusskolen"},
    {id:"167003",name:"Holmegårdsskolen"},
    {id:"167004",name:"Langhøjskolen"},
    {id:"167005",name:"Præstemoseskolen"},
    {id:"167006",name:"Risbjergskolen"},
    {id:"167008",name:"Engstrandskolen"},
    {id:"167009",name:"Avedøre Skole"},
    {id:"167011",name:"Frydenhøjskolen"},
    {id:"167013",name:"Esajasskolen"},
    {id:"169001",name:"Fløng Skole"},
    {id:"169003",name:"Hedehusene Skole"},
    {id:"169006",name:"Reerslev Skole"},
    {id:"169007",name:"Borgerskolen"},
    {id:"169008",name:"Mølleholmskolen"},
    {id:"169010",name:"Sengeløse Skole"},
    {id:"169012",name:"Taastrup Realskole"},
    {id:"169013",name:"Charlotteskolen"},
    {id:"169016",name:"Skt. Pauls Skole"},
    {id:"169018",name:"Vestegnens Privatskole"},
    {id:"169019",name:"Torstorp Skole"},
    {id:"169021",name:"LINIE10"},
    {id:"171001",name:"Søagerskolen"},
    {id:"171002",name:"Boesagerskolen"},
    {id:"171004",name:"Balsmoseskolen"},
    {id:"173002",name:"Engelsborgskolen"},
    {id:"173003",name:"Fuglsanggårdsskolen"},
    {id:"173004",name:"Hummeltofteskolen"},
    {id:"173005",name:"Kongevejens Skole"},
    {id:"173006",name:"Lindegårdsskolen"},
    {id:"173007",name:"Lundtofte Skole"},
    {id:"173008",name:"Taarbæk Skole"},
    {id:"173009",name:"Trongårdsskolen"},
    {id:"173011",name:"Virum Skole"},
    {id:"173012",name:"Billums Privatskole"},
    {id:"173013",name:"Lyngby Private Skole"},
    {id:"173014",name:"Skt. Knud Lavard Skole"},
    {id:"173020",name:"Lyngby Friskole"},
    {id:"175001",name:"Hendriksholm Skole"},
    {id:"175002",name:"Islev Skole"},
    {id:"175003",name:"Nyager Skole"},
    {id:"175004",name:"Rødovre Skole"},
    {id:"175006",name:"Tinderhøj Skole"},
    {id:"175007",name:"Valhøj Skole"},
    {id:"175017",name:"Frederiksberg Privatskole"},
    {id:"181002",name:"Ny Holte Skole"},
    {id:"181003",name:"Nærum Skole"},
    {id:"181006",name:"Vangeboskolen"},
    {id:"181007",name:"Vedbæk Skole"},
    {id:"181008",name:"Skovlyskolen"},
    {id:"181009",name:"Trørødskolen"},
    {id:"181010",name:"Nærum Privatskole"},
    {id:"181016",name:"Dronninggårdskolen"},
    {id:"181017",name:"Rudersdal Lilleskole"},
    {id:"183001",name:"Ishøj Skole"},
    {id:"183002",name:"Vibeholmskolen"},
    {id:"183003",name:"Gildbroskolen"},
    {id:"183004",name:"Strandgårdskolen"},
    {id:"183005",name:"Vejlebroskolen"},
    {id:"183008",name:"Ådalens Privatskole"},
    {id:"185002",name:"Korsvejens Skole"},
    {id:"185003",name:"Løjtegårdsskolen"},
    {id:"185004",name:"Nordregårdsskolen"},
    {id:"185005",name:"Pilegårdsskolen"},
    {id:"185006",name:"Skelgårdsskolen"},
    {id:"185010",name:"Kastrupgårdsskolen"},
    {id:"185013",name:"Amager Privatskole"},
    {id:"185014",name:"Skottegårdsskolen"},
    {id:"187001",name:"Egholmskolen"},
    {id:"187002",name:"Pilehaveskolen"},
    {id:"187003",name:"Vallensbæk Skole"},
    {id:"187005",name:"Gideonskolen"},
    {id:"189001",name:"Hareskov Skole"},
    {id:"189002",name:"Lille Værløse Skole"},
    {id:"189003",name:"Søndersøskolen"},
    {id:"189004",name:"Syvstjerneskolen"},
    {id:"189006",name:"Egeskolen"},
    {id:"190001",name:"Hillerød Privatskole"},
    {id:"190003",name:"Lyngholmskolen"},
    {id:"201001",name:"Blovstrød Skole"},
    {id:"201002",name:"Lynge Skole"},
    {id:"201005",name:"Kratbjergskolen afd. Engholm"},
    {id:"201006",name:"Kratbjergskolen afd. Ravnsholt"},
    {id:"205001",name:"Sjælsøskolen"},
    {id:"205002",name:"Bistrupskolen"},
    {id:"205003",name:"Høsterkøb Skole"},
    {id:"205005",name:"Toftevangskolen"},
    {id:"205006",name:"Birkerød Privatskole"},
    {id:"207002",name:"Solvangskolen"},
    {id:"207003",name:"Stavnsholtskolen"},
    {id:"207007",name:"Marie Kruses Skole"},
    {id:"208005",name:"Humlebæk Lille Skole"},
    {id:"208007",name:"Endrupskolen"},
    {id:"208008",name:"Langebjergskolen"},
    {id:"208009",name:"Skolen på Slotsvænget"},
    {id:"209005",name:"Frederikssund Private Realskole"},
    {id:"211004",name:"Melby Skole"},
    {id:"211006",name:"Ølsted Skole"},
    {id:"211009",name:"Magleblik Skole"},
    {id:"213005",name:"Havregården Lille-Kost og Dagskole"},
    {id:"213007",name:"Søborg Privatskole"},
    {id:"213008",name:"Alme Kristne Friskole"},
    {id:"213010",name:"Esrum Kostskole"},
    {id:"215001",name:"Bjørnehøjskolen"},
    {id:"215004",name:"Ramløse Skole"},
    {id:"215007",name:"Helsinge Realskole"},
    {id:"215008",name:"Gribskov Skole"},
    {id:"217001",name:"Espergærdeskolen"},
    {id:"217002",name:"Hellebækskolen"},
    {id:"217003",name:"Hornbæk Skole"},
    {id:"217004",name:"Mørdrupskolen"},
    {id:"217008",name:"Skolen ved Gurrevej"},
    {id:"217010",name:"Skolen ved Rønnebær Allé"},
    {id:"217012",name:"Tikøb Skole"},
    {id:"217013",name:"Tibberupskolen"},
    {id:"217014",name:"Nordvestskolen"},
    {id:"217016",name:"Helsingør Lille Skole"},
    {id:"217018",name:"Borupgårdskolen"},
    {id:"217020",name:"Rudolf Steiner Skolen Kvistgård"},
    {id:"217021",name:"Grydemoseskolen"},
    {id:"217024",name:"Byskolen"},
    {id:"217029",name:"Al-Irchad Skolen"},
    {id:"217033",name:"10.Klasseskolen"},
    {id:"219002",name:"Frederiksborg Byskole"},
    {id:"219003",name:"Hillerødsholmskolen"},
    {id:"219009",name:"Hillerød Lilleskole"},
    {id:"219010",name:"Marie Mørks Skole"},
    {id:"219011",name:"Johannesskolen"},
    {id:"219016",name:"Nordsjællands Friskole"},
    {id:"219022",name:"Kon Tiki - Børnenes Skole"},
    {id:"219024",name:"10. klasseskolen"},
    {id:"219030",name:"Sophienborgskolen"},
    {id:"221004",name:"Halsnæs Lilleskole"},
    {id:"223001",name:"Hørsholm Skole"},
    {id:"223002",name:"Rungsted Skole"},
    {id:"223003",name:"Usserød Skole"},
    {id:"223004",name:"Vallerødskolen"},
    {id:"223006",name:"Rungsted Private Realskole"},
    {id:"223007",name:"Hørsholm Lille Skole"},
    {id:"227008",name:"Nordsjællands Grundskole og Gymnasium samt HF"},
    {id:"231005",name:"Sigerslevøster Privatskole"},
    {id:"235001",name:"Ganløse Skole"},
    {id:"235003",name:"Lærkeskolen"},
    {id:"235004",name:"Slagslunde Skole"},
    {id:"235006",name:"Stenløse Privatskole"},
    {id:"235007",name:"Veksø Skole"},
    {id:"235210",name:"Ung Egedal"},
    {id:"237002",name:"Toftehøjskolen"},
    {id:"237003",name:"Stengårdsskolen"},
    {id:"237004",name:"Maglehøjskolen"},
    {id:"237006",name:"Hampelandskolen"},
    {id:"237007",name:"Bækkegårdsskolen"},
    {id:"250004",name:"Jægerspris Skole"},
    {id:"251001",name:"Bramsnæsvigskolen"},
    {id:"251002",name:"Kirke Hyllinge Skole"},
    {id:"253001",name:"Hedelyskolen"},
    {id:"253002",name:"Karlslunde Skole"},
    {id:"253003",name:"Krogårdskolen"},
    {id:"253004",name:"Mosedeskolen"},
    {id:"253005",name:"Strandskolen"},
    {id:"253006",name:"Tune Skole"},
    {id:"253008",name:"Arenaskolen"},
    {id:"253009",name:"Hundige Lille Skole"},
    {id:"253011",name:"Damagerskolen"},
    {id:"253013",name:"Greve Privatskole"},
    {id:"253015",name:"Holmeagerskolen"},
    {id:"255001",name:"Lindebjergskolen"},
    {id:"255003",name:"Margretheskolen"},
    {id:"255004",name:"Nordskolen, afdeling Jyllinge"},
    {id:"255005",name:"Roskilde Lille Skole"},
    {id:"255006",name:"Nordskolen, afdeling Baunehøj"},
    {id:"257001",name:"Hvalsø Skole"},
    {id:"257002",name:"Kirke Saaby Skole"},
    {id:"259001",name:"Alkestrupskolen"},
    {id:"259003",name:"Ellemarkskolen"},
    {id:"259004",name:"Herfølge Skole"},
    {id:"259005",name:"Højelse Skole"},
    {id:"259006",name:"Kirstinedalsskolen"},
    {id:"259008",name:"Søndre Skole"},
    {id:"259011",name:"Hastrupskolen"},
    {id:"259013",name:"Køge Lille Skole"},
    {id:"259015",name:"Holmebækskolen"},
    {id:"259019",name:"Sct. Nicolai Skole"},
    {id:"259020",name:"Herfølge Friskole"},
    {id:"259021",name:"Billesborgskolen"},
    {id:"259022",name:"Køge Private Realskole"},
    {id:"259025",name:"Asgård Skole"},
    {id:"261001",name:"Allerslev Skole"},
    {id:"261002",name:"Osted Skole"},
    {id:"261004",name:"Trællerupskolen"},
    {id:"261005",name:"Osted Fri og Efterskole"},
    {id:"263001",name:"Gadstrup Skole"},
    {id:"263002",name:"Viby Skole, afdeling Ørstedvej"},
    {id:"263003",name:"Viby Friskole"},
    {id:"263004",name:"Viby Skole, afdeling Bueager"},
    {id:"265001",name:"Absalons Skole"},
    {id:"265005",name:"Hedegårdenes Skole"},
    {id:"265006",name:"Himmelev Skole"},
    {id:"265007",name:"Klostermarksskolen"},
    {id:"265008",name:"Lynghøjskolen"},
    {id:"265010",name:"Sct. Jørgens Skole"},
    {id:"265011",name:"Vindinge Skole"},
    {id:"265012",name:"Vor Frue Skole"},
    {id:"265013",name:"Østervangsskolen"},
    {id:"265015",name:"Tjørnegårdskolen"},
    {id:"265017",name:"Roskilde Private Realskole"},
    {id:"265018",name:"Roskilde Private Skole"},
    {id:"265019",name:"Skt. Josefs Skole"},
    {id:"265030",name:"Kristofferskolen"},
    {id:"265031",name:"TCR TiendeklasseCenter Roskilde"},
    {id:"265032",name:"Trekronerskolen"},
    {id:"265036",name:"Freds Skole"},
    {id:"267001",name:"Skovboskolen"},
    {id:"267002",name:"Borup Skole"},
    {id:"267003",name:"Ejby Skole"},
    {id:"267005",name:"Vemmedrupskolen"},
    {id:"267008",name:"Borup Privatskole"},
    {id:"269001",name:"Havdrup Skole"},
    {id:"269005",name:"Uglegårdsskolen"},
    {id:"269006",name:"Sydkystens Privatskole"},
    {id:"269007",name:"Munkekærskolen"},
    {id:"269009",name:"Køge Bugt Privatskole"},
    {id:"269012",name:"10Solrød"},
    {id:"270002",name:"Sankt Helene Skole"},
    {id:"271001",name:"Hotherskolen"},
    {id:"271002",name:"Strøbyskolen"},
    {id:"280033",name:"Ugelbølle Friskole"},
    {id:"280038",name:"Spjellerup Friskole"},
    {id:"280039",name:"Helsingør Privatskole"},
    {id:"280040",name:"Midtdjurs Friskole"},
    {id:"280043",name:"Distrikt Syd, Hørby-Dybvad skoleafdeling"},
    {id:"280047",name:"Ørestad Skole"},
    {id:"280048",name:"Maribo Skole, Borgerskolen"},
    {id:"280049",name:"Maribo Skole, Blæsenborgskolen"},
    {id:"280053",name:"Feldballe Friskole"},
    {id:"280055",name:"Nr. Asmindrup Friskole"},
    {id:"280091",name:"Skolen på Grundtvigsvej"},
    {id:"280093",name:"SkoleCenter Hirtshals"},
    {id:"280094",name:"Sindal Skolecenter"},
    {id:"280096",name:"Løkken-Vrå Skole"},
    {id:"280105",name:"10 i Campus"},
    {id:"280106",name:"Herstedlund Skole"},
    {id:"280111",name:"Fællesskolen HoptrupMarstrupVilstrup"},
    {id:"280113",name:"Nymarkskolen"},
    {id:"280117",name:"Brobyskolerne"},
    {id:"280118",name:"Lille Næstved Skole, afd. Fuglebjerg"},
    {id:"280119",name:"Fredensborg Skole"},
    {id:"280120",name:"Humlebæk Skole"},
    {id:"280121",name:"Nivå Skole"},
    {id:"280122",name:"Kokkedal Skole"},
    {id:"280129",name:"Skolen i Sydhavnen"},
    {id:"280135",name:"Vestermarkskolen"},
    {id:"280136",name:"Issø-skolen"},
    {id:"280137",name:"Stokkebækskolen"},
    {id:"280138",name:"Skårup Skole"},
    {id:"280139",name:"Tåsingeskolen"},
    {id:"280140",name:"Tybjerg Privatskole"},
    {id:"280161",name:"Toppedalskolen"},
    {id:"280163",name:"Hillerød Vest Skolen"},
    {id:"280164",name:"Grønnevang skole"},
    {id:"280165",name:"Hanebjerg Skole"},
    {id:"280166",name:"Kornmarkskolen"},
    {id:"280168",name:"Kobberbakkeskolen, afd. Sjølund"},
    {id:"280186",name:"St. Magleby Skole"},
    {id:"280187",name:"Dragør Skole"},
    {id:"280189",name:"Brørupskolen"},
    {id:"280190",name:"Holmeå Børnecenter"},
    {id:"280192",name:"Tønder Distriktsskole"},
    {id:"280193",name:"Løgumkloster Distriktsskole"},
    {id:"280195",name:"Toftlund Distriktsskole"},
    {id:"280197",name:"Frederiksværk Skole"},
    {id:"280198",name:"Hundested Skole"},
    {id:"280200",name:"Nykøbing Skole"},
    {id:"280202",name:"Sønderborg International School"},
    {id:"280204",name:"Astrup Friskole"},
    {id:"280205",name:"Esbjerg International School"},
    {id:"280207",name:"Friskolen Skallerup"},
    {id:"280208",name:"Grænseegnens Friskole"},
    {id:"280210",name:"Idestrup Privatskole"},
    {id:"280211",name:"Sindal Privatskole"},
    {id:"280212",name:"Ingstrup Trivselsskole"},
    {id:"280215",name:"Klitmøller Friskole"},
    {id:"280216",name:"Roser Skole"},
    {id:"280218",name:"Stepping Friskole"},
    {id:"280220",name:"Vejlernes NaturFriskole"},
    {id:"280221",name:"Vendsyssel Friskole"},
    {id:"280222",name:"Ådalens Skole"},
    {id:"280224",name:"Randlevskolen"},
    {id:"280226",name:"Langtved Friskole"},
    {id:"280227",name:"Lørslev Friskole"},
    {id:"280238",name:"Fællesskolen Bevtoft-Over Jerstal"},
    {id:"280240",name:"Fællesskolen Favrdal-Fjelstrup"},
    {id:"280241",name:"Fællesskolen Hammelev-Sct. Severin"},
    {id:"280243",name:"Fællesskolen Nustrup-Sommersted"},
    {id:"280244",name:"Starup-Øsby Skole og Børnehus"},
    {id:"280270",name:"Gilbjergskolen"},
    {id:"280271",name:"Gribskolen"},
    {id:"280272",name:"Nordstjerneskolen"},
    {id:"280273",name:"Struer Statsgymnasium - 10. klassecentret"},
    {id:"280276",name:"10. klasse Erhverv Fårevejle"},
    {id:"280284",name:"Ørkildskolen"},
    {id:"280295",name:"Holbæk By Skole, afdeling Bjergmark"},
    {id:"280315",name:"Distrikt Nord, Skagen skoleafdeling"},
    {id:"280316",name:"BillundSkolen"},
    {id:"280319",name:"Kongehøjskolen"},
    {id:"280320",name:"Hærvejsskolen"},
    {id:"280321",name:"Lyreskovskolen"},
    {id:"280323",name:"NEXT Uddannelse København, 10. klasse Frederiksberg"},
    {id:"280325",name:"Glostrup skole"},
    {id:"280327",name:"Birkerød Skole"},
    {id:"280331",name:"Vejle Midtbyskole"},
    {id:"280332",name:"Firehøjeskolen"},
    {id:"280333",name:"Fælleshåbsskolen"},
    {id:"280334",name:"Firkløverskolen"},
    {id:"280335",name:"Bredsten - Gadbjerg Skole"},
    {id:"280341",name:"Byskovskolen"},
    {id:"280342",name:"Campusskolen"},
    {id:"280348",name:"Distrikt Øst, Nordstjernen skoleafdeling"},
    {id:"280350",name:"Gåsetårnskolen"},
    {id:"280351",name:"Præstø skole"},
    {id:"280352",name:"Møn skole"},
    {id:"280353",name:"Kulsbjerg Skole"},
    {id:"280354",name:"Svend Gønge-skolen"},
    {id:"280356",name:"Kulsbjerg Skole, Mern"},
    {id:"280357",name:"Midtskolen"},
    {id:"280358",name:"Ak10vet"},
    {id:"280361",name:"Østskolen"},
    {id:"280362",name:"Vestskolen"},
    {id:"280366",name:"Vestskolen, Vibeeng afdeling"},
    {id:"280371",name:"10. klasse Gribskov og modtageklasser"},
    {id:"280376",name:"Haverslev-Ravnkilde Skole"},
    {id:"280379",name:"Sofiehøj Friskole"},
    {id:"280380",name:"Allindelille Friskole"},
    {id:"280381",name:"Slotsparkens Friskole"},
    {id:"280382",name:"Bedsted Friskole"},
    {id:"280383",name:"Mariagerfjord Idrætsskole"},
    {id:"280384",name:"Hald Ege Høj-, Fri- & Efterskole (friskolen)"},
    {id:"280385",name:"Skovlund Friskole"},
    {id:"280386",name:"Svenstrup Friskole"},
    {id:"280387",name:"Jejsing Friskole"},
    {id:"280388",name:"Filskov Friskole"},
    {id:"280389",name:"Margrethe Reedtz Skolen"},
    {id:"280390",name:"Voldumegnens Friskole"},
    {id:"280391",name:"Kværkeby Friskole"},
    {id:"280392",name:"Ølgod Kristne Friskole"},
    {id:"280393",name:"Vestermose Natur- og Idrætsfriskole"},
    {id:"280395",name:"Ringsted Lilleskole"},
    {id:"280396",name:"Ros Privatskole"},
    {id:"280397",name:"Aarhus Academy for Global Education"},
    {id:"280398",name:"Ejsing Friskole"},
    {id:"280405",name:"Romalt Friskole"},
    {id:"280406",name:"Snekkersten Skoledistrikt"},
    {id:"280407",name:"Helsingør Skole"},
    {id:"280408",name:"Nygård Skole"},
    {id:"280409",name:"Espergærde Skole"},
    {id:"280411",name:"Ådalskolen"},
    {id:"280415",name:"Ullerup Bæk Skolen"},
    {id:"280416",name:"Kirstinebjergskolen, distriktskontoret"},
    {id:"280417",name:"Erritsø Fællesskole"},
    {id:"280418",name:"Fjordbakkeskolen"},
    {id:"280425",name:"Skolen på Strandboulevarden"},
    {id:"280430",name:"Snekkersten Skole"},
    {id:"280433",name:"TEC , 10. klassecenter"},
    {id:"280434",name:"Nordals skolen"},
    {id:"280435",name:"SOSU H København, 10. klasse"},
    {id:"280436",name:"SOSU H Hillerød, 10. klasse"},
    {id:"280438",name:"Hotel- og Restaurantskolen, 10. klassecenter"},
    {id:"280447",name:"Skorpeskolen"},
    {id:"280456",name:"Kerteminde Byskole"},
    {id:"280457",name:"Munkebo Skole - mellem nor og fjord"},
    {id:"280458",name:"Tranbjergskolen"},
    {id:"280460",name:"Friskolen Vellev"},
    {id:"280461",name:"Skelbæk Friskole"},
    {id:"280462",name:"The International School of Billund"},
    {id:"280463",name:"Jelling Friskole"},
    {id:"280464",name:"Hellested Friskole og Børnehus"},
    {id:"280465",name:"Kværs Idrætsfriskole"},
    {id:"280466",name:"Den Nye Friskole"},
    {id:"280467",name:"Jersie Privatskole"},
    {id:"280471",name:"Høje Taastrup Privatskole"},
    {id:"280473",name:"10. klassecenter Grindsted, Syddansk Erhvervsskole Odense-Vejle"},
    {id:"280474",name:"Vejle Privatskole"},
    {id:"280481",name:"Skolecenter Jetsmark"},
    {id:"280489",name:"UNORD, grundskoleafd. Hillerød"},
    {id:"280490",name:"UNORD, grundskoleafd. Helsingør"},
    {id:"280491",name:"Kalvebod Fælled Skole"},
    {id:"280494",name:"Distriktsskole Ølstykke"},
    {id:"280495",name:"Distriktsskole Stenløse"},
    {id:"280496",name:"Distriktsskole Ganløse"},
    {id:"280497",name:"Distriktsskole Smørum"},
    {id:"280498",name:"Hillerød 10. klasse, UNORD"},
    {id:"280500",name:"Studie 10"},
    {id:"280501",name:"Ansgarskolen Vadehav"},
    {id:"280502",name:"Vitaskolen Bohr"},
    {id:"280505",name:"Skolerne i Egebjerg, Nykøbing og Odden"},
    {id:"280506",name:"Skolerne i Asnæs, Fårevejle og Hørve"},
    {id:"280508",name:"Stevns Dagskole"},
    {id:"280509",name:"NEXT Uddannelse København, 10. klasse Ishøj 65"},
    {id:"280521",name:"HANSENBERG, 10. klasse"},
    {id:"280524",name:"European School Copenhagen - Primary and Secondary"},
    {id:"280525",name:"IBC, 10. klasse"},
    {id:"280532",name:"Præstø Privatskole"},
    {id:"280534",name:"Fur Friskole"},
    {id:"280535",name:"10. klasse Campus Køge"},
    {id:"280537",name:"Bredballe Privatskole"},
    {id:"280538",name:"Hammer Frie Privatskole"},
    {id:"280540",name:"Slagelse Privatskole"},
    {id:"280541",name:"GYM-10 Odsherreds Gymnasium"},
    {id:"280542",name:"Park Allé Privatskole"},
    {id:"280545",name:"Den dansk-franske Skole af 2014"},
    {id:"280549",name:"Stadil-Vedersø Friskole"},
    {id:"280551",name:"Københavns Skole & Idrætsakademi"},
    {id:"280553",name:"Den Grønne Friskole"},
    {id:"280562",name:"Baltorpskolen"},
    {id:"280563",name:"Skovvejens Skole"},
    {id:"280564",name:"Skovlunde Skole"},
    {id:"280565",name:"Måløvhøj Skole"},
    {id:"280579",name:"NEXT Uddannelse København, 10. klasse Ballerup"},
    {id:"280580",name:"UCRS, 10´eren, Ringkøbing"},
    {id:"280581",name:"UCRS, 10´eren Skjern"},
    {id:"280583",name:"EUC Nordvest, 10. klasse"},
    {id:"280585",name:"NEXT Uddannelse København, 10. klasse Ishøj 45"},
    {id:"280587",name:"GXU - Gladsaxe 10.klasse og Ungdomsskole"},
    {id:"280588",name:"Lyngby 10. klasse, UNORD"},
    {id:"280589",name:"Frederikssund 10. klasse, UNORD"},
    {id:"280595",name:"Distrikt Øst"},
    {id:"280596",name:"Distrikt Vest"},
    {id:"280597",name:"Skoledistrikt Nord"},
    {id:"280598",name:"Skoledistrikt Syd"},
    {id:"280616",name:"Signaturskolen"},
    {id:"280617",name:"Cosmosskolen"},
    {id:"280618",name:"Urbanskolen"},
    {id:"280619",name:"Bohrskolen"},
    {id:"280620",name:"Vadehavsskolen"},
    {id:"280621",name:"Fortunaskolen"},
    {id:"280622",name:"Auraskolen"},
    {id:"280623",name:"Tradium, EUD 10"},
    {id:"280626",name:"Hjørring Nordvestskole"},
    {id:"280627",name:"Hjørring Sydøstskole"},
    {id:"280629",name:"Slangerup Skole"},
    {id:"280631",name:"Fjordlandsskolen"},
    {id:"280633",name:"Trekløverskolen"},
    {id:"280638",name:"Per Gyrum Skolen"},
    {id:"280640",name:"CampusU10"},
    {id:"280641",name:"Svaneke Friskole"},
    {id:"280643",name:"Hvidovre Privatskole"},
    {id:"280650",name:"Vinderød Privatskole"},
    {id:"280651",name:"Nørreådal Friskole"},
    {id:"280653",name:"Poulstrup Friskole og Børnehus"},
    {id:"280654",name:"Feldborg Frie Børneunivers"},
    {id:"280655",name:"Friskolen for Hundelev og Omegn"},
    {id:"280656",name:"Odense Privatskole"},
    {id:"280657",name:"VerdensBørn Grundskole"},
    {id:"280658",name:"Marieskolen"},
    {id:"280659",name:"Agersted Friskole"},
    {id:"280662",name:"Astrup-Sønderskov Friskole"},
    {id:"280665",name:"Københavns Kommunes Ungdomsskole Ø 10"},
    {id:"280667",name:"Københavns Kommunes Ungdomsskole 10. vest"},
    {id:"280668",name:"Københavns Kommunes Ungdomsskole Amager 10"},
    {id:"280669",name:"Brahesminde Skole"},
    {id:"280670",name:"Naturfriskolen Nordmors"},
    {id:"280673",name:"Friskolen Asgaard"},
    {id:"280674",name:"Kundby Friskole"},
    {id:"280675",name:"Skolen for Livet"},
    {id:"280678",name:"Læringscenter Syd"},
    {id:"280679",name:"Aulum-Hodsager Skole"},
    {id:"280680",name:"Sunds-Ilskov Skole"},
    {id:"280682",name:"EUD10 Syddjurs"},
    {id:"280686",name:"Limfjordsskolen Struer"},
    {id:"280691",name:"Nyborg Gymnasium, 10. klasse-center"},
    {id:"280704",name:"Brønderslev Nord"},
    {id:"280705",name:"Brønderslev Syd"},
    {id:"280706",name:"Hjallerup"},
    {id:"280707",name:"Dronninglund"},
    {id:"280708",name:"NEXT Uddannelse København, EUD10 Ishøj 45"},
    {id:"280726",name:"EUD10 Djursland - Auning"},
    {id:"280731",name:"Aarhus Business College, EUD10, Sønderhøj 28"},
    {id:"280744",name:"Lille Næstved Skole"},
    {id:"280745",name:"Kobberbakkeskolen"},
    {id:"280746",name:"Fladsåskolen"},
    {id:"280747",name:"Susåskolen"},
    {id:"280748",name:"Holmegaardskolen"},
    {id:"280749",name:"Ellebækskolen"},
    {id:"280753",name:"Kerteminde Kommunale Ungdomsskole, Kerteminde 10. KlasseCenter"},
    {id:"280755",name:"Heldagsskolen Vantinge"},
    {id:"280760",name:"Horsens Byskole"},
    {id:"280792",name:"Allerød Privatskole"},
    {id:"280794",name:"Lille Næstved Skole, afd. Karrebækvej"},
    {id:"280795",name:"Lille Næstved Skole, afd. Sandved"},
    {id:"280796",name:"Kobberbakkeskolen, afd. Uglebro"},
    {id:"280797",name:"Holmegaardskolen, afd. Holme-Olstrup"},
    {id:"280800",name:"Holbæk By Skole"},
    {id:"280801",name:"Kildedamsskolen"},
    {id:"280802",name:"Skovvejens Skole"},
    {id:"280803",name:"Katrinedalskolen"},
    {id:"280804",name:"Kratbjergskolen"},
    {id:"280805",name:"Lillevang Skole"},
    {id:"280806",name:"Hovedgård Skole"},
    {id:"280808",name:"Frederikshavn Friskole"},
    {id:"280809",name:"Odense Designakademi & Friskolen Glasværket"},
    {id:"280810",name:"Øhavsskolen"},
    {id:"280811",name:"Thy Privatskole"},
    {id:"280813",name:"Karrebæksminde Privatskole"},
    {id:"280814",name:"Unge- og Kulturcenter Halsnæs, 10. klassecenter"},
    {id:"280815",name:"Overlade Friskole"},
    {id:"280817",name:"Blære Friskole"},
    {id:"280825",name:"Damhusengens Skole"},
    {id:"280831",name:"Uddannelsescenter Holstebro, 10. klasse"},
    {id:"280832",name:"10´eren i Middelfart Kommune"},
    {id:"280855",name:"Trekløverskolen, Silkeborg"},
    {id:"280856",name:"Funder-Kragelund Skole, Silkeborg"},
    {id:"280865",name:"UNORD, grundskoleafd. Frederiksskund, 10. klasse"},
    {id:"280866",name:"Zealand Business College, 10. klasse"},
    {id:"280873",name:"NEXT Uddannelse København,10. klasse Nørrebro"},
    {id:"280874",name:"NEXT Uddannelse København, 10. klasse Emdrup"},
    {id:"280875",name:"NEXT Uddannelse København, 10. klasse Vibenshus"},
    {id:"280876",name:"NEXT Uddannelse København, 10. klasse Kastrup"},
    {id:"280889",name:"SOSU H Frederikssund, 10. klasse"},
    {id:"280890",name:"SOSU H Helsingør, 10. klasse"},
    {id:"280918",name:"Efterskolen Ådalen og Åskolen - friskolen i Hørning"},
    {id:"280928",name:"Vestbyen Friskole"},
    {id:"280929",name:"Billum Friskole"},
    {id:"280930",name:"Privatskolen i Jerup"},
    {id:"280931",name:"Skolen ved Havet"},
    {id:"280934",name:"KILDEN- Børne- og Ungeunivers"},
    {id:"280937",name:"Lynghedeskolen"},
    {id:"280970",name:"10. klassecenteret Nordfyn, Syddansk Erhvervsskole"},
    {id:"280980",name:"Ole Rømer-Skolen"},
    {id:"281002",name:"Sæby Friskole"},
    {id:"281003",name:"Den Frie Fakkel"},
    {id:"281004",name:"Friskolen på Røsnæs"},
    {id:"281006",name:"Nordøstsalling Skoler og Dagtilbud"},
    {id:"281011",name:"Nordenskov-Næsbjerg Skole"},
    {id:"281013",name:"Blåbjergskolen"},
    {id:"281018",name:"Unity Skolen"},
    {id:"281053",name:"Skolen i Nordøstamager"},
    {id:"281054",name:"Lindbjergskolen"},
    {id:"281060",name:"Ny Skole på Sluseholmen"},
    {id:"281070",name:"Søren Kanne Børneby"},
    {id:"281071",name:"Børneby Midt"},
    {id:"281072",name:"Børneby Nord"},
    {id:"281081",name:"Egedal 10. klassecenter"},
    {id:"281082",name:"Herningsholm Erhvervsskole & Gymnasier, 10. klasse"},
    {id:"281092",name:"Dalum Landbrugsskole, 10. klasse"},
    {id:"281099",name:"Fund Friskole"},
    {id:"281103",name:"10 KCD, Viden Djurs"},
    {id:"281210",name:"Idrætsskolerne Ikast (friskolen)"},
    {id:"281211",name:"Viby Skole"},
    {id:"281212",name:"Nordskolen"},
    {id:"281242",name:"Byens Steinerskole"},
    {id:"281276",name:"Farum Lilleskole"},
    {id:"281286",name:"EUC Nordvestsjælland, 10. klasse"},
    {id:"281293",name:"Skolefællesskabet Skals - Ulbjerg Skole"},
    {id:"281294",name:"Skolefællesskabet Mønsted - Sparkær"},
    {id:"301004",name:"Firhøjskolen"},
    {id:"301005",name:"Sejerø Skole"},
    {id:"301007",name:"Svebølle Skole"},
    {id:"301008",name:"Højbo Friskole"},
    {id:"301010",name:"Bregninge-Bjergsted Friskole"},
    {id:"303001",name:"Holbergskolen"},
    {id:"303003",name:"Ruds Vedby Skole"},
    {id:"305002",name:"Asnæs Skole"},
    {id:"305004",name:"Fårevejle Skole"},
    {id:"305008",name:"Hørve Skole"},
    {id:"305011",name:"Fårevejle Fri-og Efterskole"},
    {id:"305015",name:"Vallekilde-Hørve Friskole"},
    {id:"307004",name:"Hindholm Privatskole"},
    {id:"309001",name:"Gørlev Skole"},
    {id:"309002",name:"Kirke-Helsinge Skole"},
    {id:"309004",name:"Vinde Helsinge Friskole"},
    {id:"311001",name:"Dalmose skole"},
    {id:"311002",name:"Flakkebjerg Skole"},
    {id:"311003",name:"Hashøjskolen"},
    {id:"311004",name:"Hvilebjergskolen"},
    {id:"313002",name:"Midtskolen, Sofiendal afdeling"},
    {id:"313003",name:"Vestskolen, Nordskov afdeling"},
    {id:"313005",name:"Vestskolen, Terslev afdeling"},
    {id:"313007",name:"Haslev Privatskole"},
    {id:"315002",name:"Holbæk By Skole, afdeling Orø"},
    {id:"315005",name:"Katrinedalskolen, afdeling Tuse"},
    {id:"315006",name:"Katrinedalskolen, afdeling Udby"},
    {id:"315007",name:"Kildedamsskolen, afdeling Vipperød"},
    {id:"315009",name:"Kildedamsskolen, afdeling Ågerup"},
    {id:"315011",name:"Holbæk Private Realskole"},
    {id:"315012",name:"Stenhus Kostskole"},
    {id:"315013",name:"Andreasskolen"},
    {id:"315017",name:"Holbæk Lilleskole"},
    {id:"315021",name:"Holbæk By Skole, afdeling Absalon"},
    {id:"316001",name:"Holbæk Ungdomscenter"},
    {id:"317001",name:"Hvidebækskolen"},
    {id:"317004",name:"Rørby Skole"},
    {id:"317006",name:"Friskolen og Idrætsefterskolen Ubby"},
    {id:"319001",name:"Buerup Skole"},
    {id:"319002",name:"Høng Skole"},
    {id:"319003",name:"Løve-Ørslev Skole"},
    {id:"319007",name:"Høng Privatskole"},
    {id:"319010",name:"Sæby-Hallenslev Friskole"},
    {id:"321002",name:"Skovvejens Skole, afdeling Nr. Jernløse"},
    {id:"321004",name:"Skovvejens Skole, afdeling Undløse"},
    {id:"321005",name:"Sdr. Jernløse Lilleskole"},
    {id:"323001",name:"Nyrupskolen"},
    {id:"323002",name:"Raklev Skole"},
    {id:"323003",name:"Rynkevangskolen"},
    {id:"323007",name:"Tømmerup Skole"},
    {id:"323008",name:"Årby Skole"},
    {id:"323010",name:"Tømmerup Fri- og Efterskole"},
    {id:"323013",name:"Kalundborg Friskole"},
    {id:"323016",name:"Skolen på Herredsåsen"},
    {id:"325001",name:"Broskolen"},
    {id:"325003",name:"Baggesenskolen"},
    {id:"325005",name:"Tårnborg Skole"},
    {id:"325006",name:"Vemmelev Skole"},
    {id:"325007",name:"Forlev Friskole"},
    {id:"325008",name:"Helms Skole"},
    {id:"327002",name:"Rørvig Friskole"},
    {id:"329002",name:"Byskovskolen, afd. Benløse"},
    {id:"329005",name:"Kildeskolen"},
    {id:"329008",name:"Nordbakkeskolen"},
    {id:"329009",name:"Søholmskolen"},
    {id:"329012",name:"Vigersted Skole"},
    {id:"329013",name:"Dagmarskolen"},
    {id:"329015",name:"Valdemarskolen"},
    {id:"329017",name:"Sct. Joseph Skole"},
    {id:"329018",name:"Ringsted Privatskole"},
    {id:"329019",name:"Byskovskolen, afd. Asgård"},
    {id:"329022",name:"Ringsted Ny Friskole"},
    {id:"331002",name:"Boeslunde Skole"},
    {id:"331003",name:"Eggeslevmagle Skole"},
    {id:"331004",name:"Kirkeskovsskolen"},
    {id:"331006",name:"Omø Skole"},
    {id:"331007",name:"Skælskør Skole"},
    {id:"331008",name:"Lille Egede Friskole"},
    {id:"333004",name:"Marievangsskolen"},
    {id:"333005",name:"Nymarkskolen"},
    {id:"333008",name:"Stillinge Skole"},
    {id:"333011",name:"Søndermarksskolen"},
    {id:"333012",name:"Landsgrav Friskole"},
    {id:"333014",name:"Dyhrs Skole"},
    {id:"333015",name:"Antvorskov Skole"},
    {id:"333021",name:"Xclass. 10. Klasse-centret i Slagelse"},
    {id:"333023",name:"Trelleborg Friskole"},
    {id:"335002",name:"Frederiksberg Skole"},
    {id:"335003",name:"Pedersborg Skole"},
    {id:"335005",name:"Sorø Borgerskole"},
    {id:"335006",name:"Sorø Privatskole"},
    {id:"337004",name:"Stenlille Skole"},
    {id:"339004",name:"Katrinedalskolen, afdeling Svinninge"},
    {id:"339005",name:"Katrinedalskolen, afdeling Gislinge"},
    {id:"341002",name:"Skovvejens Skole, afdeling Jyderup"},
    {id:"341003",name:"Skovvejens Skole, afdeling Kildebjerg"},
    {id:"341004",name:"Skovvejens Skole, afdeling Knabstrup"},
    {id:"343001",name:"Egebjerg Skole"},
    {id:"343002",name:"Højby Skole"},
    {id:"343005",name:"Odden Skole"},
    {id:"343007",name:"Vig Skole"},
    {id:"345002",name:"Kildedamsskolen, afdeling Stestrup"},
    {id:"345003",name:"Kildedamsskolen, afdeling Store Merløse"},
    {id:"345004",name:"Kildedamsskolen, afdeling Tølløse"},
    {id:"345005",name:"Kildedamsskolen, afdeling Ugerløse"},
    {id:"345006",name:"Tølløse Privat- og Efterskole, Baptisternes Skoler"},
    {id:"345007",name:"Sejergaardsskolen Grundskole og efterskole"},
    {id:"345009",name:"Freja Skolen - Rudolf Steiner Skolen på Midtsjælland"},
    {id:"351002",name:"Østskolen, afd. Hylleholt"},
    {id:"351003",name:"Østskolen, afd. Karise"},
    {id:"351008",name:"Østskolen, afd. Rollo"},
    {id:"353003",name:"Fladsåskolen, afd. Korskilde"},
    {id:"355003",name:"Holeby Landsbyordning"},
    {id:"357001",name:"Holmegaardskolen, afd. Toksværd"},
    {id:"357002",name:"Holmegaardskolen, afd. Fensmark"},
    {id:"359003",name:"Søllested Landsbyordning"},
    {id:"359006",name:"Reventlow Lilleskole"},
    {id:"361003",name:"Kulsbjerg Skole, Stensved"},
    {id:"361005",name:"Friskolen Øster Egesborg"},
    {id:"363008",name:"Østofte Landsbyordning"},
    {id:"363009",name:"Sankt Birgitta Skole"},
    {id:"365002",name:"Møn Skole, Fanefjord"},
    {id:"365004",name:"Møn Skole, Hjertebjerg"},
    {id:"365008",name:"Bogø Kostskole"},
    {id:"365011",name:"Møn Skole, Stege"},
    {id:"365012",name:"Møn Friskole"},
    {id:"367001",name:"Byskolen"},
    {id:"367005",name:"Stormarkskolen"},
    {id:"367006",name:"Privatskolen Nakskov"},
    {id:"367007",name:"Stenoskolen"},
    {id:"369002",name:"Sophieskolen"},
    {id:"369005",name:"SUNDskolen"},
    {id:"369008",name:"Østre Skole"},
    {id:"369009",name:"Lindeskovskolen"},
    {id:"369010",name:"Nykøbing F Realskole"},
    {id:"369011",name:"Sct. Joseph Søstrenes Skole"},
    {id:"369018",name:"Nordbyskolen"},
    {id:"370001",name:"Appenæs Friskole"},
    {id:"370002",name:"Kobberbakkeskolen, afd. Sydby"},
    {id:"370003",name:"Fladsåskolen, afd. Mogenstrup"},
    {id:"370004",name:"Susåskolen,afd. Herlufmagle"},
    {id:"370005",name:"Susåskolen, afd. Glumsø"},
    {id:"371004",name:"Nysted Skole"},
    {id:"373001",name:"Lille Næstved Skole, afd. Hyllinge"},
    {id:"373003",name:"Lille Næstved Skole, afd. Karrebæk"},
    {id:"373005",name:"Lille Næstved Skole, afd. Herlufsholmsvej"},
    {id:"373011",name:"Kobberbakkeskolen, afd. Rønnebæk"},
    {id:"373012",name:"Kobberbakkeskolen, afd. Sct. Jørgen"},
    {id:"373018",name:"Vor Frue Skole"},
    {id:"373024",name:"Herlufsholm Skole og Gods"},
    {id:"373029",name:"Susåskolen, afd. Holsted"},
    {id:"373030",name:"Næstved Fri Skole"},
    {id:"375001",name:"Nr Alslev Skole"},
    {id:"375003",name:"Nr. Vedby Skole & Børnehus"},
    {id:"375004",name:"Eskilstrup Børne- og Skolefællesskab"},
    {id:"375005",name:"Skovby Friskole"},
    {id:"376001",name:"Ellekildeskolen"},
    {id:"377003",name:"Svend Gønge-Skolen, Bårse"},
    {id:"379004",name:"Horslunde Landsbyordning"},
    {id:"379008",name:"Horslunde Realskole"},
    {id:"381001",name:"Dannelunde skole"},
    {id:"383002",name:"Rødby Skole"},
    {id:"385001",name:"Midtskolen, Bavne afdeling"},
    {id:"385003",name:"Midtskolen, Møllevang afdeling"},
    {id:"385004",name:"Druestrup Friskole"},
    {id:"387005",name:"Sakskøbing Skole"},
    {id:"389008",name:"Store Heddinge Skole"},
    {id:"389009",name:"Stevns Friskole"},
    {id:"391002",name:"Møllebakkeskolen"},
    {id:"391003",name:"Stubbekøbing Skole"},
    {id:"395002",name:"Sydfalster Skole"},
    {id:"397001",name:"Gåsetårnskolen, Kastrup afdeling"},
    {id:"397002",name:"Gåsetårnskolen, Marienberg"},
    {id:"397004",name:"Svend Gønge-Skolen, Lundby"},
    {id:"397006",name:"Kulsbjerg Skole, Nyråd"},
    {id:"397007",name:"Gåsetårnskolen, Ørslev"},
    {id:"397008",name:"Gåsetårnskolen, Iselinge"},
    {id:"397010",name:"Rudolf Steiner skolen i Vordingborg"},
    {id:"397015",name:"Ungdomsskolen og 10.klasse"},
    {id:"400001",name:"Kongeskærskolen"},
    {id:"400006",name:"Friskolen Østerlars"},
    {id:"400009",name:"Svartingedal Skole"},
    {id:"400014",name:"Paradisbakkeskolen"},
    {id:"400018",name:"Søndermarksskolen"},
    {id:"400020",name:"Aavangsskolen"},
    {id:"400021",name:"Rønne Privatskole"},
    {id:"400024",name:"Peterskolen, Rønne Kristne Friskole"},
    {id:"400034",name:"Davidskolen"},
    {id:"400036",name:"10. klasseskolen"},
    {id:"400037",name:"Bornholms Frie Idrætsskole"},
    {id:"400038",name:"Sydbornholms Privatskole"},
    {id:"400042",name:"Hans Rømer Skolen"},
    {id:"411001",name:"Christiansø Skole"},
    {id:"421003",name:"Ebberup Skole"},
    {id:"421005",name:"Salbrovadskolen"},
    {id:"421007",name:"Strandmølleskolen"},
    {id:"421009",name:"Peter Willemoesskolen"},
    {id:"423001",name:"Bogense Skole"},
    {id:"425005",name:"Broby Fri- og Efterskole"},
    {id:"427009",name:"Ollerup Friskole"},
    {id:"427010",name:"Vester Skerninge Friskole"},
    {id:"429001",name:"Anna Trolles Skole"},
    {id:"429002",name:"Ejby Skole"},
    {id:"429003",name:"Fjelsted Harndrup Skole"},
    {id:"429004",name:"Gelsted Skole"},
    {id:"429006",name:"Brenderup og Omegns Realskole"},
    {id:"431009",name:"Bøgebjergskolen"},
    {id:"431011",name:"Enghaveskolen - Faaborg Friskole"},
    {id:"431014",name:"Haastrup Friskole"},
    {id:"433003",name:"Glamsbjergskolen"},
    {id:"433004",name:"Gummerup Skole"},
    {id:"433005",name:"Glamsbjerg Fri-og Efterskole"},
    {id:"433008",name:"Køng Idrætsfriskole"},
    {id:"435006",name:"Oure Friskole"},
    {id:"437002",name:"Hårby Skole"},
    {id:"437004",name:"Friskolen i Jordløse"},
    {id:"439001",name:"Hindsholms børn & unge"},
    {id:"439006",name:"Nymarken Skole og Børnehus"},
    {id:"439007",name:"Kertemindeegnens Friskole"},
    {id:"439008",name:"Rynkeby Friskole"},
    {id:"441002",name:"Langeskov Skole"},
    {id:"441003",name:"Marslev Skole og Børnehus"},
    {id:"443002",name:"Marstal Skole"},
    {id:"445002",name:"Hyllehøjskolen"},
    {id:"445003",name:"Strib Skole"},
    {id:"445004",name:"Vestre Skole"},
    {id:"445005",name:"Østre Skole"},
    {id:"445008",name:"Lillebæltskolen"},
    {id:"445009",name:"Middelfart Friskole"},
    {id:"445010",name:"Fænøsund Friskole"},
    {id:"449002",name:"Birkhovedskolen"},
    {id:"449007",name:"Danehofskolen"},
    {id:"449008",name:"Nyborg Private Realskole"},
    {id:"449009",name:"Nyborg Friskole"},
    {id:"451001",name:"Båring Skole"},
    {id:"451002",name:"Nørre Aaby Skole"},
    {id:"451003",name:"Nørre Aaby Realskole"},
    {id:"461001",name:"Agedrup Skole"},
    {id:"461003",name:"Dalumskolen"},
    {id:"461007",name:"Hjalleseskolen"},
    {id:"461008",name:"Hunderupskolen"},
    {id:"461009",name:"Højby Skole"},
    {id:"461010",name:"Højmeskolen"},
    {id:"461013",name:"Korup Skole"},
    {id:"461014",name:"Ejerslykkeskolen"},
    {id:"461015",name:"Kroggårdsskolen"},
    {id:"461016",name:"Lumby Skole"},
    {id:"461017",name:"Munkebjergskolen"},
    {id:"461018",name:"Næsby Skole"},
    {id:"461019",name:"Provstegårdskolen"},
    {id:"461020",name:"Paarup Skole"},
    {id:"461021",name:"Rasmus Rask-Skolen"},
    {id:"461022",name:"Risingskolen"},
    {id:"461023",name:"Sanderumskolen"},
    {id:"461024",name:"Sct Hans Skole"},
    {id:"461025",name:"Seden Skole"},
    {id:"461026",name:"Skt Klemensskolen"},
    {id:"461028",name:"Spurvelundskolen"},
    {id:"461029",name:"Stige Skole"},
    {id:"461030",name:"Tarup Skole"},
    {id:"461031",name:"Tingkærskolen"},
    {id:"461032",name:"Tingløkkeskolen"},
    {id:"461034",name:"Ubberud Skole"},
    {id:"461035",name:"Vestre Skole"},
    {id:"461037",name:"Åløkkeskolen"},
    {id:"461038",name:"Rosengårdskolen"},
    {id:"461039",name:"Abildgårdskolen"},
    {id:"461040",name:"Giersings Realskole"},
    {id:"461041",name:"Højby Friskole"},
    {id:"461042",name:"Henriette Hørlucks Skole"},
    {id:"461043",name:"Marie Jørgensens Skole"},
    {id:"461044",name:"Odense Friskole"},
    {id:"461045",name:"Sct. Albani Skole"},
    {id:"461046",name:"Stige Friskole"},
    {id:"461047",name:"Ådalskolen"},
    {id:"461053",name:"Kratholmskolen"},
    {id:"461054",name:"Rudolf Steiner-Skolen i Odense"},
    {id:"461060",name:"Søhusskolen"},
    {id:"461064",name:"Holluf Pile Skole"},
    {id:"461068",name:"Lilleskolen Odense"},
    {id:"461070",name:"Al-Salahiyah Skolen"},
    {id:"461075",name:"H. C. Andersen Skolen"},
    {id:"461078",name:"Det Kongelige Teater Balletskolen Odense"},
    {id:"461079",name:"Skibhus Friskole"},
    {id:"471005",name:"Kystskolen"},
    {id:"471008",name:"Sletten Skole"},
    {id:"471011",name:"Otterup Realskole"},
    {id:"473001",name:"Espe Skole"},
    {id:"473009",name:"Tingagerskolen"},
    {id:"473011",name:"Hjemly Fri- og Idrætsefterskole"},
    {id:"473012",name:"Nordskovens Friskole"},
    {id:"473013",name:"Ringe Friskole"},
    {id:"473014",name:"Ringe Kost- og Realskole"},
    {id:"473015",name:"Rudme Friskole"},
    {id:"473020",name:"Nordagerskolen"},
    {id:"473022",name:"Krarup Friskole, Reventlowskolen"},
    {id:"475004",name:"Kassebølle Friskole"},
    {id:"477001",name:"Tre Ege Skolen"},
    {id:"477004",name:"Gislev Friskole"},
    {id:"477005",name:"Ryslinge Friskole"},
    {id:"477006",name:"Trunderup Friskole"},
    {id:"479008",name:"Rantzausminde Skole"},
    {id:"479009",name:"Thurø Skole"},
    {id:"479010",name:"Tved Skole"},
    {id:"479011",name:"Vestre Skole"},
    {id:"479013",name:"Haahrs Skole"},
    {id:"479014",name:"Ida Holsts Skole"},
    {id:"479016",name:"Øster Åby Friskole"},
    {id:"481001",name:"Humble Skole"},
    {id:"482001",name:"Magleby Fri- og Efterskole"},
    {id:"482002",name:"Ørstedskolen"},
    {id:"483002",name:"Havrehedskolen"},
    {id:"483003",name:"Særslev-Hårslev-Skolen"},
    {id:"483004",name:"Søndersøskolen"},
    {id:"485001",name:"Brylle Skole"},
    {id:"485003",name:"Tallerupskolen"},
    {id:"485004",name:"Tommerup Skole"},
    {id:"485005",name:"Verninge Skole"},
    {id:"487002",name:"Nordskolen"},
    {id:"489002",name:"Vibeskolen"},
    {id:"491005",name:"Vissenbjerg Skole"},
    {id:"492001",name:"Ærø Friskole"},
    {id:"495005",name:"4kløverskolen"},
    {id:"495006",name:"Refsvindinge Friskole"},
    {id:"495007",name:"Svindinge Friskole"},
    {id:"497001",name:"Broskolen"},
    {id:"497002",name:"Carl Nielsen-Skolen"},
    {id:"497005",name:"Ferritslev Friskole"},
    {id:"497006",name:"Nr Lyndelse Friskole"},
    {id:"497007",name:"Sdr. Nærå Friskole og Midtfyns Efterskole"},
    {id:"499004",name:"Aarupskolen"},
    {id:"499005",name:"Frøbjerg-Orte Friskole"},
    {id:"501001",name:"Augustenborg Skole"},
    {id:"501002",name:"Fynshav Børneunivers"},
    {id:"501003",name:"Midtals Friskole"},
    {id:"503004",name:"Kollund Skole"},
    {id:"503006",name:"Deutsche Schule Pattburg"},
    {id:"505002",name:"Bredebro Filialskole"},
    {id:"507001",name:"Broager Skole"},
    {id:"509009",name:"Sjølund-Hejls Skole"},
    {id:"509011",name:"Aller Friskole"},
    {id:"509013",name:"Christiansfeld Skole"},
    {id:"510006",name:"Simmersted Friskole"},
    {id:"511003",name:"Gram Skole"},
    {id:"511005",name:"Fole Friskole"},
    {id:"513002",name:"Gråsten Skole"},
    {id:"513004",name:"Rinkenæs Skole"},
    {id:"513005",name:"Førde-Schule Gravenstein"},
    {id:"513008",name:"Æblegård Friskole"},
    {id:"515002",name:"Erlev Skole"},
    {id:"515004",name:"Kløvermarken Skole"},
    {id:"515014",name:"Haderslev Realskole"},
    {id:"515015",name:"Deutsche Schule Hadersleben"},
    {id:"515018",name:"Haderslev Kristne Friskole"},
    {id:"515021",name:"10. ved Kløften"},
    {id:"517001",name:"Tønder Distriktsskole - Digeskolen"},
    {id:"519002",name:"Felsted Centralskole"},
    {id:"519003",name:"Kliplev Skole"},
    {id:"519005",name:"Varnæs Skole"},
    {id:"519006",name:"Deutsche Privatschule Feldstedt"},
    {id:"519008",name:"Søgård Friskole"},
    {id:"521003",name:"Distriktsskolen"},
    {id:"521005",name:"Øster Højst Filialskole"},
    {id:"521006",name:"Deutsche Schule Lügumkloster"},
    {id:"521011",name:"LøgumklosterFriskole"},
    {id:"523001",name:"Nørreskov-Skolen"},
    {id:"523010",name:"Kindercampus Lunden  Deutsche Schule"},
    {id:"523012",name:"Oksbøl Friskole"},
    {id:"523013",name:"FriskolenØsterlund"},
    {id:"525001",name:"Agerskov Skole"},
    {id:"525007",name:"Agerskov Kristne Friskole"},
    {id:"525010",name:"Kostskolen i Sønderjylland"},
    {id:"525011",name:"Arrild Friskole og Børnehus"},
    {id:"527002",name:"Jels Skole"},
    {id:"527004",name:"Rødding Skole"},
    {id:"527005",name:"Skodborg Skole"},
    {id:"527008",name:"Rødding Friskole"},
    {id:"527009",name:"Brændstrup Kristne Friskole"},
    {id:"529001",name:"Genner Univers"},
    {id:"529002",name:"Hellevad Børneunivers"},
    {id:"529003",name:"Hjordkær Skole"},
    {id:"529004",name:"Hovslund Børneunivers"},
    {id:"529006",name:"Deutsche Schule Rothenkrug"},
    {id:"531005",name:"Skærbæk Distriktsskole"},
    {id:"531007",name:"Skærbæk Realskole"},
    {id:"533003",name:"Nybøl Børneunivers"},
    {id:"533004",name:"Nydamskolen"},
    {id:"533005",name:"Eckersberg Friskole"},
    {id:"535001",name:"Hørup Centralskole"},
    {id:"535003",name:"Lysabild Børneunivers"},
    {id:"535006",name:"Kegnæs Friskole"},
    {id:"535007",name:"Tandslet Friskole"},
    {id:"537001",name:"Ahlmann-Skolen"},
    {id:"537004",name:"Sønderskov-Skolen"},
    {id:"537006",name:"Ulkebøl Skole"},
    {id:"537007",name:"Dybbøl-Skolen"},
    {id:"537008",name:"Humlehøj-Skolen"},
    {id:"537009",name:"Deutsche Schule Sonderburg"},
    {id:"537015",name:"Privatskolen Als"},
    {id:"537210",name:"Ungecentret - 10. klasse"},
    {id:"539001",name:"Bolderslev Skole"},
    {id:"539003",name:"Bylderup Skole"},
    {id:"539004",name:"Ravsted Børneunivers"},
    {id:"539006",name:"Tinglev Skole"},
    {id:"539009",name:"Deutsche Schule Buhrkall"},
    {id:"539010",name:"Deutsche Schule Rapstedt"},
    {id:"539012",name:"Deutsche Schule Tingleff"},
    {id:"539014",name:"Friskolen - Bylderup Bov"},
    {id:"541003",name:"Tønder Distriktsskole - Møgeltønder Skole"},
    {id:"541004",name:"Tønder Distriktsskole - Overbygningsskolen"},
    {id:"541006",name:"Ludwig-Andresen-Schule"},
    {id:"541007",name:"Tønder Distriktsskole - Grundskolen"},
    {id:"541010",name:"Tønder10"},
    {id:"543001",name:"Bregnbjergskolen"},
    {id:"543011",name:"Ungeuniverset-Vojens"},
    {id:"545002",name:"Løjt Kirkeby Skole"},
    {id:"545005",name:"Stubbæk Skole"},
    {id:"545006",name:"Deutsche Privatschule Apenrade"},
    {id:"545009",name:"Høje Kolstrup Skole"},
    {id:"545011",name:"Aabenraa Friskole"},
    {id:"545016",name:"10. klasse Aabenraa"},
    {id:"550001",name:"Branderup Friskole"},
    {id:"550002",name:"Toftlund Skole"},
    {id:"551003",name:"Vorbasse Skole"},
    {id:"553004",name:"Blåbjergskolen, Lunde-Kvong afdeling"},
    {id:"553005",name:"Blåbjergskolen, Outrup afd."},
    {id:"553007",name:"Blåbjergskolen, Nr. Nebel afd."},
    {id:"553009",name:"Blåbjerg Friskole"},
    {id:"555001",name:"Blåvandshuk Skole"},
    {id:"557001",name:"Bakkevejens Skole Fortuna"},
    {id:"557002",name:"Darum Børneby"},
    {id:"557003",name:"Gørding Skole Fortuna"},
    {id:"557005",name:"Vejrup Skole Fortuna"},
    {id:"557006",name:"Nordre Skole Fortuna"},
    {id:"557007",name:"Friskolen i Bramming"},
    {id:"559007",name:"Gjerndrup Friskole"},
    {id:"561002",name:"Bakkeskolen Cosmos"},
    {id:"561003",name:"Boldesager Skole Cosmos"},
    {id:"561004",name:"Bryndum Skole Aura"},
    {id:"561005",name:"Danmarksgades Skole Urban"},
    {id:"561006",name:"Fourfeldtskolen Bohr"},
    {id:"561011",name:"Hjerting Skole Aura"},
    {id:"561014",name:"Ådalskolen Bohr"},
    {id:"561015",name:"Præstegårdsskolen Urban"},
    {id:"561016",name:"Rørkjær Skole Urban"},
    {id:"561018",name:"Skads Skole Signatur"},
    {id:"561019",name:"Spangsbjergskolen Cosmos"},
    {id:"561022",name:"Tjæreborg Skole Signatur"},
    {id:"561024",name:"Kvaglundskolen Signatur"},
    {id:"561026",name:"Esbjerg Realskole"},
    {id:"561030",name:"Skt. Nikolaj Skole"},
    {id:"561036",name:"Markusskolen"},
    {id:"561039",name:"Sønderrisskolen Aura"},
    {id:"563001",name:"Fanø Skole"},
    {id:"565003",name:"Lynghedeskolen, afdeling Søndre"},
    {id:"565004",name:"Hejnsvig Skole"},
    {id:"565006",name:"Lynghedeskolen, afdeling Stenderup"},
    {id:"565007",name:"Sdr Omme Skole"},
    {id:"565009",name:"Grindsted Vestre Skole"},
    {id:"565010",name:"Grindsted Privatskole"},
    {id:"567001",name:"Agerbæk Skole"},
    {id:"567003",name:"Næsbjerg afdeling"},
    {id:"567004",name:"Egekratskolen Fortuna"},
    {id:"567005",name:"Starup Skole"},
    {id:"567006",name:"Nordenskov afdeling"},
    {id:"567007",name:"Årre Skole"},
    {id:"569001",name:"Føvling Skole"},
    {id:"569004",name:"Højmarkskolen"},
    {id:"569007",name:"Glejbjerg Børne- og Skolecenter"},
    {id:"571002",name:"Gredstedbro Skole Vadehav"},
    {id:"571015",name:"Egebæk-Hviding Skole Vadehav"},
    {id:"571017",name:"Vittenbergskolen Vadehav"},
    {id:"571022",name:"Riberhus Privatskole"},
    {id:"573001",name:"Alslev Skole"},
    {id:"573003",name:"Brorsonskolen"},
    {id:"573004",name:"Horne Skole"},
    {id:"573005",name:"Janderup Skole"},
    {id:"573006",name:"Lykkesgårdskolen"},
    {id:"573008",name:"Sct. Jacobi Skole"},
    {id:"573009",name:"Thorstrup Skole"},
    {id:"573012",name:"Mejls-Orten-Tinghøj Friskole"},
    {id:"575001",name:"Andst Børne- og Skolecenter"},
    {id:"575002",name:"Askov-Malt Skole"},
    {id:"575003",name:"Grønvangskolen"},
    {id:"575004",name:"Bække Skole"},
    {id:"575005",name:"Gesten Børnecenter"},
    {id:"575011",name:"Østerbyskolen"},
    {id:"575013",name:"Vejen friskole"},
    {id:"575014",name:"Friskolen Helios"},
    {id:"577001",name:"Ansager Skole"},
    {id:"577007",name:"Tistrup Skole"},
    {id:"577008",name:"Ølgod Skole"},
    {id:"601001",name:"Brædstrup Skole"},
    {id:"601004",name:"Nim Skole og Børnehus"},
    {id:"601007",name:"Voerladegård Skole"},
    {id:"601008",name:"Tønning-Træden Friskole"},
    {id:"601009",name:"Bakkelandets Friskole"},
    {id:"603002",name:"Gårslev Skole"},
    {id:"603004",name:"Smidstrup-Skærup Skole"},
    {id:"603005",name:"Englystskolen"},
    {id:"605002",name:"Egtved Skole"},
    {id:"605005",name:"Vester Nebel Skole"},
    {id:"605007",name:"Ødsted Skole"},
    {id:"605008",name:"Øster Starup Skole"},
    {id:"605009",name:"Balle Friskole, Balle Musik- & Idrætsefterskole"},
    {id:"607001",name:"Ullerup Bæk Skolen, Skolesvinget"},
    {id:"607002",name:"Kirstinebjergskolen, Høgevej"},
    {id:"607003",name:"Erritsø Fællesskole, Krogagervej"},
    {id:"607006",name:"Kirstinebjergskolen, Indre Ringvej"},
    {id:"607007",name:"Ullerup Bæk Skolen, Skjoldborgsvej"},
    {id:"607008",name:"Kirstinebjergskolen, Havepladsvej"},
    {id:"607009",name:"Fjordbakkeskolen, Skærbæk"},
    {id:"607010",name:"Fjordbakkeskolen, afdeling Taulov"},
    {id:"607011",name:"Ullerup Bæk Skolen, Nr. Alle"},
    {id:"607012",name:"Erritsø Fællesskole, Erritsø Bygade"},
    {id:"607013",name:"Kirstinebjergskolen, Bøgeskovvej"},
    {id:"607015",name:"Fredericia Realskole"},
    {id:"607018",name:"Sct. Knuds Skole"},
    {id:"607021",name:"Erritsø Fællesskole, Afd. Højmosen"},
    {id:"607022",name:"Fredericia Friskole"},
    {id:"607030",name:"UngFredericia"},
    {id:"609001",name:"Hovedgård Skole, folkeskoleafdeling"},
    {id:"609002",name:"Søvind Skole"},
    {id:"609003",name:"Østbirk Skole"},
    {id:"609004",name:"Gedved Skole"},
    {id:"609005",name:"Vestbirk Friskole"},
    {id:"611008",name:"Thyregod Skole"},
    {id:"611010",name:"Øster Nykirke Skole"},
    {id:"611011",name:"Hedegaard Friskole"},
    {id:"611013",name:"Karlskov Friskole"},
    {id:"613002",name:"Hedensted Skole"},
    {id:"613003",name:"Korning Skole"},
    {id:"613005",name:"Løsning Skole"},
    {id:"613006",name:"Ølsted Skole"},
    {id:"613007",name:"Daugård Skole"},
    {id:"613008",name:"Bøgballe Friskole"},
    {id:"613009",name:"Vejlefjordskolen (grundskole)"},
    {id:"613012",name:"Stjernevejskolen"},
    {id:"613013",name:"Øster Snede Skole"},
    {id:"615002",name:"Dagnæsskolen"},
    {id:"615003",name:"Egebjergskolen"},
    {id:"615007",name:"Langmarkskolen"},
    {id:"615011",name:"Stensballeskolen"},
    {id:"615012",name:"Lundskolen"},
    {id:"615014",name:"Østerhåbskolen"},
    {id:"615015",name:"Bankagerskolen"},
    {id:"615016",name:"Hulvej Privatskole"},
    {id:"615017",name:"Sct Ibs Skole"},
    {id:"615020",name:"Højvangskolen, folkeskoleafdeling"},
    {id:"615024",name:"Horsens Byskole, folkeskoleafdeling"},
    {id:"615029",name:"Learnmark Horsens, Step 10"},
    {id:"615032",name:"Højvangskolen"},
    {id:"617001",name:"Kollerup Skole"},
    {id:"617002",name:"Bredagerskolen"},
    {id:"619002",name:"Barrit Skole"},
    {id:"619004",name:"Glud Skole"},
    {id:"619005",name:"Hornsyld Skole"},
    {id:"619007",name:"Juelsminde Skole"},
    {id:"619010",name:"Raarup Skole"},
    {id:"619011",name:"Stouby Skole"},
    {id:"619012",name:"Stenderup Skole"},
    {id:"619017",name:"As Friskole & Naturbørnehave"},
    {id:"621001",name:"Alminde-Viuf Fællesskole"},
    {id:"621002",name:"Bramdrup Skole"},
    {id:"621003",name:"Brændkjærskolen"},
    {id:"621004",name:"Dalby Skole"},
    {id:"621006",name:"Eltang Skole og Børnehave"},
    {id:"621007",name:"Harte Skole"},
    {id:"621011",name:"Sdr Vang Skole"},
    {id:"621012",name:"Bakkeskolen"},
    {id:"621013",name:"Lyshøjskolen"},
    {id:"621014",name:"Sdr.Bjert Centralskole"},
    {id:"621016",name:"Vonsild Skole"},
    {id:"621017",name:"Aalykkeskolen"},
    {id:"621018",name:"Munkevængets Skole"},
    {id:"621019",name:"Kolding Realskole"},
    {id:"621020",name:"Sct. Michaels Skole"},
    {id:"621024",name:"Kolding Friskole"},
    {id:"621025",name:"Lykkegårdskolen"},
    {id:"623001",name:"Fynslundskolen"},
    {id:"623002",name:"Kongsbjergskolen"},
    {id:"625001",name:"Ejstrupholm Skole"},
    {id:"625004",name:"Nørre-Snede Skole"},
    {id:"625005",name:"Friskolen i Thorlund"},
    {id:"625006",name:"Gludsted Friskole"},
    {id:"625007",name:"Klovborg Friskole"},
    {id:"627001",name:"Lindved Skole"},
    {id:"627002",name:"Ølholm Skole og børnehave"},
    {id:"627003",name:"Rask Mølle Skole"},
    {id:"627004",name:"Tørring Skole"},
    {id:"627005",name:"Uldum Skole"},
    {id:"627006",name:"Aale Hjortsvang Skole"},
    {id:"627008",name:"Grejs Friskole"},
    {id:"629001",name:"Vamdrup Skole"},
    {id:"629002",name:"Ødis Skole"},
    {id:"629004",name:"Skanderup-Hjarup Forbundsskole"},
    {id:"631002",name:"Engum Skole"},
    {id:"631003",name:"Grejsdal Skole"},
    {id:"631005",name:"Højen Skole"},
    {id:"631006",name:"Kirkebakkeskolen"},
    {id:"631009",name:"Mølholm Skole"},
    {id:"631011",name:"NOVAskolen"},
    {id:"631012",name:"Petersmindeskolen"},
    {id:"631013",name:"Skibet Skole"},
    {id:"631014",name:"Søndermarksskolen"},
    {id:"631015",name:"Vinding Skole"},
    {id:"631017",name:"Hældagerskolen"},
    {id:"631018",name:"Kirstine Seligmanns Skole"},
    {id:"631019",name:"Sct. Norberts Skole"},
    {id:"631023",name:"Vejle Friskole"},
    {id:"631024",name:"Steiner Skolen i Vejle"},
    {id:"631029",name:"Lukas-Skolen"},
    {id:"631034",name:"10. Klasse UngdomsCenter Vejle"},
    {id:"651003",name:"Haderup Skole"},
    {id:"651005",name:"Aulum Kristne Friskole"},
    {id:"653001",name:"Blåhøj Skole"},
    {id:"653002",name:"Dalgasskolen"},
    {id:"653003",name:"Præstelundskolen"},
    {id:"653006",name:"Uhre Friskole"},
    {id:"655005",name:"Bork Skole"},
    {id:"655008",name:"Tarm Skole"},
    {id:"655009",name:"Ådum Børneunivers"},
    {id:"655013",name:"Lyne Friskole"},
    {id:"657004",name:"Gullestrup Skole"},
    {id:"657007",name:"Herningsholmskolen"},
    {id:"657010",name:"Lind Skole"},
    {id:"657013",name:"Sinding-Ørre Midtpunkt, Skolen"},
    {id:"657014",name:"Snejbjerg Skole"},
    {id:"657017",name:"Skolen på Sønderager"},
    {id:"657018",name:"Tjørring Skole"},
    {id:"657019",name:"Vestervangskolen"},
    {id:"657022",name:"Lundgårdskolen"},
    {id:"657023",name:"Brændgårdskolen"},
    {id:"657024",name:"Hammerum Friskole"},
    {id:"657025",name:"Herning Friskole"},
    {id:"657027",name:"Midtjyllands Kristne Friskole"},
    {id:"657031",name:"Engbjergskolen"},
    {id:"657032",name:"Højgårdskolen"},
    {id:"657035",name:"Skalmejeskolen"},
    {id:"657036",name:"Parkskolen"},
    {id:"657037",name:"Børneskolen Bifrost"},
    {id:"657042",name:"Studsgård Friskole"},
    {id:"659001",name:"Holmsland Skole"},
    {id:"659002",name:"Hvide Sande Skole"},
    {id:"661001",name:"Borbjerg Skole"},
    {id:"661002",name:"Rolf Krake Skolen"},
    {id:"661003",name:"Halgård Skole og SFH"},
    {id:"661004",name:"Idom-Råsted Skole"},
    {id:"661005",name:"Mejrup Skole"},
    {id:"661007",name:"Naur-Sir Skole"},
    {id:"661008",name:"Nr Felding skole"},
    {id:"661011",name:"Skave Skole"},
    {id:"661012",name:"Sønderlandsskolen"},
    {id:"661013",name:"Tvis Skole"},
    {id:"661014",name:"Nørre Boulevard Skolen"},
    {id:"661016",name:"Den Kristne Friskole i Holstebro"},
    {id:"661018",name:"Holstebro Friskole"},
    {id:"661020",name:"Balletskolen"},
    {id:"663001",name:"Bording Skole"},
    {id:"663002",name:"Engesvang Skole"},
    {id:"663003",name:"Ikast Østre Skole"},
    {id:"663005",name:"Isenvad Skole"},
    {id:"663006",name:"Ikast Nordre Skole"},
    {id:"663008",name:"Hyldgårdsskolen"},
    {id:"663010",name:"Ikast-Brande Ungdomscenter"},
    {id:"665002",name:"Christinelystskolen"},
    {id:"665003",name:"Ramme Skole"},
    {id:"665005",name:"Tangsø skole"},
    {id:"665007",name:"Alt i et-Skolen, Klinkby"},
    {id:"665012",name:"Nørre Nissum Skole- og Børneunivers"},
    {id:"665015",name:"Bøvling Fri- og Idrætsefterskole"},
    {id:"665016",name:"Fjaltring Friskole"},
    {id:"665020",name:"Lemtorpskolen"},
    {id:"665024",name:"Udefriskolen"},
    {id:"667001",name:"Alkjærskolen"},
    {id:"667002",name:"Hee Skole"},
    {id:"667004",name:"Højmark Skole"},
    {id:"667005",name:"Lem Stationsskole"},
    {id:"667008",name:"Ringkøbing Skole"},
    {id:"667014",name:"Tim Skole"},
    {id:"667018",name:"Velling Friskole"},
    {id:"667019",name:"Ølstrup Friskole"},
    {id:"667021",name:"HoverThorsted Friskole"},
    {id:"669001",name:"Borris Skole"},
    {id:"669003",name:"Kirkeskolen"},
    {id:"669004",name:"Stauning Skole"},
    {id:"669006",name:"Faster Skole"},
    {id:"669007",name:"Rækker Mølle Skolen"},
    {id:"669008",name:"Amagerskolen"},
    {id:"669010",name:"Skjern Kristne Friskole"},
    {id:"671002",name:"Hjerm Skole"},
    {id:"671003",name:"Humlum Skole"},
    {id:"671004",name:"Langhøjskolen"},
    {id:"671005",name:"Bremdal Skole"},
    {id:"671009",name:"Parkskolen"},
    {id:"671011",name:"Struer Friskole"},
    {id:"671013",name:"Resen Friskole"},
    {id:"673001",name:"Harboøre Skole og Børnecenter"},
    {id:"673002",name:"Thyborøn Skole"},
    {id:"675001",name:"Thyholm Skole"},
    {id:"677002",name:"Timring læringscenter"},
    {id:"677003",name:"Vildbjerg Skole"},
    {id:"677004",name:"Vinding Skole"},
    {id:"677006",name:"Ørnhøj Skole"},
    {id:"677008",name:"Kildebakkeskolen"},
    {id:"679004",name:"Staby Skole"},
    {id:"679006",name:"Ulfborg Skole"},
    {id:"679008",name:"Vemb Skole"},
    {id:"681001",name:"Spjald Skole"},
    {id:"681002",name:"Fjelstervang Skole"},
    {id:"681006",name:"Nr. Vium-Troldhede Skole"},
    {id:"681007",name:"Videbæk Skole"},
    {id:"681008",name:"Vorgod-Barde Skole"},
    {id:"681009",name:"Videbæk Kristne Friskole"},
    {id:"683005",name:"Sevel Skole"},
    {id:"683006",name:"Vinderup Skole"},
    {id:"683007",name:"Vinderup Realskole"},
    {id:"685001",name:"Kibæk Skole"},
    {id:"685002",name:"Sdr Felding Skole"},
    {id:"685003",name:"Skarrild Skole"},
    {id:"701001",name:"Ebeltoft Skole"},
    {id:"701002",name:"Rosmus Skole"},
    {id:"701003",name:"Molsskolen"},
    {id:"701006",name:"Syddjurs Friskole"},
    {id:"703001",name:"Gyvelhøjskolen"},
    {id:"703003",name:"Herskindskolen"},
    {id:"703004",name:"Skovbyskolen"},
    {id:"703006",name:"Stjærskolen"},
    {id:"703007",name:"Den Fri Hestehaveskole"},
    {id:"705001",name:"Gjern Skole"},
    {id:"705002",name:"Grauballe Skole"},
    {id:"705003",name:"Fårvang Skole"},
    {id:"705004",name:"Sorring Skole"},
    {id:"705005",name:"Voel Skole"},
    {id:"705006",name:"Klippen, Den Kristne Friskole"},
    {id:"707001",name:"Anholt Skole"},
    {id:"707009",name:"Norddjurs Friskole"},
    {id:"707011",name:"10. Klasse Center Djursland"},
    {id:"707012",name:"Skolen.Com"},
    {id:"709001",name:"Hadsten Skole"},
    {id:"709003",name:"Hadbjerg Skole"},
    {id:"709004",name:"Østervangskolen"},
    {id:"709005",name:"Bavnehøjskolen"},
    {id:"710003",name:"Frijsendal Friskole"},
    {id:"711001",name:"Skovvangskolen"},
    {id:"711004",name:"Søndervangskolen"},
    {id:"711005",name:"Regnbueskolen"},
    {id:"713001",name:"Haldum-Hinnerup Skolen"},
    {id:"713002",name:"Korsholm Skole"},
    {id:"713003",name:"Rønbækskolen"},
    {id:"713004",name:"Præstemarkskolen"},
    {id:"713005",name:"Friskolen i Hinnerup"},
    {id:"715001",name:"Bakkeskolen"},
    {id:"715002",name:"Veng Skole"},
    {id:"715003",name:"Højboskolen"},
    {id:"717002",name:"Langå Skole"},
    {id:"717003",name:"Lilleåskolen"},
    {id:"717004",name:"Munkholmskolen"},
    {id:"719001",name:"Assens Skole"},
    {id:"719002",name:"Havndal Skole"},
    {id:"719003",name:"Mariager Skole"},
    {id:"719004",name:"Vindblæs Friskole"},
    {id:"721002",name:"Kolind Centralskole"},
    {id:"721004",name:"Marienhoffskolen"},
    {id:"721005",name:"Ryomgaard Realskole"},
    {id:"723001",name:"Grønhøjskolen"},
    {id:"723004",name:"Korshøjskolen"},
    {id:"723005",name:"Mellerup Fri- og Efterskole"},
    {id:"725010",name:"Gjerrild Bønnerup Friskole"},
    {id:"727001",name:"Gylling Skole & Børnehus"},
    {id:"727002",name:"Hou Skole og Børnehus"},
    {id:"727003",name:"Hundslund Skole og Børnehus"},
    {id:"727004",name:"Parkvejens Skole"},
    {id:"727006",name:"Saksild Skole og Børnehus"},
    {id:"727007",name:"Vestskolen"},
    {id:"727011",name:"Rathlouskolen"},
    {id:"727013",name:"Odder Lille Friskole"},
    {id:"729001",name:"Asferg Skole"},
    {id:"729002",name:"Blicherskolen"},
    {id:"729003",name:"Fårup Skole"},
    {id:"729005",name:"Bjerregrav Skole"},
    {id:"729006",name:"Fussingø-egnens Friskole"},
    {id:"731002",name:"Hobrovejens Skole"},
    {id:"731003",name:"Hornbæk Skole"},
    {id:"731004",name:"Kristrup Skole"},
    {id:"731006",name:"Rismølleskolen"},
    {id:"731008",name:"Søndermarkskolen"},
    {id:"731010",name:"Tirsdalens Skole"},
    {id:"731011",name:"Vestervangsskolen"},
    {id:"731013",name:"Østervangsskolen"},
    {id:"731014",name:"Nørrevangsskolen"},
    {id:"731015",name:"C. la Cours Skole"},
    {id:"731016",name:"Forberedelsesskolen"},
    {id:"731017",name:"Randers Realskole"},
    {id:"731019",name:"Randers Lille Skole"},
    {id:"731027",name:"Randers Kristne Friskole"},
    {id:"733001",name:"Hornslet Skole"},
    {id:"733002",name:"Mørke Skole"},
    {id:"733003",name:"Ådalsskolen"},
    {id:"737001",name:"Knudsøskolen"},
    {id:"737002",name:"Bjedstrup Skole"},
    {id:"737003",name:"Låsby Skole"},
    {id:"737004",name:"Mølleskolen"},
    {id:"737005",name:"Gl Rye Skole"},
    {id:"737006",name:"Gudenåskolen"},
    {id:"739002",name:"Rønde Skole"},
    {id:"739003",name:"Thorsager Skole"},
    {id:"739006",name:"Rønde Privatskole"},
    {id:"740005",name:"Sølystskolen"},
    {id:"741004",name:"Samsø Skole"},
    {id:"741007",name:"Samsø Frie Skole"},
    {id:"743002",name:"Dybkærskolen"},
    {id:"743008",name:"Resenbro Skole"},
    {id:"743010",name:"Sejs Skole"},
    {id:"743011",name:"Skægkærskolen"},
    {id:"743012",name:"Vestre Skole"},
    {id:"743013",name:"Virklund Skole"},
    {id:"743014",name:"Balleskolen"},
    {id:"743015",name:"Kornmod Realskole"},
    {id:"743016",name:"Langsøskolen"},
    {id:"743017",name:"Th. Langs Skole"},
    {id:"743020",name:"Hvinningdalskolen"},
    {id:"743021",name:"Gødvadskolen"},
    {id:"743026",name:"Rudolf Steiner skolen i Silkeborg"},
    {id:"743027",name:"Buskelundskolen"},
    {id:"743031",name:"Friskolen i Lemming"},
    {id:"745001",name:"Ejer Bavnehøjskolen"},
    {id:"745002",name:"Morten Børup Skolen"},
    {id:"745003",name:"Stilling Skole"},
    {id:"745004",name:"Virring Skole"},
    {id:"745005",name:"Niels Ebbesen Skolen"},
    {id:"745006",name:"Skanderborg Realskole"},
    {id:"745008",name:"Rudolf Steiner Skolen, Skanderborg"},
    {id:"747001",name:"Auning Skole"},
    {id:"747002",name:"Assentoftskolen"},
    {id:"749001",name:"Bryrup Skole"},
    {id:"749002",name:"Gjessø Skole"},
    {id:"749003",name:"Frisholm Skole"},
    {id:"751002",name:"Bakkegårdsskolen"},
    {id:"751003",name:"Ellevangskolen"},
    {id:"751004",name:"Beder Skole"},
    {id:"751006",name:"Elev Skole"},
    {id:"751007",name:"Elsted Skole"},
    {id:"751008",name:"Engdalskolen"},
    {id:"751010",name:"Tovshøjskolen"},
    {id:"751013",name:"Gammelgaardsskolen"},
    {id:"751014",name:"Sødalskolen"},
    {id:"751015",name:"Hasle Skole"},
    {id:"751016",name:"Bavnehøj Skole"},
    {id:"751017",name:"Holme Skole"},
    {id:"751018",name:"Højvangskolen"},
    {id:"751019",name:"Katrinebjergskolen"},
    {id:"751021",name:"Kragelundskolen"},
    {id:"751022",name:"Lisbjergskolen"},
    {id:"751023",name:"Læssøesgades Skole"},
    {id:"751024",name:"Malling Skole"},
    {id:"751025",name:"Møllevangskolen"},
    {id:"751026",name:"Mårslet Skole"},
    {id:"751027",name:"Frederiksbjerg Skole"},
    {id:"751032",name:"Risskov Skole"},
    {id:"751033",name:"Rosenvangskolen"},
    {id:"751034",name:"Rundhøjskolen"},
    {id:"751035",name:"Sabro-Korsvejskolen"},
    {id:"751036",name:"Samsøgades Skole"},
    {id:"751038",name:"Skovvangskolen"},
    {id:"751039",name:"Skødstrup Skole"},
    {id:"751040",name:"Skåde Skole"},
    {id:"751041",name:"Solbjergskolen"},
    {id:"751042",name:"Strandskolen"},
    {id:"751043",name:"Sølystskolen"},
    {id:"751044",name:"Søndervangskolen"},
    {id:"751045",name:"Tilst Skole"},
    {id:"751046",name:"Hårup Skole"},
    {id:"751050",name:"Vestergårdsskolen"},
    {id:"751051",name:"Viby Skole"},
    {id:"751052",name:"Virupskolen"},
    {id:"751053",name:"Vorrevangskolen"},
    {id:"751054",name:"Åby Skole"},
    {id:"751055",name:"Næshøjskolen"},
    {id:"751056",name:"Skæring Skole"},
    {id:"751057",name:"Ellekærskolen"},
    {id:"751058",name:"Elise Smiths Skole"},
    {id:"751059",name:"Forældreskolen i Aarhus"},
    {id:"751060",name:"Laursens Realskole"},
    {id:"751061",name:"N. Kochs Skole"},
    {id:"751062",name:"Rudolf Steiner-Skolen i Århus"},
    {id:"751063",name:"Skt Knuds Skole"},
    {id:"751064",name:"Århus Friskole"},
    {id:"751065",name:"Skjoldhøjskolen"},
    {id:"751066",name:"Lystrup Skole"},
    {id:"751080",name:"Jakobskolen"},
    {id:"751081",name:"Interskolen"},
    {id:"751089",name:"Børnenes Friskole"},
    {id:"751094",name:"Selam Privatskole"},
    {id:"751095",name:"Egebakkeskolen"},
    {id:"751098",name:"Den moderne kulturelle skole"},
    {id:"751111",name:"Højbjerg Privatskole"},
    {id:"751114",name:"Århus Privatskole"},
    {id:"756002",name:"International School Ikast-Brande"},
    {id:"760003",name:"Grønbjerg Friskole"},
    {id:"760004",name:"Hoven Friskole"},
    {id:"760005",name:"Herborg Friskole"},
    {id:"761002",name:"Bøgeskovskolen"},
    {id:"761006",name:"Rødkærsbro skole"},
    {id:"761008",name:"Egeskovskolen"},
    {id:"761011",name:"Gudenådalens Friskole"},
    {id:"761012",name:"Tange Kristne Friskole"},
    {id:"761016",name:"MammenFri"},
    {id:"763001",name:"Mønsted Skole"},
    {id:"763005",name:"Sparkær Skole"},
    {id:"763006",name:"Stoholm Skole"},
    {id:"763008",name:"Vestfjendsskolen"},
    {id:"765001",name:"Hanstholm Skole"},
    {id:"765005",name:"Hanstholm Friskole"},
    {id:"766001",name:"Skolen i Midten"},
    {id:"767001",name:"Tungelundskolen"},
    {id:"767002",name:"Ulstrup Skole"},
    {id:"769001",name:"Frederiks Skole"},
    {id:"769003",name:"Karup Skole"},
    {id:"771001",name:"Ans Skole"},
    {id:"771007",name:"Thorning Skole"},
    {id:"773001",name:"Dueholmskolen"},
    {id:"773006",name:"Øster Jølby Skole"},
    {id:"773010",name:"M.C. Holms Skole"},
    {id:"773018",name:"Sydmors skole og Børnehus"},
    {id:"773021",name:"Bjergby Friskole"},
    {id:"773023",name:"Øster Jølby Friskole"},
    {id:"773028",name:"Sydvestmors Friskole"},
    {id:"773029",name:"Ørding Friskole"},
    {id:"773031",name:"Sundby Friskole"},
    {id:"773032",name:"Lødderup Friskole"},
    {id:"775001",name:"Brattingsborgskolen"},
    {id:"775004",name:"Møldrup Skole"},
    {id:"775006",name:"Skals Skole"},
    {id:"775007",name:"Ulbjerg Skole"},
    {id:"777001",name:"Durup Skole"},
    {id:"777002",name:"Glyngøre Skole"},
    {id:"777004",name:"Roslev Skole"},
    {id:"779001",name:"Brårup Skole"},
    {id:"779003",name:"Hem Skole"},
    {id:"779004",name:"Højslev Skole"},
    {id:"779005",name:"Nr. Søby Skole"},
    {id:"779006",name:"Resen Skole"},
    {id:"779007",name:"Skivehus Skole"},
    {id:"779008",name:"Aakjærskolen"},
    {id:"779009",name:"Ørslevkloster Skole"},
    {id:"779011",name:"Grundskoleafdelingen, Ådalskolen"},
    {id:"779015",name:"Friskolen i Skive"},
    {id:"779017",name:"10. Klasse Center Skive"},
    {id:"781005",name:"Oddense Skole"},
    {id:"781006",name:"Vestsalling Skole og Dagtilbud"},
    {id:"783005",name:"Thise Friskole"},
    {id:"785002",name:"Bedsted Skole"},
    {id:"785006",name:"Hurup Skole"},
    {id:"785007",name:"Koldby Skole"},
    {id:"785009",name:"Vestervig Skole"},
    {id:"785013",name:"Sydthy Friskole"},
    {id:"785015",name:"Campus 10"},
    {id:"785016",name:"Boddum-Ydby Friskole"},
    {id:"787004",name:"Nors Skole"},
    {id:"787005",name:"Sennels Skole"},
    {id:"787006",name:"Sjørring Skole"},
    {id:"787008",name:"Snedsted Skole"},
    {id:"787012",name:"Tilsted Skole"},
    {id:"787013",name:"Tingstrup Skole"},
    {id:"787016",name:"Hannæs-Østerild Skole"},
    {id:"787019",name:"Østre Skole"},
    {id:"787021",name:"Hundborg Friskole"},
    {id:"787022",name:"Thorsted Friskole"},
    {id:"787024",name:"Rolighedsskolen"},
    {id:"787030",name:"Thisted Friskole"},
    {id:"787033",name:"Sønderhå-Hørsted Friskole"},
    {id:"787035",name:"Lerpytter Friskole & Børnehave"},
    {id:"789002",name:"Hammershøj Skole"},
    {id:"789005",name:"Sødalskolen"},
    {id:"789008",name:"Ørum Skole"},
    {id:"789009",name:"Vejrumbro Fri"},
    {id:"791002",name:"Møllehøjskolen"},
    {id:"791003",name:"Hald Ege Skole"},
    {id:"791004",name:"Løgstrup Skole"},
    {id:"791005",name:"Nordre Skole"},
    {id:"791006",name:"Overlund Skole"},
    {id:"791008",name:"Søndre Skole"},
    {id:"791010",name:"Vestre Skole"},
    {id:"791013",name:"Vestervang Skole"},
    {id:"791014",name:"Viborg Private Realskole"},
    {id:"791015",name:"Friskolen i Viborg"},
    {id:"791019",name:"Finderuphøj Skole"},
    {id:"791020",name:"Houlkærskolen"},
    {id:"791027",name:"Langsø Friskole"},
    {id:"791028",name:"Bjerregrav Friskole"},
    {id:"791029",name:"Nørreåskolen"},
    {id:"793002",name:"Gedsted Skole"},
    {id:"793007",name:"Aalestrup Skole"},
    {id:"793008",name:"Ålestrup Realskole"},
    {id:"793009",name:"Fjelsø Friskole"},
    {id:"801001",name:"Arden Skole"},
    {id:"801005",name:"Valsgaard Skole"},
    {id:"801007",name:"Solhverv Privatskole"},
    {id:"803001",name:"Brovst Skole"},
    {id:"803004",name:"Skovsgård Tranum skole"},
    {id:"805006",name:"Skolegades Skole"},
    {id:"805007",name:"Søndergades Skole"},
    {id:"805008",name:"Thise Skole"},
    {id:"805009",name:"Toftegårdsskolen"},
    {id:"805012",name:"Øster Brønderslev Centralskole"},
    {id:"805014",name:"Hedegårdsskolen"},
    {id:"805016",name:"Tolstrup-Stenum Friskole"},
    {id:"805017",name:"Brønderslev Heldagsskole"},
    {id:"807002",name:"Asaa Skole"},
    {id:"807003",name:"Dronninglund Skole"},
    {id:"807004",name:"Flauenskjold Skole"},
    {id:"807006",name:"Klokkerholm Skole"},
    {id:"807007",name:"Hjallerup Skole"},
    {id:"809001",name:"Farsø Skole"},
    {id:"809004",name:"Ullits Skole og Børnehuset Fjorden"},
    {id:"809005",name:"Vester Hornum Skole"},
    {id:"811001",name:"Fjerritslev Skole"},
    {id:"811003",name:"Thorup-Klim Skole"},
    {id:"811005",name:"Trekronerskolen"},
    {id:"811006",name:"Ørebroskolen"},
    {id:"811007",name:"Klim Friskole"},
    {id:"813001",name:"Distrikt Vest, Bangsbostrand Skoleafdeling"},
    {id:"813002",name:"Distrikt Øst, Frydenstrand skoleafdeling"},
    {id:"813006",name:"Distrikt Nord, Strandby skoleafdeling"},
    {id:"813009",name:"Distrikt Vest, Ravnshøj skoleafdeling"},
    {id:"813010",name:"Privatskolen i Frederikshavn"},
    {id:"813014",name:"Distrikt Vest, Gærum skoleafdeling"},
    {id:"815001",name:"Havbakkeskolen"},
    {id:"815002",name:"Hadsund Skole"},
    {id:"817001",name:"Gandrup Skole"},
    {id:"817002",name:"Hals Skole"},
    {id:"817003",name:"Hou Skole"},
    {id:"817004",name:"Ulsted Skole"},
    {id:"817005",name:"Vester Hassing Skole"},
    {id:"819001",name:"Bindslev undervisningssted"},
    {id:"819002",name:"Hirtshals undervisningssted"},
    {id:"819003",name:"Horne undervisningssted"},
    {id:"819004",name:"Tornby undervisningssted"},
    {id:"820001",name:"10. KlasseCentret Vesthimmerland"},
    {id:"821001",name:"Bagterp undervisningssted"},
    {id:"821002",name:"Bjergby undervisningssted"},
    {id:"821003",name:"Højene undervisningssted"},
    {id:"821006",name:"Tårs Skole"},
    {id:"821009",name:"Muldbjerg undervisningssted"},
    {id:"821010",name:"Lundergård undervisningssted"},
    {id:"821011",name:"Hjørring Private Realskole"},
    {id:"821019",name:"Hjørring Ny 10."},
    {id:"823004",name:"Rosendalskolen"},
    {id:"823006",name:"Hobro Søndre Skole og MF10"},
    {id:"823008",name:"Bymarkskolen"},
    {id:"823009",name:"Hobro Friskole"},
    {id:"825001",name:"Læsø Skole og Børnehus"},
    {id:"827002",name:"Løgstør Skole"},
    {id:"827005",name:"Ranum Skole"},
    {id:"827006",name:"Vilsted Friskole"},
    {id:"829002",name:"Løkken undervisningssted"},
    {id:"829005",name:"Vrå undervisningssted"},
    {id:"831001",name:"Bislev Skole"},
    {id:"831002",name:"Farstrup Skole"},
    {id:"831003",name:"Nibe Skole"},
    {id:"831004",name:"Sebber Skole"},
    {id:"831006",name:"Skørbæk-Ejdrup Friskole"},
    {id:"831007",name:"Vokslev Friskole"},
    {id:"833004",name:"Sortebakkeskolen"},
    {id:"835006",name:"Saltum Skole"},
    {id:"837002",name:"Gudumholm Skole"},
    {id:"837003",name:"Mou Skole"},
    {id:"837005",name:"Kongerslev Skole"},
    {id:"837006",name:"Tofthøjskolen"},
    {id:"837007",name:"Egense Friskole"},
    {id:"839004",name:"Lendum undervisningssted"},
    {id:"839006",name:"Sindal undervisningssted"},
    {id:"841003",name:"Distrikt Nord, Aalbæk skoleafdeling"},
    {id:"841005",name:"Brovandeskolen, Den Lille Skole i Skagen"},
    {id:"843004",name:"Skørping Skole"},
    {id:"845003",name:"Bavnebakkeskolen"},
    {id:"845004",name:"Suldrup Skole"},
    {id:"845006",name:"Øster Hornum Børneunivers"},
    {id:"845008",name:"Guldbæk Friskole"},
    {id:"845011",name:"Karensmindeskolen"},
    {id:"845013",name:"Gregers Krabbe Friskolen"},
    {id:"846002",name:"Hvilsom Friskole"},
    {id:"847003",name:"Distrikt Syd, Sæby skoleafdeling"},
    {id:"847005",name:"Distrikt Syd, Stensnæs skoleafdeling"},
    {id:"847007",name:"Distrikt Syd, Torslev skoleafdeling"},
    {id:"847010",name:"Distrikt Syd, Sæbygård skoleafdeling"},
    {id:"847012",name:"Privatskolen i Sæby"},
    {id:"849001",name:"Biersted Skole"},
    {id:"849002",name:"Gjøl Skole"},
    {id:"849003",name:"Nørhalne Skole"},
    {id:"849005",name:"Aabybro Skole"},
    {id:"849006",name:"Vedsted Friskole"},
    {id:"849008",name:"Åbybro Friskole"},
    {id:"851004",name:"Ellidshøj Skole"},
    {id:"851005",name:"Ferslev Skole"},
    {id:"851007",name:"Frejlev Skole"},
    {id:"851009",name:"Gistrup Skole"},
    {id:"851010",name:"Gl Hasseris Skole"},
    {id:"851011",name:"Gl Lindholm Skole"},
    {id:"851014",name:"Grindsted Skole"},
    {id:"851015",name:"Gug Skole"},
    {id:"851017",name:"Langholt Skole"},
    {id:"851019",name:"Kærbyskolen"},
    {id:"851020",name:"Nr Uttrup Skole"},
    {id:"851021",name:"Nørholm Skole"},
    {id:"851022",name:"Nøvling Skole"},
    {id:"851024",name:"Klarup Skole"},
    {id:"851026",name:"Skansevejens Skole"},
    {id:"851027",name:"Sofiendalskolen"},
    {id:"851028",name:"Stolpedalsskolen"},
    {id:"851029",name:"Sulsted Skole"},
    {id:"851030",name:"Svenstrup Skole"},
    {id:"851031",name:"Sønderbroskolen"},
    {id:"851032",name:"Sønderholm Skole"},
    {id:"851034",name:"Tylstrup Skole"},
    {id:"851036",name:"Vadum Skole"},
    {id:"851037",name:"Vejgaard Østre Skole"},
    {id:"851039",name:"Vestbjerg Skole"},
    {id:"851040",name:"Vester Mariendal Skole"},
    {id:"851041",name:"Vesterkærets Skole"},
    {id:"851042",name:"Vodskov Skole"},
    {id:"851044",name:"Mellervangskolen"},
    {id:"851048",name:"Tornhøjskolen"},
    {id:"851049",name:"Filstedvejens Skole"},
    {id:"851050",name:"Byplanvejens Skole"},
    {id:"851051",name:"Klostermarksskolen"},
    {id:"851052",name:"Solsideskolen"},
    {id:"851053",name:"Sct. Mariæ Skole"},
    {id:"851054",name:"Seminarieskolen"},
    {id:"851055",name:"Skipper Clement Skolen"},
    {id:"851064",name:"Højvangskolen"},
    {id:"851066",name:"Herningvej Skole"},
    {id:"851068",name:"Filipskolen Ålborg"},
    {id:"851070",name:"Aalborg Friskole"},
    {id:"851071",name:"Østskolen, Waldorfskolen i Aalborg"},
    {id:"851076",name:"Lyngbjerggårdskolen"},
    {id:"851087",name:"Mentiqa-Nordjylland"},
    {id:"861006",name:"Hornum Skole"},
    {id:"861009",name:"Vestrup Skole"},
    {id:"861010",name:"Aars Skole"},
    {id:"861011",name:"Østermarkskolen"},
    {id:"861012",name:"Haubro Landsbyskole - Himmerlands Friskole"}
    ];