import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import avatars from '../Assets/Images/avatars.js';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';
import {useGlobal} from 'reactn';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 640,
    height: 850,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Vælg Avatar</DialogTitle>
      <Grid container>
      <GridList cellHeight={80} cols={4} className={classes.gridList}>
        {avatars.map((avatar,index) => (
          <GridListTile onClick={() => handleListItemClick(index)} key={avatar.title}>
            <img src={avatar.img} alt={avatar.title} />
            <GridListTileBar
              title={avatar.title}
            />
          </GridListTile>
        ))}
      </GridList>
      </Grid>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function AvatarPicker(membernumber) {
  const [open, setOpen] = React.useState(false);
  const [global,setGlobal] = useGlobal();
  const [selectedValue, setSelectedValue] = React.useState(global.gruppemedlemmer[membernumber.membernumber].avatar);
  if(selectedValue!==global.gruppemedlemmer[membernumber.membernumber].avatar){
  setSelectedValue(global.gruppemedlemmer[membernumber.membernumber].avatar);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
    const handleClose = value => {
        if(parseInt(value)>=0){
            setOpen(false);
            setSelectedValue(value);
            let memberUpdater = global.gruppemedlemmer.map((medlem,j)=>{
              if (j===membernumber.membernumber){
                  return {name:medlem.name,avatar:value};
              }else{
                  return medlem;
              }
          });
            setGlobal({gruppemedlemmer:memberUpdater});  
        }else{
            setOpen(false);
        }
    }

  return (
    <>
    <Grid container alignItems="center" alignContent="center" style={{border:"1px solid grey",marginTop:"5px"}} onClick={handleClickOpen}><Grid item xs={6} >
      <img width="100" height="100" style={{border:"1px solid grey",backgroundColor:"grey",padding:"5px"}} src={avatars[selectedValue].img} onClick={handleClickOpen} alt="selected Avatar"/>
      </Grid>
      <Grid item xs={6}>
      <Button onClick={handleClickOpen}>
        Vælg en avatar
      </Button>
      
      
      
      </Grid></Grid>
      <SimpleDialog selectedValue={avatars[selectedValue].img} open={open} onClose={handleClose} />
      </>
  );
}
