import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    pageContainer:{
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    root: {
      maxWidth: "100%",
    },
    backButton: {
      marginRight:theme.spacing(2)
    },
    spsContainer:{
      marginTop:"20px",
      height: "6940px",
      overflow:"hidden",
    }
    
  }));

  export default function LaererSPS() {
    const classes = useStyles();
    const LinkBehavior = React.forwardRef((props, ref) => (
      <RouterLink ref={ref} to="/" {...props} />
    ));
return(<div>
    <AppBar position="static">
      <Toolbar>
      <IconButton component={LinkBehavior} edge="start" className={classes.backButton} color="inherit" aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Vidensrummet
        </Typography>
        
      </Toolbar>
      
    </AppBar>
  <div className={classes.spsContainer}><iframe title="Spørgeskema til Lærer" src="https://docs.google.com/forms/d/e/1FAIpQLSfayBcbJxqd95gKH0sSrTeEm2f-X72EkAJbRBiR0oA85DlpAw/viewform?embedded=true" width="740" height="7240" frameBorder="0" marginHeight="0" marginWidth="0">Indlæser…</iframe></div></div>);
  }
