
import React from 'react';
import { Route, BrowserRouter  as Router, Switch, Redirect} from 'react-router-dom';
import Start from './Pages/Start.js';
import LaererSPS from './Pages/LaererSPS.js';
import UtzonSPS from './Pages/UtzonSPS.js';
import Skolebesoeg from './Pages/Skolebesoeg.js';
import Adminskolebesoeg from './Pages/AdminSkoleBesoeg.js';
import Boligen from './Pages/Boligen.js';
import RumOgLys from './Pages/RumOgLys.js';
import ForloebOpstart from './Pages/ForloebOpstart.js';
import ISkalaensVerden from './Pages/ISkalaensVerden.js';
import AdminGenoptag from './Pages/AdminGenoptag.js';
import AdminGenoptagGruppe from './Pages/AdminGenoptageGruppe.js';
import RolStepOne from './Pages/RolStepOne.js';
import RolStepTwo from './Pages/RolStepTwo.js';
import RolStepThree from './Pages/RolStepThree.js';
import IsvStepOne from './Pages/IsvStepOne.js';
import IsvStepTwo from './Pages/IsvStepTwo.js';
import IsvStepThree from './Pages/IsvStepThree.js';



function App() {
  const [authenticated,setAuthenticated] = React.useState(false);
function PrivaterRoute ({children, ...rest}){
  return(
    <Route {...rest} render={()=> {
      return authenticated=== true?  children:<Redirect to="/"/>; 
    }} />
  )
}
return (
  
  <Router basename={`${process.env.PUBLIC_URL}/`}>
    <Switch>
    <Route exact path="/" >
      <Start authenticationUpdate={setAuthenticated} />
              </Route>

    <Route exact path="/laerersps" component={LaererSPS} />
    <Route exact path="/utzonsps" component={UtzonSPS}/>
    <Route exact path="/skolebesoeg" component={Skolebesoeg}/>
    <PrivaterRoute exact path="/adminskolebesoeg">
      <Adminskolebesoeg/>
    </PrivaterRoute>
    <PrivaterRoute exact path="/genoptag" >
      <AdminGenoptag/>
      </PrivaterRoute>
    <PrivaterRoute exact path="/genoptagGruppe" >
    <AdminGenoptagGruppe/>
    </PrivaterRoute>
    <Route exact path="/forloebopstart" component={ForloebOpstart} />
    <Route exact path="/rol" component={RumOgLys} />
    <Route exact path="/rolone" component={RolStepOne}/>
    <Route exact path="/roltwo" component={RolStepTwo}/>
    <Route exact path="/rolthree" component={RolStepThree}/>
    <Route exact path="/bol" component={Boligen} />
    <Route exact path="/isv" component={ISkalaensVerden} />
    <Route exact path="/isvlvl1" component={IsvStepOne} />
    <Route exact path="/isvlvl2" component={IsvStepTwo} />
    <Route exact path="/isvlvl3" component={IsvStepThree} />
    <Route component={Start} />
    </Switch>
  </Router>
  
);
}

export default App;
