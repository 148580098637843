import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useGlobal } from 'reactn';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    pageContainer:{
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    root: {
      maxWidth: "100%",
    },
    
  }));

  export default function AdminGenoptagGruppe() {
    const history = useHistory();
    const classes = useStyles();
    const [grupper,setGrupper] = React.useState("");
    const [global,setGlobal]= useGlobal();
    const handleStartCourse = (theGroup) => {
        setGlobal({
            gruppeId:theGroup.id,
            gruppemedlemmer:theGroup.gruppemedlemmer,});
      if(global.besoegsForloeb === "Ultrabit: Rum og Lys"){
        let progress=1;
        if(theGroup.modsaetninger!=="" && theGroup.skala!=="" && theGroup.modellen!==""){
          progress=3;
        } 
        console.log("step 3 conditions: "+ theGroup.placering+" | " + theGroup.dagslaengde+" | " +theGroup.farver)
        if(theGroup.placering!=="" && theGroup.farver!=="" && theGroup.dagslaengde!==""){
          progress=5;
        }
          setGlobal({
                rolProgress:progress
            });

      history.push('/rol');
        }else if(global.besoegsForloeb === "I Skalaens Verden"){
            setGlobal({
                skitsemodel:theGroup.skitsemodel,
                modulmodel:theGroup.modulmodel,
                model:theGroup.model,
                stopmotionvideo:theGroup.stopmotionvideo,
                minecraftmodellen:theGroup.minecraftmodellen,
                minecraftudsigten:theGroup.minecraftudsigten,
                minecraftdetaljen:theGroup.minecraftdetaljen,
            });
            history.push('/isv');
        }else if(global.besoegsForloeb === "Boligen - det enkle liv og landskabet"){
            setGlobal({
                registreringer:theGroup.registreringer,
                funktioner:theGroup.funktioner,
                organisation:theGroup.organisation,
                faellesskitse:theGroup.faellesskitse,
                maalfast:theGroup.maalfast,
                snit:theGroup.snit,
                digitalplan:theGroup.digitalplan,
                tredimensionelmodel:theGroup.tredimensionelmodel,
                render:theGroup.render,});
                history.push('/bol');
        }
    }
    useEffect(()=>{
        if(grupper===""){
      switch(global.besoegsForloeb){
          case "Ultrabit: Rum og Lys":
      fetch("https://vidensrummet.dk/vidensrumapi/rumoglysbesoeg/read.php",{
        method: 'GET',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            besoegsid:global.besoegsid,
        })
    })
    .then(response => response.json())
    .then(data =>{ console.log(data.records);setGrupper(data.records);console.log(data.records)}).then(console.log(grupper));
        break;
    case "I Skalaens Verden":
      fetch("https://vidensrummet.dk/vidensrumapi/iskalaensverdenbesoeg/read.php",{
        method: 'GET',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            besoegsid:global.besoegsid,
        })
    })
    .then(response => response.json())
    .then(data =>{ console.log(data.records);setGrupper(data.records);console.log(data.records)}).then(console.log(grupper));
    break;
    case "Boligen - det enkle liv og landskabet":
      fetch("https://vidensrummet.dk/vidensrumapi/boligenbesoeg/read.php",{
        method: 'GET',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            besoegsid:global.besoegsid,
        })
    })
    .then(response => response.json())
    .then(data =>{ console.log(data.records);setGrupper(data.records);console.log(data.records)}).then(console.log(grupper));
    break;
}
    }
},[]);
    
return(<div>
    <AppBar position="static">
      <Toolbar>
      <IconButton component={RouterLink} to="/" edge="start" className={classes.backButton} color="inherit" aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Vidensrummet - Skolebesøg
        </Typography>
        
      </Toolbar>
      
    </AppBar>
    <div>
    <List className={classes.listRoot}>
        {grupper.length>0?grupper.map((gruppe,index)=>{
        return(
           
          <ListItem onClick={()=>handleStartCourse(gruppe)} key={gruppe.id} divider>
            <ListItemText
              primary={gruppe.gruppemedlemmer.map(function (medlem) { return medlem.name; }).join(" & ")}
              secondary={gruppe.id}
            />
          </ListItem>)
        }):<ListItem divider>
        <ListItemText
          primary="ingen besøg registreret idag"
        />
      </ListItem>}
        </List>
    </div>
  </div>);
  }