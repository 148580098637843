
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect } from 'reactn';
import { Card, FormGroup} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import undervisningsBillede from '../Assets/Images/undervisning merged@0.25x.png';
import genoptagBillede from '../Assets/Images/resumeImg.png';
import sendDataBillede from '../Assets/Images/SendDataImg.png';
import spoergeskemaBillede from '../Assets/Images/checklist-check-list-marker.jpg'
import spoergeskemaUCBillede from '../Assets/Images/checklist-check-list-marker-bleu.jpg'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {Link as RouterLink} from 'react-router-dom';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  pageContainer:{
    flexGrow: 1,
    marginTop:"20px"
  },
  title: {
    flexGrow: 1,
  },
  root: {
    maxWidth: "100%",
  },
  media: {
    height: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: green[500],
    
  },
}));

export default function Start({authenticationUpdate}) {
    const classes = useStyles();
    const [loggedIn,setLoggedIn] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [email,setEmail] = React.useState("");
    const [password,setPassword] = React.useState("");
    const [openError,setOpenError] = React.useState(false);
    const [openSuccess,setOpenSuccess] = React.useState(false);
    const handleClickOpen = ()=> {
      if(loggedIn){
        setCookie("jwt", "", 1);
        setLoggedIn(false);
        authenticationUpdate(false);
      }else{
      setCookie("jwt", "", 1);
      setOpen(true);
      }
    }
    
  
    const handleSuccessClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSuccess(false);
    };
    const handleErrorClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenError(false);
    };
    
    const setCookie=(cname,cvalue,exdays)=>{
      let d = new Date();
      d.setTime(d.getTime()+(exdays*24*60*60*1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    const getCookie =(cname)=>{
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' '){
              c = c.substring(1);
          }
   
          if (c.indexOf(name) === 0) {
              return c.substring(name.length, c.length);
          }
      }
      return "";
  }
    const handleClose = () => {
      setOpen(false);
    };
    function handlefetchErrors(response) {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      return response;
  }
    const handleLogin = () =>{
      setLoading(true);
      fetch("https://vidensrummet.dk/vidensrumapi/user/login.php",{
          method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email:email,
          password:password
        })
        }).then(handlefetchErrors).then(response => response.json()).then(data=>{
          window.setTimeout(
            () => {
            setLoading(false);
            setOpenSuccess(true);
            handleClose();
            setLoggedIn(true);
            authenticationUpdate(true);
          }, 1000);
          setCookie("jwt",data.jwt,1);
         
        }).catch( error=>{
          authenticationUpdate(false);
          window.setTimeout(
            () => {
            setLoading(false);
            setOpenError(true);
          }, 1000);

        });
    }
    useEffect(()=>{
      let jwt = getCookie("jwt");
      if(jwt!==""  && jwt !== undefined && loggedIn===false){
        fetch("https://vidensrummet.dk/vidensrumapi/validate_token.php",{
          method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt: jwt
        })}).then(handlefetchErrors).then(response=>{setLoggedIn(true);authenticationUpdate(true);}).catch(error=>{console.log("invalid token"); setLoggedIn(false);authenticationUpdate(false);setCookie("jwt","",1)})
        setLoggedIn(false);
      }
    });
    let userContent = <div className={classes.pageContainer}><Grid  container
    direction="row"
    justify="center"
    alignItems="center" spacing={2}><Grid item xs={10}><Card className={classes.root}>
    <CardActionArea component={RouterLink} to="/skolebesoeg">
      <CardMedia
        className={classes.media}
        image={undervisningsBillede}
        title="SkoleBesøg"
      />
      <CardContent >
        <Typography gutterBottom variant="h5" component="h2">
          Skolebesøg
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Et besøg på Utzon Center med undervisning i et af de 3 forløb:
          <br />
          Boligen - det enkle liv og landskabet<br />
          Ultrabit::Rum og Lys<br />
          I Skalaens Verden<br />
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card></Grid>
  </Grid><Grid  container
    direction="row"
    justify="center"
    alignItems="center" spacing={2}><Grid item xs={5}><Card className={classes.root}>
    <CardActionArea  component={RouterLink} to="/laerersps">
      <CardMedia
        className={classes.media}
        image={spoergeskemaBillede}
        title="Spørgeskema"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Spørgeskema til lærer
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Her finder du spørgeskemaet til de lærerer der deltager ved et skolebesøg. Spørgeskemaet har til formål at indsamle viden om Lærerens oplevelse.
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card></Grid><Grid item xs={5}><Card className={classes.root}>
    <CardActionArea component={RouterLink} to="/utzonsps">
      <CardMedia
        className={classes.media}
        image={spoergeskemaUCBillede}
        title="Spørgeskema"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Spørgeskema til formidler
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Her finder du spørgeskemaet til Utzon formidleren. Spørgeskemaet har til formål at indsamle viden og erfaringer fra arbejdet med at undervise skoleklasser.
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card></Grid>
  </Grid></div>;
  
  let adminContent =<Grid container
  direction="row"
  justify="center"
  alignItems="center" spacing={2}>
    <Grid item xs={5}>
      <Card className={classes.root}>
  <CardActionArea  component={RouterLink} to="/adminskolebesoeg">
    <CardMedia
      className={classes.media}
      image={undervisningsBillede}
      title="SkoleBesøg"
    />
    <CardContent style={{height:"160px"}}>
      <Typography gutterBottom variant="h5" component="h2">
        Skolebesøg
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Et besøg på Utzon Center med undervisning i et af de 3 forløb:
        Boligen - det enkle liv og landskabet
        Ultrabit::Rum og Lys
        I Skalaens Verden
      </Typography>
    </CardContent>
  </CardActionArea>
</Card></Grid>
<Grid item xs={5}><Card className={classes.root}>
  <CardActionArea component={RouterLink} to="/genoptag">
    <CardMedia
      className={classes.media}
      image={genoptagBillede}
      title="Genoptag"
    />
    <CardContent style={{height:"160px"}}>
      <Typography gutterBottom variant="h5" component="h2">
        Genoptag forløb
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Er appen crashet, ipadden gået ud eller er der brug for at tilføje data på et eksisterende forløb?
      </Typography>
    </CardContent>
  </CardActionArea>
</Card></Grid>
<Grid item xs={5}><Card className={classes.root}>
  <CardActionArea>
    <CardMedia
      className={classes.media}
      image={sendDataBillede}
      title="Ship it"
    />
    <CardContent style={{height:"160px"}}>
      <Typography gutterBottom variant="h5" component="h2">
        Send Data
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Er der en skole der har ønsket data fra deres besøg kan du sende et link til dem her.
      </Typography>
    </CardContent>
  </CardActionArea>
</Card></Grid>
<Grid item xs={5}><Card className={classes.root}>
  <CardActionArea>
    <CardMedia
      className={classes.media}
      image={undervisningsBillede}
      title="SkoleBesøg"
    />
    <CardContent style={{height:"160px"}}>
      <Typography gutterBottom variant="h5" component="h2">
        Skolebesøg
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Et besøg på Utzon Center med undervisning i et af de 3 forløb:
        Boligen - det enkle liv og landskabet
        Ultrabit::Rum og Lys
        I Skalaens Verden
      </Typography>
    </CardContent>
  </CardActionArea>
</Card></Grid>
</Grid>;
    return(
<div className="App">
     <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Vidensrummet
          </Typography>
          <Button onClick={handleClickOpen} color="inherit">{loggedIn?"Log ud":"Log ind"}</Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Log ind</DialogTitle>
        <DialogContent>
          <FormGroup>
          <TextField
            autoFocus
            variant="filled"
            id="name"
            label="Email"
            type="email"
            value={email}
            onChange={(event)=>{setEmail(event.target.value)}}
          />
          <TextField
            variant="filled"
            id="password"
            label="kode"
            type="password"
            value={password}
            onChange={(event)=>{setPassword(event.target.value)}}
          />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          {loading? <CircularProgress size={24} className={classes.buttonProgress} />:<Button onClick={handleLogin} color="primary">
            Log mig ind
          </Button>}
          
      <Snackbar anchorOrigin={{vertical:"top", horizontal:"center"}} open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
      <Alert onClose={handleErrorClose} severity="error">Forkert email eller password</Alert>
      </Snackbar>
      
        </DialogActions>
      </Dialog>
      <Snackbar anchorOrigin={{vertical:"top", horizontal:"center"}} open={openSuccess} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          Du er nu logget ind!
        </Alert>
      </Snackbar>
        </Toolbar>
        
      </AppBar>
    </div>
    <div className={classes.pageContainer}>
    {loggedIn?adminContent:userContent}
    </div>
    </div>
    );
}