import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useEffect, useGlobal } from 'reactn';
import Typography from '@material-ui/core/Typography';
import { Button, Card,  Grid, TextField } from '@material-ui/core';

import AvatarPicker from '../Components/AvatarPicker';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
    gridRoot:{
      flexGrow: 1,
    },
    cardRoot: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    
  }));

  export default function ForloebOpstart() {
    const history = useHistory();
    const classes = useStyles();
    const [gruppeId,setGruppeId] = useGlobal("gruppeId");
    const [global,setGlobal] = useGlobal();
    const [gruppeMedlemmer,setGruppeMedlemmer] = useGlobal("gruppemedlemmer");
    const [emptyName,setEmptyName] = React.useState(true);
    const handleForloebStart = (forloeb)=>{
      switch(global.besoegsForloeb){
        case "Ultrabit: Rum og Lys":
      history.push("/rol");
      break;
      case "Boligen - det enkle liv og landskabet":
        history.push("/bol");
      break;
      case "I Skalaens Verden":
        history.push("/isv");
        break;
      }
    }
    function handlefetchErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
      }
      function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
      }
    useEffect(()=>{
      switch(global.besoegsForloeb){
        case "Ultrabit: Rum og Lys":
        fetch('https://vidensrummet.dk/vidensrumapi/rumoglysbesoeg/create.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    besoegsid:global.besoegsid,
    gruppemedlemmer:global.gruppemedlemmer
    })
    
  }).then(handlefetchErrors).then(response => response.json()).then(data=>{
    setGruppeId(data.id);
  }).catch( error=>{
    console.log("ugyldigt besøgsid");
  });
  break;
  case "Boligen - det enkle liv og landskabet":
    fetch('https://vidensrummet.dk/vidensrumapi/boligenbesoeg/create.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
    besoegsid:global.besoegsid,
    gruppemedlemmer:global.gruppemedlemmer
    })
    
  }).then(handlefetchErrors).then(response => response.json()).then(data=>{
    setGruppeId(data.id);
  }).catch( error=>{
    console.log("ugyldigt besøgsid");
  });
  break;
  case "I Skalaens Verden":
    fetch('https://vidensrummet.dk/vidensrumapi/iskalaensverdenbesoeg/create.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
besoegsid:global.besoegsid,
gruppemedlemmer:global.gruppemedlemmer
    })
    
  }).then(handlefetchErrors).then(response => response.json()).then(data=>{
    setGruppeId(data.id);
  }).catch( error=>{
    console.log("ugyldigt besøgsid");
  });
  break;
}

    },[]);
    useEffect(()=>{
      if(global.gruppemedlemmer!==[{name:"",avatar:0},{name:"",avatar:1}]){
      switch(global.besoegsForloeb){
        case "Ultrabit: Rum og Lys":
      fetch('https://vidensrummet.dk/vidensrumapi/rumoglysbesoeg/update.php', {
        method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id:gruppeId,
        besoegsid:global.besoegsid,
        gruppemedlemmer: global.gruppemedlemmer,
      })
    }).then(response =>response.json());
    break;
    case "Boligen - det enkle liv og landskabet":
      fetch('https://vidensrummet.dk/vidensrumapi/boligenbesoeg/update.php', {
        method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id:gruppeId,
        besoegsid:global.besoegsid,
        gruppemedlemmer: global.gruppemedlemmer,
        registreringer:global.registreringer,
        viewImages:global.viewImages,
        funktioner:global.funktioner,
        organisation:global.organisation,
        faellesskitse:global.faellesskitse,
        maalfast:global.maalfast,
        snit:global.snit,
        digitalplan:global.digitalplan,
        tredimensionelmodel:global.tredimensionelmodel,
        render:global.render,
      })
    }).then(response =>response.json());
    break;
    case "I Skalaens Verden":
      fetch('https://vidensrummet.dk/vidensrumapi/iskalaensverdenbesoeg/update.php', {
        method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id:gruppeId,
        besoegsid:global.besoegsid,
        gruppemedlemmer: global.gruppemedlemmer,
      })
    }).then(response =>response.json());
    break;
  }
    for(var i = 0; i < gruppeMedlemmer.length; i++) {
      if (gruppeMedlemmer[i].name === '') {
          setEmptyName(true);
          break;
      }
      if(i===gruppeMedlemmer.length-1){
        setEmptyName(false);
      }
    }}
    },[gruppeMedlemmer]);
      return(
    <div>
        <AppBar position="static">
            <Toolbar>
            <Typography variant="h6" className={classes.title}>
                Ultrabit: Rum og Lys
            </Typography>
            
            </Toolbar>
            
        </AppBar>
        <div className={classes.gridRoot}>
        <Grid  container direction="row"
  justify="center"
  alignItems="center"
 spacing={2} > <Grid item xs={10}><Card className={classes.cardRoot}><Typography variant="h4">Velkommen </Typography><Typography variant="h5">{global.besoegsKlasser.map(function (klasse,i){return <React.Fragment key={i}>{klasse}{ i>= global.besoegsKlasser.length - 1 ? null : i<global.besoegsKlasser.length-2?", ":" & "}</React.Fragment>;})}</Typography>{global.besoegsSkoler.map(function (school,i) { return <Typography key={school.id}  variant="h5">{school.name}{i >= global.besoegsSkoler.length - 1 ? null : i<global.besoegsSkoler.length-2?", ":" & "}</Typography> })} <Typography  variant="h5">til forløbet {global.besoegsForloeb}</Typography><Typography>Start med at oprette jeres gruppe, skriv jeres navn og vælg en avatar hver</Typography></Card></Grid>
            {gruppeMedlemmer.map((medlem,index)=>{
              return(
              <Grid key={index} item xs={5}>
                <Card className={classes.cardRoot} style={{height:"210px"}}>
                <TextField variant="outlined" fullWidth value={medlem.name} onChange={(event)=>{
                    let memberUpdater = gruppeMedlemmer.map((medlem,j)=>{
                      if (j===index){
                          return {name:event.target.value,avatar:medlem.avatar};
                      }else{
                          return medlem;
                      }
                    
                  });
                  setGruppeMedlemmer(memberUpdater);
                }
                } label="navn"/>
                <div style={{border:"1px soild white"}}>
                <AvatarPicker membernumber={index} key={index} />
                </div>
                </Card>
              </Grid>)})}
              {gruppeMedlemmer.length<6 && <Grid item xs={5}>
                <Card className={classes.cardRoot} style={{height:"160px"}}>
                  <Button onClick={()=>{
                let membies = gruppeMedlemmer;
                membies.push({name:"",avatar:getRandomInt(28)});
                setGruppeMedlemmer(membies);
                setEmptyName(true)}}>
                  + Tilføj gruppemedlem
                </Button>
                </Card>
              </Grid>}
              <Grid item xs={5}></Grid>
        </Grid>
        <Grid container alignItems="center" justify="center" style={{marginTop:"15px"}}>
                    <Grid item xs={2}>
                      <Button variant="contained" disabled={emptyName} fullWidth size="large" color="secondary" onClick={handleForloebStart}>Næste</Button>
                    </Grid>
        </Grid>

        </div>
    </div>);
  }