import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'reactn';
import { useGlobal } from 'reactn';
import takeThatPhoto from '../Assets/Images/takephoto.png';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  listRoot:{
    overflow: 'auto',
    maxHeight: 500,
  },
  listContainer:{
      backgroundColor: theme.palette.background.paper,
  },
  dialogContainer: {
    backgroundColor: "#F24949",
    position:"fixed",
    height:"760px",
    width:"660px",
    padding:"20px",
    top:"200px",
    left:"50px"
  },
  input: {
    display:'none',
  },
  pickVidButton:{
    position:"absolute",
    top:"500px",
    left:"303px",
    zIndex:"10",
    color:"#000",
    border:"2px solid #000"
    
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  photoContainer:{
    height:"1024px",
  }
}));

export default function TakePhoto(props){
  const [ global, setGlobal ] = useGlobal();
    const classes = useStyles();
    const [buttonText,setButtonText] = React.useState("Tag Billede"); 
    let usePhoto = takeThatPhoto;
    if(props.tegning==="faellesskitse" && global.faellesskitse!== ""){
        usePhoto = global.faellesskitse;
       
    }else if(props.tegning==="maalfast" && global.maalfast!== ""){
        usePhoto = global.maalfast;

    }if(props.tegning==="snit" && global.snit!== ""){
        usePhoto = global.snit;

    }if(props.tegning==="digiplan" && global.digitalplan!== ""){
      usePhoto = global.digitalplan;

  }if(props.tegning==="treD" && global.tredimensionelmodel!== ""){
    usePhoto = global.tredimensionelmodel;

}if(props.tegning==="render" && global.render!== ""){
  usePhoto = global.render;

}
    const [shownPhoto,setShownPhoto] = useState(usePhoto);
    const handlePhotoSet =(event)=>{
        const target = event.target; 
        if (typeof target.files[0] !== 'undefined'){
          setButtonText("Tag nyt Billede");
          let blobben =URL.createObjectURL(target.files[0]);
          if(props.tegning==="faellesskitse"){
              setGlobal({faellesskitse:blobben});
              setShownPhoto(blobben);
              sendFile(target.files[0],"faellesskitse");
          }else if(props.tegning==="maalfast"){
              setGlobal({maalfast:blobben});
              setShownPhoto(blobben);
              sendFile(target.files[0],"maalfast");
          }if(props.tegning==="snit"){
              setGlobal({snit:blobben});
              setShownPhoto(blobben);
              sendFile(target.files[0],"snit");
          }if(props.tegning==="treD"){
            setGlobal({tredimensionelmodel:blobben});
            setShownPhoto(blobben);
            sendFile(target.files[0],"treD");
        }if(props.tegning==="digiplan"){
          setGlobal({digitalplan:blobben});
          setShownPhoto(blobben);
          sendFile(target.files[0],"digiplan");
      }if(props.tegning==="render"){
        setGlobal({render:blobben});
        setShownPhoto(blobben);
        sendFile(target.files[0],"render");
    }
      }
        
    }
    useEffect(()=>{
      fetch('https://vidensrummet.dk/vidensrumapi/boligenbesoeg/update.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:global.gruppeId,
      gruppemedlemmer:global.gruppemedlemmer,
      registreringer:global.registreringer,
      viewImages:global.viewImages,
      funktioner:global.funktioner, 
      organisation:global.organisation, 
      faellesskitse:global.faellesskitse, 
      maalfast:global.maalfast, 
      snit:global.snit, 
      digitalplan:global.digitalplan, 
      render:global.render, 
      tredimensionelmodel:global.tredimensionelmodel, 
    })
    }).then(response => response.json());
  },[global]);

  const sendFile = (file,name) =>{
    let fdata = new FormData();
    fdata.append(String(name), file);
      fetch('https://vidensrummet.dk/vidensrumapi/uploadimage.php', {
        method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      body: fdata
    }).then(response => response.json()).then(data =>{
      switch(name){
        case "faellesskitse":
          setGlobal({ faellesskitse: data.url }); 
        break;
        case "maalfast":
          setGlobal({ maalfast: data.url });
        break;
        case "snit":
          setGlobal({ snit: data.url });
        break;
        case "digiplan":
          setGlobal({ digitalplan: data.url });
        break;
        case "treD":
          setGlobal({ tredimensionelmodel: data.url });
        break;
        case "render":
          setGlobal({ render: data.url });
        break;
        default:
          alert("no valid data");
  }});
    }

    return(<div><label><div className={classes.photoContainer} style={{backgroundImage:"url("+shownPhoto+")",backgroundSize:"cover",backgroundPosiition:"center",}}>
        <Button color="secondary" className={classes.pickVidButton} variant="outlined" component="span">
        {buttonText}
        </Button>
        <input
        onChange={handlePhotoSet}
            accept="image/*"
            capture
            className={classes.input}
            id="skitse-file"
            type="file"
          /></div></label></div>);
}