import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import RlBadge from '../Components/Badge.js';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useGlobal } from 'reactn';
import DefaultImg from '../Assets/Images/default.png';
import DefaultPhotoImg from '../Assets/Images/takephoto.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import smAppIko from '../Assets/Images/stopmotionappikon.jpg';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Redirect } from 'react-router-dom';
import AvatarBar from '../Components/AvatarBar.js';

const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
    paper:{
      backgroundColor:"#3D3D84",
    },
    dialogTitleRoot:{
      fontSize:"38px",
      color:"#fff",
    },
    iconRoot:{
      color:"green",
      fontSize:"78px",
      '&:hover': {
        backgroundColor: "rgba(73, 273, 73, 0.08)"
      }
    },
    iconRootCircle:{
      color:"green",
      fontSize:"78px",
    },
      levelRow:{
        marginTop:"8px",
        borderTop:"5px solid rgba(0, 0, 0, 0.0)",
        borderBottom:"10px solid rgba(0, 0, 0, 0.0)",
          height:"300px",
          padding:"20px 0px 20px 0px",
          backgroundColor:"rgba(0, 0, 0, 0.08)",
          '&:nth-of-type(2n+1)':{
            backgroundColor:"rgba(0, 0, 0, 0.18)",
          },
          '&:nth-of-type(3n+1)':{
            backgroundColor:"rgba(0, 0, 0, 0.28)",
          },
          '&:nth-of-type(4n+1)':{
            backgroundColor:"rgba(0, 0, 0, 0.38)",
          },
      },
      activeLevelRow:{
        borderTop:"5px solid rgba(73, 273, 73, 0.0)",
        borderBottom:"10px solid rgba(73, 273, 73, 0.0)",
          height:"300px",
          marginTop:"8px",
          padding:"10px 0px 10px 0px",
          
      }
        }));

  export default function ISkalaensVerden() {
    const classes = useStyles();
    const [dialogWelOpen, setDialogWelOpen] = React.useState(false);
    const [dialogOneOpen, setDialogOneOpen] = React.useState(false);
    const [dialogTwoOpen, setDialogTwoOpen] = React.useState(false);
    const [dialogThreeOpen, setDialogThreeOpen] = React.useState(false);
    const [dialogFourOpen, setDialogFourOpen] = React.useState(false);
    const [global,setGlobal] = useGlobal();
    const [activeLevel,setActiveLevel] = React.useState(0);
    const [redirect,setRedirect] = React.useState("");
    const handleClose = () => {
      setDialogWelOpen(false);
      setDialogOneOpen(false);
      setDialogTwoOpen(false);
      setDialogThreeOpen(false);
      setDialogFourOpen(false);
    };

    let skitseContent=(global.skitsemodel!==null&&global.skitsemodel!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+global.skitsemodel+")",backgroundSize: "cover",backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>: <div></div>;
    let modulContent=(global.modulmodel!==null&&global.modulmodel!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+global.modulmodel+")",backgroundSize: "cover",backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>: <div></div>;
    let modelContent=(global.model!==null&&global.model!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+global.model+")",backgroundSize: "cover",backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>: <div></div>;
    let stopMotionContent=(global.stopmotionvideo!==null&&global.stopmotionvideo!=="")? <div style={{height:"219px",width:"389px",overflow:"hidden"}}>
    <video src={global.stopmotionvideo} loop playsInline autoPlay muted width="389px" poster={DefaultImg}/>
    </div>: <div></div>;
    let minecraftmodellenContent=(global.minecraftmodellen!==null&&global.minecraftmodellen!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+global.minecraftmodellen+")",backgroundSize: "cover",backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>: <div></div>;
    let minecraftudsigtenContent=(global.minecraftudsigten!==null&&global.minecraftudsigten!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+global.minecraftudsigten+")",backgroundSize: "cover",backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>: <div></div>;
    let minecraftdetaljenContent=(global.minecraftdetaljen!==null&&global.minecraftdetaljen!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+global.minecraftdetaljen+")",backgroundSize: "cover",backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>: <div></div>;
    let level1Badges = <Grid className={(activeLevel === 0)?classes.activeLevelRow:classes.levelRow} onClick={()=>setRedirect("level1")} container  justify="center"><Grid item xs={10}><h2 style={{textShadow:activeLevel===0?"2px 2px green":"",color:activeLevel===0?"white":"grey"}}>Trin 1 - Byggeriet</h2></Grid><Grid item xs={5}><img alt="tag billede" src={DefaultPhotoImg} height="200px" width="300px"/></Grid><Grid item xs={1}><h2 style={{textShadow:activeLevel===0?"2px 2px green":"",color:activeLevel===0?"white":"grey"}}>Skitse</h2><h2 style={{textShadow:activeLevel===0?"2px 2px green":"",color:activeLevel===0?"white":"grey"}}>Moduler</h2><h2 style={{textShadow:activeLevel===0?"2px 2px green":"",color:activeLevel===0?"white":"grey"}}>Model</h2></Grid></Grid>;
    let level2Badges = <Grid className={(activeLevel === 1)?classes.activeLevelRow:classes.levelRow} onClick={()=>{if(activeLevel === 1){setRedirect("level2")}}} container  justify="center"><Grid item xs={10}><h2 style={{textShadow:activeLevel===1?"2px 2px green":"",color:activeLevel===1?"white":"grey"}}>Trin 2a - Fortællingen</h2></Grid><Grid item xs={5}><img alt="tag billede" src={smAppIko} height="200px" width="200px" style={{borderRadius:"22.5%",marginLeft:"40px"}}/></Grid><Grid item xs={1}><h2 style={{textShadow:activeLevel===1?"2px 2px green":"",color:activeLevel===1?"white":"grey"}}>Stop Motion Filmen</h2></Grid></Grid>;
    let level3Badges = <Grid className={(activeLevel === 1)?classes.activeLevelRow:classes.levelRow} onClick={()=>{if(activeLevel === 1){setRedirect("level3")}}} container  justify="center"><Grid item xs={10}><h2 style={{textShadow:activeLevel===1?"2px 2px green":"",color:activeLevel===1?"white":"grey"}}>Trin 2b - Registreringer i 1:1</h2></Grid><Grid item xs={5}><img alt="tag billede" src={DefaultPhotoImg} height="200px" width="300px"/></Grid><Grid item xs={1}><h2 style={{textShadow:activeLevel===1?"2px 2px green":"",color:activeLevel===1?"white":"grey"}}>Bygningen</h2><h2 style={{textShadow:activeLevel===1?"2px 2px green":"",color:activeLevel===1?"white":"grey"}}>Detaljen</h2><h2 style={{textShadow:activeLevel===1?"2px 2px green":"",color:activeLevel===1?"white":"grey"}}>Udsigten</h2></Grid></Grid>;
    if(activeLevel===0 &&(global.skitsemodel!==null&&global.skitsemodel!=="")&&(global.modulmodel!==null&&global.modulmodel!=="")&&(global.model!==null&&global.model!=="")){
      if((global.isvprogress ===1)){
      setGlobal({isvprogress:2});
      setDialogOneOpen(true);
      }
      
      setActiveLevel(1);
    }
    if(activeLevel>0){
      level1Badges = <Grid className={(activeLevel === 0)?classes.activeLevelRow:classes.levelRow} onClick={()=>setRedirect("level1")} container  justify="space-around"><Grid item><RlBadge ribbonText="SKITSEMODELLEN" borderColor="transparent" content={skitseContent}/></Grid><Grid item><RlBadge ribbonText="MODULET/ERNE" borderColor="transparent" content={modulContent}/></Grid><Grid item><RlBadge ribbonText="MODELLEN" borderColor="transparent" content={modelContent}/></Grid></Grid>;
    }
    if(global.isvprogress !==1 && global.isvprogress !==2 && global.isvprogress !==6){
      level2Badges = <Grid className={(activeLevel === 1)?classes.activeLevelRow:classes.levelRow} onClick={()=>{if(activeLevel === 1){setRedirect("level2")}}} container  justify="space-around"><Grid item><RlBadge badgeWidth="389" ribbonText="STOPMOTIONFILMEN" borderColor="transparent" content={stopMotionContent}/></Grid></Grid>;
    }
    if(global.isvprogress !==1 && global.isvprogress !==2 && global.isvprogress !==3 && global.isvprogress !==5){
      level3Badges = <Grid className={(activeLevel === 1)?classes.activeLevelRow:classes.levelRow} onClick={()=>{if(activeLevel === 1){setRedirect("level3")}}} container  justify="space-around"><Grid item><RlBadge ribbonText="BYGNINGEN" borderColor="transparent" content={minecraftmodellenContent}/></Grid><Grid item><RlBadge ribbonText="DETALJEN" borderColor="transparent" content={minecraftudsigtenContent}/></Grid><Grid item><RlBadge ribbonText="UDSIGTEN" borderColor="transparent" content={minecraftdetaljenContent}/></Grid></Grid>;
    }
    
  if(activeLevel ===1 && (global.stopmotionvideo!==null&&global.stopmotionvideo!=="")&&(global.isvprogress===2 || global.isvprogress ===6)){
      if(global.isvprogress===2){setGlobal({isvprogress:3});}else{setGlobal({isvprogress:5});}
      setDialogTwoOpen(true);
  }
  if(activeLevel ===1 && (global.minecraftmodellen!==null&&global.minecraftmodellen!=="") && (global.minecraftudsigten!==null&&global.minecraftudsigten!=="") && (global.minecraftdetaljen!==null&&global.minecraftdetaljen!=="")&&(global.isvprogress===2 || global.isvprogress ===5)){
      if(global.isvprogress===3){setGlobal({isvprogress:4});}else{setGlobal({isvprogress:6});}
      setDialogThreeOpen(true);
      level3Badges = <Grid className={(activeLevel === 1)?classes.activeLevelRow:classes.levelRow} onClick={()=>{if(activeLevel === 1){setRedirect("level3")}}} container  justify="center"><Grid item><RlBadge ribbonText="BYGNINGEN" borderColor="transparent" content={minecraftmodellenContent}/></Grid><Grid item><RlBadge ribbonText="DETALJEN" borderColor="transparent" content={minecraftudsigtenContent}/></Grid><Grid item><RlBadge ribbonText="UDSIGTEN" borderColor="transparent" content={minecraftdetaljenContent}/></Grid></Grid>;

  }
  if(activeLevel===1 && (global.minecraftmodellen!==null&&global.minecraftmodellen!=="") && (global.minecraftudsigten!==null&&global.minecraftudsigten!=="") && (global.minecraftdetaljen!==null&&global.minecraftdetaljen!=="") && (global.stopmotionvideo!==null&&global.stopmotionvideo!=="")){
      if(global.isvprogress!==7){
      setGlobal({isvprogress:7});
      setDialogFourOpen(true);
      }
      setActiveLevel(2);
      //setRedirect("done");
    }
  if(global.isvprogress===0){
    setDialogWelOpen(true);
    setGlobal({isvprogress:1});
  }
  if (redirect==="level1") {
      return <Redirect push to="/isvlvl1" />;
    }else if(redirect==="level2"){
      return <Redirect push to="/isvlvl2" />;
    }else if(redirect==="level3"){
      return <Redirect push to="/isvlvl3" />;
    }else if(redirect==="done"){
        return <div><h1>Tak for jeres svar</h1></div>
    }
    if(global.isvprogress===0 && !dialogWelOpen){
      setDialogWelOpen(true);
      setGlobal({isvprogress:1});
    }
      return(
    <div>
        <AppBar position="static">
            <Toolbar>
            <Typography variant="h6" className={classes.title}>
                I skalaens verden
            </Typography>
            <AvatarBar />  
            </Toolbar>
            
        </AppBar>
        <div>
        {level1Badges}
            
            {level2Badges}
                    
            {level3Badges}
            <Dialog
        classes={{paper:classes.paper}}
    open={dialogWelOpen}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  ><DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle><DialogContent classes={{root:classes.dialogTitleRoot}}><DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
        Så er vi klar til start<br></br>
        Gå først videre når<br></br>
I får besked
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <IconButton size="medium" classes={{root:classes.iconRoot}} onClick={handleClose} color="secondary" autoFocus >
        <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
      </IconButton>
    </DialogActions>
  </Dialog>   
        <Dialog
        classes={{paper:classes.paper}}
    open={dialogOneOpen}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  ><DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle><DialogContent classes={{root:classes.dialogTitleRoot}}><DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
        Trin 1 er udfyldt<br></br>
        Gå først videre når<br></br>
I får besked
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <IconButton size="medium" classes={{root:classes.iconRoot}} onClick={handleClose} color="secondary" autoFocus >
        <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
      </IconButton>
    </DialogActions>
  </Dialog>
        <Dialog
        classes={{paper:classes.paper}}
  open={dialogTwoOpen}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
<DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle>
<DialogContent classes={{root:classes.dialogTitleRoot}}><DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
    Trin 2a er udfyldt<br></br>
        Gå først videre når<br></br>
I får besked
    </DialogContentText>
  </DialogContent>
  <DialogActions>
  <IconButton size="medium" classes={{root:classes.iconRoot}} onClick={handleClose} color="secondary" autoFocus >
        <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
      </IconButton>
  </DialogActions>
</Dialog>
        <Dialog
        classes={{paper:classes.paper}}
open={dialogThreeOpen}
onClose={handleClose}
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description"
>
<DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle>
<DialogContent classes={{root:classes.dialogTitleRoot}}><DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
  Trin 2b er udfyldt<br></br>
        Gå først videre når<br></br>
I får besked
  </DialogContentText>
</DialogContent>
<DialogActions>
<IconButton size="medium" classes={{root:classes.iconRoot}} onClick={handleClose} color="secondary" autoFocus >
        <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
      </IconButton>
</DialogActions>
</Dialog>
        <Dialog
        classes={{paper:classes.paper}}
open={dialogFourOpen}
onClose={handleClose}
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description"
>
<DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle>
<DialogContent classes={{root:classes.dialogTitleRoot}}><DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
  I har udfyldt det hele!<br></br>
        Tak, aflever iPad<br></br>
når I får besked
  </DialogContentText>
</DialogContent>
<DialogActions>
<IconButton size="medium" classes={{root:classes.iconRoot}} onClick={handleClose} color="secondary" autoFocus >
        <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
      </IconButton>
</DialogActions>
</Dialog>
        </div>
    </div>);
  }