import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AvatarBar from '../Components/AvatarBar.js';
import { Grid } from '@material-ui/core';
import RlBadge from '../Components/Badge.js';
import { useGlobal, useEffect } from 'reactn';
import UshMap from '../Assets/Images/Ush.png';
import KbhMap from '../Assets/Images/kbh.png';
import SydMap from '../Assets/Images/Syd.png';
import TroMap from '../Assets/Images/Tro.png';
import NaiMap from '../Assets/Images/Nai.png';
import Entil10 from '../Assets/Images/entilti.png';
import Entil20 from '../Assets/Images/entiltyve.png';
import Entil50 from '../Assets/Images/entilhalvtreds.png';
import Entil100 from '../Assets/Images/entilhundrede.png';
import MørkLys from '../Assets/Images/Mørklys.png';
import AktivAfslappet from '../Assets/Images/aktivafslappet.png';
import DramaAfdæmp from '../Assets/Images/dramatiskafdæmpet.png';
import FarligTryg from '../Assets/Images/Farligttrygt.png';
import GnmsigtOpak from '../Assets/Images/Gennemsigtigtopak.png';
import GeomOrg from '../Assets/Images/geometriskorganisk.png';
import GladTrist from '../Assets/Images/Gladtrist.png';
import HulletMassiv from '../Assets/Images/hulletmassiv.png';
import KantBlødt from '../Assets/Images/Kantetblødt.png';
import LavtHøjt from '../Assets/Images/Lavthøjt.png';
import SmaltBredt from '../Assets/Images/smaltbredt.png';
import TætÅbent from '../Assets/Images/tætåbent.png';
import VildtRoligt from '../Assets/Images/vildtroligt.png';
import OrdenKaos from '../Assets/Images/Ordenkaos.png';
import DefaultImg from '../Assets/Images/default.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import {Link as RouterLink} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
    AppBarRoot:{
        flexGrow: 1, 
    },
  stepRows:{
    height:"300px",
          marginTop:"8px",
          padding:"10px 0px 10px 0px",
          textDecoration:"none",
  },
  paper:{
    backgroundColor:theme.primary,
  },
  dialogTitleRoot:{
    fontSize:"38px",
    color:"#fff",
  },
  iconRoot:{
    color:"green",
    fontSize:"78px",
    '&:hover': {
      backgroundColor: "rgba(73, 273, 73, 0.08)"
    }
  },
  iconRootCircle:{
    color:"green",
    fontSize:"78px",
  },

  }));

  export default function RumOgLys() {
    const classes = useStyles();
    const [global,setGlobal] = useGlobal();
    const [dialogWelOpen,setDialogWelOpen] = React.useState(false);
    const [dialogOneOpen, setDialogOneOpen] = React.useState(false);
    const [dialogTwoOpen, setDialogTwoOpen] = React.useState(false);
    const [dialogFourOpen, setDialogFourOpen] = React.useState(false);
    const handleClose=()=>{
      setDialogWelOpen(false);
        setDialogOneOpen(false);
        setDialogTwoOpen(false);
        setDialogFourOpen(false);
        switch(global.rolProgress){
          case 0:
            setGlobal({rolProgress:1});
          break;
          case 2:setGlobal({rolProgress:3});
          break;
          case 4:setGlobal({rolProgress:5});
          break;
          case 6:setGlobal({rolProgress:7});
        }
    }
    useEffect(()=>{
      if(global.gruppeId!==''){
        fetch('https://vidensrummet.dk/vidensrumapi/rumoglysbesoeg/read_one.php?gruppeid='+global.gruppeId, {
          method: 'POST',
        headers: {
          'Accept': 'application/json',
        }
      }).then(response =>response.json()).then(data=>setGlobal({
        gruppemedlemmer:data.gruppemedlemmer,
        modsaetninger:data.modsaetninger,
        skala:data.skala,
        modellen:data.modellen,
        placering:data.placering,
        dagslaengde:data.dagslaengde,
        farver:data.farver,
        video:data.video,
        titel:data.titel
      }));
    }
    if(global.rolProgress===0){
      setDialogWelOpen(true);
    }
},[]);
let maps = {København:KbhMap,Ushuaia:UshMap,Sydney:SydMap,Tromsø:TroMap,Nairobi:NaiMap};
    const solTider={
        Tromsø:{ 2:{"op":"aldrig","ned":"altid","længde":"0 t 0 min"}, 1:{"op":"altid","ned":"aldrig","længde":"24 t 0 min"}},
        København:{ 2:{"op":"08:36","ned": "15:39","længde":"7 t 3 minuter"}, 1:{"op": "04:26", "ned": "21:58", "længde":"17 t 31 min"}},
        Ushuaia:{ 2:{"op": "04:52","ned": "22:12", "længde":"17 t 20 min"}, 1:{op: "09:59",ned: "17:12",længde: "7 t 12 min"}},
        Nairobi:{ 2:{"op": "06:22","ned": "18:36","længde":"12 t 14 min"}, 1:{"op":"06:32","ned":"18:33","længde":"12 t 1 min"}},
        Sydney:{ 2:{"op": "05:40","ned": "20:06","længde": "14 t 26 min"}, 1:{"op": "07:00","ned": "16:53","længde":"9 t 52 min"}},
        }
    let opposites = {'Lavt / Højt':LavtHøjt,'Hullet / Massiv':HulletMassiv,'Mørk / Lys':MørkLys,'Gennemsigtigt / Ikke Gennemsigtigt':GnmsigtOpak,'Vildt / Roligt':VildtRoligt,'Kantet / Blødt':KantBlødt,'Dramatisk / Afdæmpet':DramaAfdæmp,'Glad / Trist':GladTrist,'Orden / Kaos':OrdenKaos,'Aktiv / Afslappet':AktivAfslappet,'Tæt / Åben':TætÅbent,'Farligt / Trygt':FarligTryg,'Smalt / Bredt':SmaltBredt,'Geometrisk / Organisk':GeomOrg};
    let scalas = {"1:10":Entil10,"1:20":Entil20,"1:50":Entil50,"1:100":Entil100};
    let colors = global.farver.split(",");
    let colorCodes = {
        Red : {hex:"#FF0000",font:"black"},
        Orange : {hex:"#FFA500",font:"black"},
        Yellow : {hex:"#FFFF00",font:"black"},
        Green : {hex:"#00FF00",font:"black"},
        Blue : {hex:"#0000FF",font:"white"},
        Indigo : {hex:"#4b0082",font:"white"},
        Violet : {hex:"#8a2be2",font:"black"},
        Purple : {hex:"#FF00FF",font:"black"},
        White : {hex:"#FFFFFF",font:"black"},
        Black : {hex:"#000000",font:"white"}
    }
    let topRow="";
    let botRow="";
    let topFactor=(Math.ceil(colors.length/2)/colors.length);
    let botFactor =(Math.floor(colors.length/2)/colors.length);
    topRow=(global.farver!=="")?colors.map((color,i)=>(i%2===0)?<Grid key={i} item style={{color:colorCodes[color].font,backgroundColor:colorCodes[color].hex,height:topFactor*100+"%",width:(1/Math.ceil(colors.length/2))*100+"%",position:"relative",borderRight:"1px solid black",borderBottom:"1px solid black"}}><Typography variant="body1" style={{position:"absolute",bottom:"8px",left:"1px",fontSize:topFactor*20+"px"}}>{color}</Typography></Grid>:""):"";
    botRow=(global.farver!=="")?colors.map((color,i)=>(i%2!==0)?<Grid key={i} item style={{color:colorCodes[color].font,backgroundColor:colorCodes[color].hex,height:botFactor*100+"%",width:(1/Math.floor(colors.length/2))*100+"%",position:"relative",borderRight:"1px solid black",borderBottom:"1px solid black"}}><Typography variant="body1" style={{position:"absolute",bottom:"8px",left:"1px",fontSize:topFactor*20+"px"}}>{color}</Typography></Grid>:""):"";
    let solNed = (global.placering!=="" && global.placering!==null && global.dagslaengde!==""&& global.dagslaengde!==null)?solTider[global.placering][global.dagslaengde].ned:"00:00";
    let solOp = (global.placering!=="" && global.placering!==null && global.dagslaengde!==""&& global.dagslaengde!==null)?solTider[global.placering][global.dagslaengde].op:"00:00";
    let solLængde = (global.placering!=="" && global.placering!==null && global.dagslaengde!==""&& global.dagslaengde!==null)?solTider[global.placering][global.dagslaengde].længde:"0 t 0 min";

    let modsætningerneContent=(global.modsaetninger!==null&&global.modsaetninger!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+opposites[global.modsaetninger]+")"}}></div>: <div></div>;
    let skalaenContent=(global.skala!==null&&global.skala!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+scalas[global.skala]+")"}}></div>: <div></div>;
    let modellenContent=(global.modellen!==null&&global.modellen!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+global.modellen+")",backgroundSize: "cover",backgroundPosition: "center", backgroundRepeat: "no-repeat"}}></div>: <div></div>;
    let byenContent=(global.placering!==null&&global.placering!=="")? <div style={{height:"219px",width:"200px",backgroundImage:"url("+maps[global.placering]+")"}}></div>: <div></div>;
    let dagslaengdeContent=(global.placering!=="" && global.placering!==null && global.dagslaengde!==""&& global.dagslaengde!==null)? <div>
    <Grid container justify="space-around" alignItems="center" style={{background:'linear-gradient(to top,#ff5f6d,#ffc371)',height:"73px"}}>
        <Grid item xs={2} >
            <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 24 44.89"><defs><radialGradient id="a" cx="12" cy="32.89" r="12" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#fff"/><stop offset="1" stopColor="#fcee21"/></radialGradient></defs><g data-name="Layer 2"><g data-name="Layer 1"><circle cx="12" cy="32.89" r="12" fill="url(#a)"/><path fill="#fff" d="M11.5 0l-9 15.59h18L11.5 0z"/></g></g></svg>
            </Grid>
            <Grid item xs={8}><Typography variant="h4">{solOp}</Typography>
            </Grid>
            </Grid>
            <Grid container justify="space-around" alignItems="center" style={{background:'linear-gradient(to top,#000428, #004e92)',height:"73px"}}>
            <Grid item xs={8}>
            <Typography color="primary" variant="h4">{solNed}</Typography>
            </Grid>
            <Grid item xs={2}>
                <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 24 44.89"><defs><radialGradient id="a" cx="12" cy="12" r="12" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#fff"/><stop offset=".51" stopColor="#fcee21"/><stop offset=".73" stopColor="#fbb03b"/></radialGradient></defs><g data-name="Layer 2"><g data-name="Layer 1"><circle cx="12" cy="12" r="12" fill="url(#a)"/><path fill="#fff" d="M11.5 44.89l9-15.59h-18l9 15.59z"/></g></g></svg>
            </Grid>
            </Grid>
            <Grid container justify="space-around" alignItems="center" style={{background:"radial-gradient(circle at 100%, yellow, orange 30%, rgba(249,251,240,1) 35%, rgba(110,175,255,1) 75%)",height:"73px"}}>
            <Grid item xs={10}>
            <Typography variant="h4">{solLængde}</Typography>
            </Grid></Grid></div>: <div></div>;
    let farverContent=(global.farver!==null && global.farver!=="")? <Grid container style={{height:"219px", width:"200px"}}>{topRow}{botRow}</Grid>: <div></div>;
    let titelContent=(global.titel!==null&&global.titel!=="")? <div style={{height:"219px",width:"389px",position:"relative",background: "linear-gradient(to top, #3F2B96 0%, #A8C0FF 100%)"}}><Typography variant="h5" style={{wordWrap: "break-word",hyphens: "auto",WebkitHyphens:"auto",MozHyphens:"auto", position:"absolute",top:"50%",left:"51%",transform: "translate(-50%, -51%)"}}>{global.titel}</Typography></div>: <div></div>;
    let installContent=(global.video!==null&&global.video!=="")? <div style={{height:"219px",width:"200px",overflow:"hidden"}}>
    <video src={global.video} loop playsInline autoPlay muted width="200px" poster={DefaultImg}/>
    </div>: <div></div>;
    
    if(global.rolProgress===1 && global.skala!==""&&global.modellen!=="" && global.modsaetninger!==""){
      setGlobal({rolProgress:2});
    }
    if(global.rolProgress===2 && !dialogOneOpen){
      setDialogOneOpen(true);
    }
    if(global.rolProgress===3 && global.placering!=="" && global.dagslaengde!=="" && global.farver!==""){
      setGlobal({rolProgress:4});
    }
    if(global.rolProgress===4 && !dialogTwoOpen){
      setDialogTwoOpen(true);
    }
    if(global.rolProgress===5 && global.video!=="" && global.titel!==""){
      setGlobal({rolProgress:6});
    }
    if(global.rolProgress===6 && !dialogFourOpen){
      setDialogFourOpen(true);
    }
    console.log("step er: "+global.rolProgress);
      return(
    <div  className={classes.AppBarRoot}>
        <AppBar position="static">        
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    Ultrabit: Rum og Lys
                </Typography>
                <AvatarBar />           
            </Toolbar>
        </AppBar>
        <div>
        {global.rolProgress>=2?
          <Grid component={RouterLink} to="/rolone" container justify="space-around" className={classes.stepRows}>
            
            <Grid item>
              <RlBadge ribbonText="MODSÆTNINGERNE" borderColor="transparent" content={modsætningerneContent}/>
            </Grid>
            <Grid item>
              <RlBadge ribbonText="SKALAEN" borderColor="transparent" content={skalaenContent}/>
            </Grid>
            <Grid item>
              <RlBadge ribbonText="MODELLEN" borderColor="transparent" content={modellenContent}/>
            </Grid>
          </Grid>:
          <Grid component={RouterLink} to="/rolone" container justify="space-around" className={classes.stepRows}>
            <Grid item xs={3}>
              <Typography style={{textShadow:global.rolProgress===1?"2px 2px green":"",color:global.rolProgress===1?"white":"grey"}} variant="h3">Trin 1:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{textShadow:global.rolProgress===1?"2px 2px green":"",color:global.rolProgress===1?"white":"grey"}} variant="h4">Modsætninger</Typography>
              <Typography style={{textShadow:global.rolProgress===1?"2px 2px green":"",color:global.rolProgress===1?"white":"grey"}} variant="h4">Skala</Typography>
              <Typography style={{textShadow:global.rolProgress===1?"2px 2px green":"",color:global.rolProgress===1?"white":"grey"}} variant="h4">Modellen</Typography>
            </Grid>
          </Grid>}
            {global.rolProgress>=4?
          <Grid component={RouterLink} to="/roltwo" container justify="space-around" className={classes.stepRows}>
            <Grid item>
              <RlBadge ribbonText="BYEN" borderColor="transparent" content={byenContent}/>
            </Grid>
            <Grid item>
              <RlBadge ribbonText="DAGSLÆNGDEN" borderColor="transparent" content={dagslaengdeContent}/>
            </Grid>
            <Grid item>
              <RlBadge ribbonText="FARVERNE" borderColor="transparent"  content={farverContent}/>
            </Grid>
          </Grid>:
          <Grid component={RouterLink} to={global.rolProgress>2?"/roltwo":"/rol"} container justify="space-around" className={classes.stepRows}>
            <Grid item xs={3}>
              <Typography style={{textShadow:global.rolProgress===3?"2px 2px green":"",color:global.rolProgress===3?"white":"grey"}} variant="h3">Trin 2:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{textShadow:global.rolProgress===3?"2px 2px green":"",color:global.rolProgress===3?"white":"grey"}} variant="h4">Byen</Typography>
                <Typography style={{textShadow:global.rolProgress===3?"2px 2px green":"",color:global.rolProgress===3?"white":"grey"}} variant="h4">Dagslængde</Typography>
                <Typography style={{textShadow:global.rolProgress===3?"2px 2px green":"",color:global.rolProgress===3?"white":"grey"}} variant="h4">Farverne</Typography>
              </Grid>
            </Grid>}
          {global.rolProgress>=6?<Grid component={RouterLink} to="/rolthree" container justify="space-around" className={classes.stepRows}>
            <Grid item>
              <RlBadge badgeWidth="389" ribbonText="TITLEN" content={titelContent}/>
            </Grid>
            <Grid item>
              <RlBadge ribbonText="INSTALLATIONEN" content={installContent}/>
            </Grid>
          </Grid>:
          <Grid component={RouterLink} to={global.rolProgress>3?"/rolthree":"/rol"} container justify="space-around" className={classes.stepRows}>
            <Grid item xs={3}>
              <Typography style={{textShadow:global.rolProgress===5?"2px 2px green":"",color:global.rolProgress===5?"white":"grey"}} variant="h3">Trin 3:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{textShadow:global.rolProgress===5?"2px 2px green":"",color:global.rolProgress===5?"white":"grey"}} variant="h4">Titlen</Typography>
              <Typography style={{textShadow:global.rolProgress===5?"2px 2px green":"",color:global.rolProgress===5?"white":"grey"}} variant="h4">Installationen</Typography>
            </Grid>
          </Grid>}
        </div>
        <Dialog
        classes={{paper:classes.paper}}
        open={dialogWelOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle>
        <DialogContent classes={{root:classes.dialogTitleRoot}}>
          <DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
            Så er vi klar til start<br></br>
            Gå først videre når<br></br>
      I får besked
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton size="medium" classes={{root:classes.iconRoot}} onClick={handleClose} color="secondary" autoFocus >
            <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
            classes={{paper:classes.paper}}
        open={dialogOneOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      ><DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle><DialogContent classes={{root:classes.dialogTitleRoot}}><DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
            Trin 1 er udfyldt<br></br>
            Gå først videre når<br></br>
      I får besked
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton size="medium" classes={{root:classes.iconRoot}} onClick={handleClose} color="secondary" autoFocus >
            <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
          </IconButton>
        </DialogActions>
      </Dialog>
        <Dialog
            classes={{paper:classes.paper}}
            open={dialogTwoOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
          <DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle>
          <DialogContent classes={{root:classes.dialogTitleRoot}}><DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
              Trin 2 er udfyldt<br></br>
                  Gå først videre når<br></br>
          I får besked
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <IconButton size="medium" classes={{root:classes.iconRoot}}  onClick={handleClose} color="secondary" autoFocus >
                  <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
                </IconButton>
            </DialogActions>
          </Dialog>
                  <Dialog
                  classes={{paper:classes.paper}}
          open={dialogFourOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle classes={{root:classes.dialogTitleRoot}} id="alert-dialog-title">{"Sådan!"}</DialogTitle>
          <DialogContent classes={{root:classes.dialogTitleRoot}}><DialogContentText classes={{root:classes.dialogTitleRoot}} id="alert-dialog-description">
            I har udfyldt det hele!<br></br>
                  Tak, aflever iPad<br></br>
          når I får besked
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <IconButton size="medium" classes={{root:classes.iconRoot}} onClick={handleClose} color="secondary" autoFocus >
                  <CheckCircleRoundedIcon classes={{root:classes.iconRootCircle}} fontSize="large"/>
                </IconButton>
          </DialogActions>
          </Dialog>   
    </div>);
  }
  