import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './Components/theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
setGlobal({
  besoegsid:'',
  besoegsSkoler:[],
  besoegsKlasser:[],
  besoegsForloeb:'',
  besoegsDato:'',
  gruppeId:'',
  gruppemedlemmer:[{name:"",avatar:0},{name:"",avatar:1}],
  /*Boligen*/
  registreringer:[],
  viewImages:{},
  funktioner:[],
  organisation:"",
  faellesskitse:"",
  maalfast:"",
  snit:"",
  digitalplan:"",
  tredimensionelmodel:"",
  render:"",
  boligprogress:0,
  /*i skalaens verden*/
  skitsemodel:"",
  modulmodel:"",
  model:"",
  stopmotionvideo:"",
  minecraftmodellen:"",
  minecraftudsigten:"",
  minecraftdetaljen:"",
  isvprogress:0,
  /*Rum og Lys*/
  modsaetninger:"",
  skala:"",
  modellen:"",
  placering:"",
  dagslaengde:"",
  farver:"",
  video:"",
  titel:"",
  rolProgress:0,
});
ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
