import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'reactn';
import { useGlobal } from 'reactn';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import { Button, Dialog, TextField } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  listRoot:{
    overflow: 'auto',
    maxHeight: 500,
  },
  listContainer:{
      backgroundColor: theme.palette.background.paper,
  },
  dialogContainer: {
    backgroundColor: theme.palette.background.paper,
    position:"fixed",
    height:"760px",
    width:"660px",
    padding:"20px",
    top:"200px",
    left:"50px"
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function Funktionerne(props){
  const [ global, setGlobal ] = useGlobal();
    const classes = useStyles();
    const [open,setOpen] = useState(false);
    const [roomName,setRoomName]=useState("");
    const [roomErrors,setRoomErrors]=useState(0);
    const [helperText,setHelperText] = useState("eks. Børneværelse, Køkken");
    React.useEffect(()=>{
      fetch('https://vidensrummet.dk/vidensrumapi/boligenbesoeg/update.php', {
      method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:global.gruppeId,
      gruppemedlemmer:global.gruppemedlemmer,
      registreringer:global.registreringer,
      viewImages:global.viewImages,
      funktioner:global.funktioner, 
      organisation:global.organisation, 
      faellesskitse:global.faellesskitse, 
      maalfast:global.maalfast, 
      snit:global.snit, 
      digitalplan:global.digitalplan, 
      render:global.render, 
      tredimensionelmodel:global.tredimensionelmodel, 
    })
    }).then(response => response.json());
  },[global]);
    const handleClose= ()=> {
        setRoomErrors(0);
        setRoomName("");

        setOpen(false);
    }
    const handleOpen=()=>{
        setOpen(true);
    }
    const addRoom=()=>{
        if(roomName==="" || roomName===null){
            setHelperText("Du skal give rummet et navn");
            setRoomErrors(1);
        }else{
            setHelperText("eks. Børneværelse, Køkken");
            setRoomErrors(0);
        let theRooms = global.funktioner;
        theRooms.push({id:theRooms.length>0?theRooms[theRooms.length-1].id+1:1,name:roomName,size:""});
        setGlobal({funktioner:theRooms});
        setRoomName("");
        setOpen(false);
    }
    }
    const deleteRoom=(roomId)=>{
      console.log("deleting room: " + roomId)
        const markerIdFind = (element) => element.id === roomId;
        let roomIndex = global.funktioner.findIndex(markerIdFind);
        let newRooms = global.funktioner.filter((_, i) => i !== roomIndex);
        setGlobal({funktioner:newRooms});
    }
    const handleNameChange=(event)=>{
        setRoomName(event.target.value);
    }
    const roomItems =global.funktioner.map(room=> {
        return(
          <ListItem key={room.id} divider>
            <ListItemText
              primary={room.name}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={()=>deleteRoom(room.id)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>);
        });
       
    return(
    <div className={classes.dialogContainer}>
        <Typography variant="h3" color="primary">Hvilke rum?</Typography>
        <div className={classes.listContainer}>
        <List className={classes.listRoot}>
        {roomItems}
        </List>
        <Typography>i alt {global.funktioner.length} rum</Typography>
        </div>
        <div>
            <Button color="primary" variant="outlined" onClick={handleOpen}>Tilføj Rum</Button>
        </div>
        <Dialog open={open} onClose={handleClose}><Paper style={{width:"300px",height:"360px",padding:"30px"}}>
            <Typography variant="h5">Tilføj Rum</Typography>
            <Typography >Hvilket rum vil i tilføje?</Typography>
                <TextField autoFocus={true} error={roomErrors===1?true:false} helperText={helperText} variant="filled" value={roomName} onChange={handleNameChange} label="Rum" />
                <div style={{display:"flex",height:"60px",margin:"10px",alignItems:"flex-end",width:"100%"}}>
                    <Button onClick={addRoom} variant="contained" color="secondary">Tilføj</Button><Button style={{marginLeft:"10px"}} onClick={handleClose} variant="outlined" color="secondary">Annuller</Button>
                </div>
                
            </Paper>
        </Dialog>
    </div>);
}