import React from 'react';
import { Typography } from '@material-ui/core';


export default function Color(props){
    const NeoPixelColors = {
        Red : "#FF0000",
        Orange : "#FFA500",
        Yellow : "#FFFF00",
        Green : "#00FF00",
        Blue : "#0000FF",
        Indigo : "#4b0082",
        Violet : "#8a2be2",
        Purple : "#FF00FF",
        White : "#FFFFFF",
        Black : "#000000",
        Rainbow: "linear-gradient(to right, red ,red 15%, yellow 15%,yellow 30%, green 30%,green 45%, cyan 45%,cyan 60%, blue 60%,blue 85%, violet 85%)",
    }

    
    const diameter = props.diameter+"px";
    const lineHeight =props.diameter*2.5+"px";
    const color = NeoPixelColors[props.color];
    const [toggled,setToggled] =React.useState(props.toggled); 
    let border =props.diameter*0.05+"px solid black";
    let toggledBorder = props.diameter*0.1+"px solid white";
    if(props.color===""){
        return(<Typography variant="h6">Farver</Typography>);
    }
    if(toggled){
        return(<div style={{background:color,borderRadius:'50%',width:diameter,height:diameter,border:toggledBorder,margin:"24px",boxShadow: "0 0 6px 3px rgba(0,0,0,0.8), 0 0 10px 6px rgba(255,255,255,0.4), 0 0 14px 9px rgba(255,255,255,0.1)"}} onClick={()=>{if(props.toggleList!==false){props.toggleList(props.color); setToggled(!toggled);}}}><Typography style={{lineHeight:lineHeight}}>{props.color}</Typography></div>);
    }else{
        return(<div style={{background:color,borderRadius:'50%',width:diameter,height:diameter,border:border,margin:"24px"}} onClick={()=>{if(props.toggleList!==false){props.toggleList(props.color); setToggled(!toggled);}}}></div>);
    }
}